import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Button} from "react-bootstrap";

const NavMobile = (props) => {
  
  const navigate = useNavigate();
  
  return (
    <>
      <div className='nav-mobile'>
        <Button data-tip="Retour" variant="link" onClick={() => navigate(-1)}><FaArrowLeft /></Button>
        <h2>{props.name}</h2>
        {props.handleList ? 
          <Link to={"/listes"}><Button data-tip="Gérer les listes" variant="list">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
                <g id="Groupe_106" data-name="Groupe 106" transform="translate(-293 -17)">
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="1.5" cy="1.5" r="1.5" transform="translate(293 17)" fill="#f8f8f8"/>
                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.5" cy="1.5" r="1.5" transform="translate(293 24)" fill="#f8f8f8"/>
                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.5" cy="1.5" r="1.5" transform="translate(293 31)" fill="#f8f8f8"/>
                    <rect id="Rectangle_59" data-name="Rectangle 59" width="18" height="3" rx="1.5" transform="translate(298 31)" fill="#f8f8f8"/>
                    <rect id="Rectangle_60" data-name="Rectangle 60" width="18" height="3" rx="1.5" transform="translate(298 24)" fill="#f8f8f8"/>
                    <rect id="Rectangle_61" data-name="Rectangle 61" width="18" height="3" rx="1.5" transform="translate(298 17)" fill="#f8f8f8"/>
                </g>
            </svg>
          </Button></Link>
        : "" }
        {props.handleLink ? 
          <Button data-tip={props.handleData} variant="plus" onClick={props.handleLink} disabled={!props.role || props.role === "Manager" ? false : true} >
            <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
                <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
            </svg>
          </Button>
        : "" }
      </div>
    </>
  );
}

export default NavMobile;