import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { formatDate } from "@fullcalendar/core";
import { useNavigate } from "react-router-dom";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { isMobile } from "react-device-detect";

import { Card, Button, ListGroup, Alert, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import BrowserSidenav from "../components/BrowserSidenav.jsx";
import NavMobile from '../components/NavMobile';
import RapporActiviteFormAddModal from "../components/RapportActiviteFormAddModal";
import ValidationModal from "../components/ValidationModal";
import CreateEmpty from "../components/CreateEmpty";
import ContactRapport from "../components/ContactRapport";

const GETRAPPORTSACTIVITES_URL = '/rapportsActivites/getRapportsActivites'
const ADDRAPPORTACTIVITE_URL = '/rapportsActivites/addRapportActivite'
const DELRAPPORTACTIVITE_URL = '/rapportsActivites/deleteRapportActivite'
const GETACTIONS_URL = '/actions/getActions'
const GETCONTACTS_URL = '/contacts'
const GETCONTACTSBYID_URL = '/contacts/getContactbyid'

const RapportActivite = () => {
    //SESSION STATES
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;
    const role = auth?.role;

    //STORAGE
    const [rapportsActivites, setRapportsActivites] = useState([]);
    // const [rapportsActivitesDump, setRapportsActivitesDump] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [actions, setActions] = useState([]);

    //FORMS
    const [commentaire, setCommentaire] = useState("");
    const changeCommentaire = (value) => {
        setCommentaire(value);
    }
    const [action, setAction] = useState("");
    const changeAction = (value) => {
        setAction(value);
    }
    const [contact, setContact] = useState("");
    const changeContact = (value) => {
        setContact(value);
    }

    //TOOLS
    const [actionTypeInput, setActionTypeInput] = useState("Liste");
    const changeActionTypeInput = (value) => {
        setActionTypeInput(value);
    }
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [needListReload, setNeedListReload] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        setNeedListReload(false);
        setIsLoadingList(true);
        const fetchRapportActiviteList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETRAPPORTSACTIVITES_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setRapportsActivites(response.data["rapports"]);
                setIsLoadingList(false);
                // setRapportsActivitesDump(response.data["rapports"]);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchRapportActiviteList()
    }, [accessToken, needListReload])

    useEffect(() => {
        const fetchActionList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETACTIONS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setActions(response.data["actions"]);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchActionList()
    }, [accessToken])

    //GET CONTACTS
    useEffect(() => {
        const fetchContactsList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setContacts(response.data["contacts"]);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchContactsList()
    }, [accessToken])

    //ADD MODALS
    const [showAddRapportActiviteModal, setShowAddRapportActiviteModal] = useState(false);

    const handleShowAddRapportActivite = () => {
        setShowAddRapportActiviteModal(true);
    }

    const handleCloseAddRapportActivite = () => {
        setShowAddRapportActiviteModal(false);
        setActionTypeInput("Liste");
        setCommentaire("");
    }

    const handleAddRapportActiviteFormSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [409, 401];
        setIsLoading(true);
        try {
            const response = await axios.post(ADDRAPPORTACTIVITE_URL,
                JSON.stringify(
                    {accessToken, contact, action, commentaire}
                ), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            console.log(response.data["message"])

            setTimeout(function () {
                setIsLoading(false);
                setNeedListReload(true);
                handleCloseAddRapportActivite();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
                setIsLoading(false);
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
                setIsLoading(false);
            } else {
                console.log("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    }

    //DEL MODAL
    const [showDelRapportActiviteModal, setShowDelRapportActiviteModal] = useState(false);
    const [rapportToDelete, setRapportToDelete] = useState({});

    const handleShowDeleteRapport = rapport => {
        setShowDelRapportActiviteModal(true);
        setRapportToDelete(rapport);
    }

    const handleCloseDeleteRapport = () => {
        setShowDelRapportActiviteModal(false);
        setRapportToDelete({});
    }

    const handleDeleteRapportActiviteFormSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [409, 401];
        setIsLoading(true);
        try {
            const response = await axios.post(DELRAPPORTACTIVITE_URL,
                JSON.stringify(
                    {accessToken, "id" : String(rapportToDelete.id)}
                ), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            console.log(response.data["message"])

            setTimeout(function () {
                setIsLoading(false);
                setNeedListReload(true);
                handleCloseDeleteRapport();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setErrMsg("Pas de réponse");
                setIsLoading(false);
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setErrMsg(err.response?.data["message"]);
                setIsLoading(false);
            } else {
                setErrMsg("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    }

    const navigate = useNavigate();

    const handleGetContactbyId = idContact => {
        axios.post(GETCONTACTSBYID_URL,
            JSON.stringify(
                {accessToken, "id" : String(idContact)}   
            ), 
            {
                headers: {"Content-Type":"application/json"},
                withCredentials: true
            }
        )
        .then(function (response) {
            navigate("/contacts/"+response.data["contact"].email);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    //HOVER BUTTON
    const handleMouseOver = async (e) => {
        e.currentTarget.className = "contacts-list-item list-group-item hover";
    }

    const handleMouseOut = async (e) => {
        e.currentTarget.className = "contacts-list-item list-group-item";
    }

    return (
        <>
            {!isMobile ? <BrowserSidenav /> : <NavMobile handleLink={handleShowAddRapportActivite} handleData="Ajouter un rapport" name="Rapports d'activités" />}
            <div className={!isMobile ? "browser-content page-clients" : "mobile-content page-clients"}>
                {!isMobile ? <Card>
                    <Card.Header className="contacts-list-header">
                        <h2>Rapports d'activités</h2>
                            <Button data-tip="Ajouter un rapport" variant="plus" onClick={handleShowAddRapportActivite}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
                                    <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
                                </svg>
                            </Button>
                    </Card.Header>
                </Card>
                : "" }

                {isLoadingList
                    ? 
                    <div style={{
                        marginTop: "50px",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        <Spinner animation="border" size="sm"/> Chargement
                    </div>
                    : <ListGroup variant="flush" className="contacts-list">
                        {!rapportsActivites.length ? <CreateEmpty name="Rapport" namebtn="Ajouter un rapport" showModal={setShowAddRapportActiviteModal} /> : rapportsActivites.map(rapportActivite => (
                        <div key={rapportActivite.id} className="contacts-list-item-container">
                            <ListGroup.Item className="contacts-list-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                <div className="contacts-list-item-content" onClick={e => handleGetContactbyId(rapportActivite.contact_ref)}>
                                    <div className="header-contact">
                                        <strong><ContactRapport idContact={rapportActivite.contact_ref} /> - {rapportActivite.contact}, le {formatDate(rapportActivite.date, {
                                            month: 'numeric',
                                            year: 'numeric',
                                            day: 'numeric',
                                            locale: 'fr'
                                        })}</strong>
                                    </div>
                                    <div className="body-contact">
                                        <div>
                                            Rapport réalisé par : {rapportActivite.fait_par}
                                        </div>
                                        <div>
                                            Action réalisé : {rapportActivite.action}
                                        </div>
                                    </div>
                                    
                                    <div>
                                        "{rapportActivite.commentaire}"
                                    </div>
                                </div>
                                {rapportActivite.email !== auth?.emailP ? "" : (
                                    <div className="btn-container">
                                            <Button disabled={role === "Manager" ? false : true} variant="sub" className="m-1" value={rapportActivite.id} onClick={e => handleShowDeleteRapport(rapportActivite)}>
                                                <FaTrashAlt />
                                            </Button>
                                    </div>
                                )}
                            </ListGroup.Item>
                        </div>
                        ))}
                    </ListGroup>
                }

                <RapporActiviteFormAddModal
                    show={showAddRapportActiviteModal}
                    handleClose={handleCloseAddRapportActivite}
                    handleSubmit={handleAddRapportActiviteFormSubmit}

                    headerContent={"Ajouter un rapport d'activité"}
                    btnLabel={["Ajout en cours...", "Ajouter"]}
                    
                    actions={actions}
                    contacts={contacts}

                    action={action}
                    changeAction={changeAction}
                    commentaire={commentaire}
                    changeCommentaire={changeCommentaire}
                    changeContact={changeContact}
                    actionTypeInput={actionTypeInput}
                    changeActionTypeInput={changeActionTypeInput}

                    isLoading={isLoading}
                />

                <ValidationModal 
                    show={showDelRapportActiviteModal}
                    handleClose={handleCloseDeleteRapport}
                    headerContent={"Suppression"}
                    bodyContent={"Etes-vous sûr de vouloir supprimer le rapport ?"}
                    handleSubmit={handleDeleteRapportActiviteFormSubmit}
                    isLoading={isLoading}
                    errMsg={
                        <Alert 
                            key={"warning"} 
                            variant={"warning"} 
                            className={errMsg !== "" ? "" : "offscreen"}
                            style={{position: "absolute", bottom: "20px", margin: "0"}}>
                            <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
                        </Alert>
                    }
                />
            </div>
        </>
    )  
}

export default RapportActivite;