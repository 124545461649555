import React, { useEffect, useState, useRef } from "react"
import { isMobile } from "react-device-detect";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { ListGroup, Accordion, Button, useAccordionButton, Card, Alert, Form, Row, Col, Spinner } from "react-bootstrap";

import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";

import modeleCSV from "../../assets/Modèle_import_Lucky.xlsx"

import BrowserSidenav from '../../components/BrowserSidenav';
import UserFormModal from "../../components/UserFormModal";
import ValidationModal from "../../components/ValidationModal";

//API
const GETUSER_URL = '/users';
const ADDUSER_URL = '/users/addUser';
const UPDUSER_URL = '/users/updateUser';
const DELUSER_URL = '/users/deleteUser';
const IMPORT_URL = '/exchange/importContacts';
const EXPORT_URL = '/exchange/exportContacts';
const GETACTIONS_URL = '/actions/getActions';
const ADDACTION_URL = '/actions/addAction';
const DELETEACTION_URL = '/actions/deleteAction';
const GETCONTACTS_URL = '/contacts';
const GETCONTACTSBY_URL = '/contacts/getcontactby';
const ADDASSOCUC_URL = '/assoc/addAssocUC';
const DELETEASSOCUC_URL = '/assoc/deleteAssocUC';

//REGEX
const USER_NAME_REGEX = /^[A-zÀ-ú]{3,23}$/;
const EMAIL_REGEX = /^(?=.*[A-z])(?=.*[.])(?=.*[@]).{6,30}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%./?*&$]).{8,16}$/;

//ADMIN
const Admin = () => {
    //SESSION STATE
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;
    const roleAuth = auth?.role

    //TOOLS
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImport, setIsLoadingImport] = useState(false);

    const [needListReload, setNeedListReload] = useState(false);
    const [needActionListReload, setNeedActionListReload] = useState(false);

    const [showPwd, setShowPwd] = useState(false);
    const changeShowPwd = (value) => {value ? setShowPwd(true) : setShowPwd(false)}

    //ERR MSG
    const [addMsg, setAddMsg] = useState("");
    const addMsgRef = useRef("");

    const [updMsg, setUpdMsg] = useState("");
    const updMsgRef = useRef("");

    const [delMsg, setDelMsg] = useState("");
    const delMsgRef = useRef("");

    const [errMsg, setErrMsg] = useState("");

    //TIMEOUT ERR MSG
    useEffect(()=>{
        setTimeout(()=>{
            setErrMsg("");
        },5000)
    }, [errMsg])

    //REFS
    const errRef = useRef();
    const prenomRef = useRef();

    //REGEX BOOL STATES
    const [validNom, setValidNom] = useState(false);
    const changeValidNom = (value) => setValidNom(USER_NAME_REGEX.test(value));
    const [validPrenom, setValidPrenom] = useState(false);
    const changeValidPrenom = (value) => setValidPrenom(USER_NAME_REGEX.test(value));
    const [validPwd, setValidPwd] = useState(false);
    const changeValidEmail = (value) => setValidEmail(EMAIL_REGEX.test(value));
    const [validEmail, setValidEmail] = useState(false);
    const changeValidPwd = (value) => setValidPwd(PWD_REGEX.test(value));
    const [matchPwd, setMatchPwd] = useState(false);
    const changeMatchPwd = (value) => setMatchPwd(value === mdp);

    //CHANGE METHOD LIKE IN CONTACT
    function getIndex(array, field, listKey) {
        for(var i = 0; i < array.length; i++) {
            if(array[i][field] === listKey) {
                return i;
            }
        }
        return -1;
    }

    //USER FORM STATES
    const [prenom, setPrenom] = useState("");
    const changePrenom = (value) => setPrenom(value);
    const [nom, setNom] = useState("");
    const changeNom = (value) => setNom(value);
    const [email, setEmail] = useState("");
    const changeEmail = (value) => setEmail(value);
    const [mdp, setMdp] = useState("");
    const changeMdp = (value) => setMdp(value);
    const [item, setItem] = useState({ role: "" });
    const { role } = item;
    const changeRole = e => {
        e.persist();
    
        setItem(prevState => ({
            ...prevState,
            role: e.target.value
        }));
    };

    //USER FORM TARGET
    const [userEmailToUpdate, setUserEmailToUpdate] = useState("");

    //VALIDATION FORM STATE
    const [userToDelete, setUserToDelete] = useState("");

    //USERS LIST STATE
    const [users, setUsers] = useState([]);

    //GET USERS LIST
    useEffect(() => {
        setNeedListReload(false);
        const fetchUserList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETUSER_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                setUsers(response.data["utilisateurs"]);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
                errRef.current.focus();
            }
        }
        fetchUserList()
    }, [accessToken, needListReload])

    //ADD USER FORM MODAL
    const [showAddUser, setShowAddUser] = useState(false);
    const handleShowAddUser = async () => {
        await setShowAddUser(true);
        prenomRef.current.focus();
    }
    const handleCloseAddUser = () => {
        setShowAddUser(false);
        setAddMsg(""); 
        setNom(""); 
        setPrenom(""); 
        setEmail(""); 
        setMdp("");
        setItem({role: ""});
        setValidNom(false);
        setValidPrenom(false);
        setValidPwd(false);
        setValidEmail(false);
        setMatchPwd(false);
        setShowPwd(false);
    }
    const handleAddUserSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [401, 409, 204, 206];
        if (validNom === true && validPrenom === true && validPwd === true && matchPwd === true && item.role !== "" && validEmail === true) {
            setIsLoading(true);
            try {
                const response = await axios.post(ADDUSER_URL,
                    JSON.stringify({prenom, nom, role, email, mdp, accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                )

                setAddMsg(response?.data["message"]);
                setTimeout(function () {
                    setIsLoading(false);
                    setNeedListReload(true);
                    handleCloseAddUser();
                }, 2000);
            } catch (err) {
                if (!err?.response) {
                    setAddMsg("Pas de réponse");
                    setIsLoading(false);
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setAddMsg(err.response?.data["message"]);
                    setIsLoading(false);
                } else {
                    setAddMsg("Problème interne lié au serveur !");
                    setIsLoading(false);
                }
            }
        } else {
            setAddMsg("Au moins une valeur non valide");
        }
    }

    //UPDATE USER FORM MODAL
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const handleShowUpdateUser = (e) => {
        setUserEmailToUpdate(e.target.value); //keep old email for request
        setEmail(e.target.value);
        setNom(users[getIndex(users, "email", e.target.value)].nom);
        setPrenom(users[getIndex(users, "email", e.target.value)].prenom);
        setItem({ role: users[getIndex(users, "email", e.target.value)].role });
        setShowUpdateUser(true);
        setValidNom(true);
        setValidPrenom(true);
        setValidEmail(true);
    }
    const handleCloseUpdateUser = () => {
        setShowUpdateUser(false);
        setUpdMsg("");
        setNom(""); 
        setPrenom(""); 
        setEmail("");
        setMdp("");
        setItem({ role: "" });
        setValidNom(false);
        setValidPrenom(false);
        setValidPwd(false);
        setValidEmail(false);
        setMatchPwd(false);
        setShowPwd(false);
    }
    const handleUpdateUserSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [409, 401];
        setIsLoading(true);
        try {
            const response = await axios.post(UPDUSER_URL,
                JSON.stringify({prenom, nom, role, "old_email" : userEmailToUpdate, email, mdp, accessToken}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            setUpdMsg(response?.data["message"]);
            setTimeout(function () {
                setIsLoading(false);
                setNeedListReload(true);
                handleCloseUpdateUser();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setUpdMsg("Pas de réponse");
                setIsLoading(false);
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setUpdMsg(err.response?.data["message"]);
                setIsLoading(false);
            } else {
                setUpdMsg("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    };
    
    //DELETE USER
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const handleShowDeleteUser = (e) => {
        setUserToDelete(e.target.value);
        setShowDeleteUser(true);
    }
    const handleCloseDeleteUser = () => {
        setUserToDelete("");
        setShowDeleteUser(false);
        setDelMsg("");
    }
    const handleDeleteUserSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [401, 409, 204, 206];
        setIsLoading(true);
        try {
            const response = await axios.post(DELUSER_URL,
                JSON.stringify({accessToken, "email" : userToDelete}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            
            setDelMsg(response?.data["message"]);
            setTimeout(function () {
                setIsLoading(false);
                setNeedListReload(true);
                handleCloseDeleteUser();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setDelMsg("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setDelMsg(err.response?.data["message"]);
            } else {
                setDelMsg("Problème interne lié au serveur !");
            }
            errRef.current.focus();
        }
    }

    //CSV IMPORT
    const [selectedFiles, setSelectedFiles] = useState();
	const [isFilesPicked, setIsFilesPicked] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [disableCSVSubmit, setDisableCSVSubmit] = useState(true);
    
    const [parsedCSVInfo, setParsedCSVInfo] = useState()
    const [parsedCSV, setParsedCSV] = useState()

    const [clientCSVKey, setClientKey] = useState("none");
    const changeClientCSVKey = (value) => {value !== 0 ? setClientKey(parsedCSVInfo.fields[value - 1]) : setClientKey("none");};

    const [emailCSVKey, setEmailKey] = useState("none");
    const changeEmailCSVKey = (value) => {value !== 0 ? setEmailKey(parsedCSVInfo.fields[value - 1]) : setEmailKey("none");};

    const [prenomCSVKey, setPrenomCSVKey] = useState("none");
    const changePrenomCSVKey = (value) => {value !== 0 ? setPrenomCSVKey(parsedCSVInfo.fields[value - 1]) : setPrenomCSVKey("none");};

    const [nomCSVKey, setNomCSVKey] = useState("none");
    const changeNomCSVKey = (value) => {value !== 0 ? setNomCSVKey(parsedCSVInfo.fields[value - 1]) : setNomCSVKey("none");};

    const [fonctionCSVKey, setFonctionCSVKey] = useState("none");
    const changeFonctionCSVKey = (value) => {value !== 0 ? setFonctionCSVKey(parsedCSVInfo.fields[value - 1]) : setFonctionCSVKey("none");};

    const [telPortableCSVKey, setTelPortableCSVKey] = useState("none");
    const changeTelPortableCSVKey = (value) => {value !== 0 ? setTelPortableCSVKey(parsedCSVInfo.fields[value - 1]) : setTelPortableCSVKey("none");};

    const [telDirectCSVKey, setTelDirectCSVKey] = useState("none");
    const changeTelDirectCSVKey = (value) => {value !== 0 ? setTelDirectCSVKey(parsedCSVInfo.fields[value - 1]) : setTelDirectCSVKey("none");};

    const [linkedinCSVKey, setLinkedinCSVKey] = useState("none");
    const changeLinkedinCSVKey = (value) => {value !== 0 ? setLinkedinCSVKey(parsedCSVInfo.fields[value - 1]) : setLinkedinCSVKey("none");};

    const [statutJuridiqueCSVKey, setStatutJuridiqueCSVKey] = useState("none");
    const changeStatutJuridiqueCSVKey = (value) => {value !== 0 ? setStatutJuridiqueCSVKey(parsedCSVInfo.fields[value - 1]) : setStatutJuridiqueCSVKey("none");};

    const [raisonSocialeCSVKey, setRaisonSocialeCSVKey] = useState("none");
    const changeRaisonSocialeCSVKey = (value) => {value !== 0 ? setRaisonSocialeCSVKey(parsedCSVInfo.fields[value - 1]) : setRaisonSocialeCSVKey("none");};

    const [telStandardCSVKey, setTelStandardCSVKey] = useState("none");
    const changeTelStandardCSVKey = (value) => {value !== 0 ? setTelStandardCSVKey(parsedCSVInfo.fields[value - 1]) : setTelStandardCSVKey("none");};

    const [adresseCSVKey, setAdresseCSVKey] = useState("none");
    const changeAdresseCSVKey = (value) => {value !== 0 ? setAdresseCSVKey(parsedCSVInfo.fields[value - 1]) : setAdresseCSVKey("none");};

    const [commentaireCSVKey, setCommentaireCSVKey] = useState("none");
    const changeCommentaireCSVKey = (value) => {value !== 0 ? setCommentaireCSVKey(parsedCSVInfo.fields[value - 1]) : setCommentaireCSVKey("none");};

    //CSV COMPARISON
    const [showUploadCSV, setShowUploadCSV] = useState(true);
    const [showCheckFieldsCSV, setShowCheckFieldsCSV] = useState(false);

    useEffect(() => {
        if (isFilesPicked === true) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [isFilesPicked])

    useEffect(() => {
        if (emailCSVKey !== "none") {
            setDisableCSVSubmit(false);
        } else {
            setDisableCSVSubmit(true);
        }
    }, [emailCSVKey])

    const goToCheckCSVFields = () => {
        Papa.parse(selectedFiles[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setParsedCSVInfo(results.meta)
                setParsedCSV(results.data)
            },
        });

        setShowUploadCSV(false); 
        setShowCheckFieldsCSV(true);
    }

    const handleImportCSVSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [409, 401];
        if (emailCSVKey !== "none") {
            setIsLoadingImport(true);
            try {
                const response = await axios.post(IMPORT_URL,
                    JSON.stringify({
                        accessToken, "role": roleAuth, "is_client": clientCSVKey,
                        "champs" : 
                            {"email": emailCSVKey, "prenom": prenomCSVKey, "nom": nomCSVKey, "fonction": fonctionCSVKey, "tel_portable": telPortableCSVKey, 
                            "tel_direct": telDirectCSVKey, "linkedin": linkedinCSVKey, "statut_juridique": statutJuridiqueCSVKey, "raison_sociale": raisonSocialeCSVKey, "tel_standard": telStandardCSVKey, "adresse": adresseCSVKey, "commentaire": commentaireCSVKey}, 
                        "valeures": parsedCSV
                    }), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

    
                setErrMsg(response?.data["message"]);
                setTimeout(function () {
                    setIsLoadingImport(false);
                    setShowCheckFieldsCSV(false);
                    setShowUploadCSV(true);
                    setSelectedFiles([]);
                    setIsFilesPicked(false);
                }, 2000);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                    setIsLoadingImport(false);
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                    setIsLoadingImport(false);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                    setIsLoadingImport(false);
                }
            }
        }
    };

    const [csvReport, setCSVReport] = useState();

    useEffect(() => {
        const exportCSV = async () => {
            const handledErr = [409, 401];
            try {
                const response = await axios.post(EXPORT_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                setCSVReport({
                    data: response?.data["contacts"], 
                    headers: response?.data["headers"], 
                    filename: 'mes-contacts-luckyCRM.csv'
                })

            } catch (err) {
                if (!err?.response) {
                    setUpdMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setUpdMsg(err.response?.data["message"]);
                } else {
                    setUpdMsg("Problème interne lié au serveur !");
                }
            }
        }

        exportCSV();
    },[accessToken]);

    //CONTACTS ASSOC USER
    const [contacts, setContacts] = useState([]);
    const [needContactListReload, setNeedContactListReload] = useState(false);

    useEffect(() => {
        setNeedContactListReload(false);
        const fetchContactsList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setContacts(response.data["contacts"]);

            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchContactsList()
    }, [accessToken, needContactListReload])

    //ACTIONS
    const [actions, setActions] = useState([]);

    const [actionAdd, setActionAdd] = useState("");
    const [actionDel, setActionDel] = useState("");

    //GET ACTIONS
    useEffect(() => {
        setNeedActionListReload(false);
        const fetchActionList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETACTIONS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setActions(response.data["actions"]);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchActionList()
    }, [accessToken, needActionListReload])

    const handleAddAction = async () => {
        const handledErr = [401];
        try {
            const response = await axios.post(ADDACTION_URL,
                JSON.stringify({accessToken, "action" : actionAdd}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            console.log(response.data["message"]);
            setNeedActionListReload(true);
            setActionAdd("");
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
            } else {
                console.log("Problème interne lié au serveur !");
            }
        }
    }

    const handleDeleteAction = async () => {
        const handledErr = [401];
        try {
            const response = await axios.post(DELETEACTION_URL,
                JSON.stringify({accessToken, "action" : actionDel}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            console.log(response.data["message"]);
            setNeedActionListReload(true);
            setActionAdd("");
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
            } else {
                console.log("Problème interne lié au serveur !");
            }
        }
    }

    //CUSTOM ACCORDION TOGGLE
    function CustomToggle({ children, eventKey}) {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
          <button
            type="button"
            className="btn"
            style={{width: "100%"}}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
    }

    const [UserContacts, setUserContacts] = useState([]);
    const [ContactsWithoutUser, setContactsWithoutUser] = useState([]);
    const [needUserContactsListReload, setNeedUserContactsListReload] = useState(false);
    const [selectedCommercial, setSelectedCommercial] = useState(0);
    const [commercial, setCommercial] = useState("");

    function getDifference(array1, array2) {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.email === object2.email;
            });
        });
    }

    useEffect(() => {
        setNeedUserContactsListReload(false);
        const loadContactbyUser = async () =>{
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTSBY_URL,
                    JSON.stringify({accessToken, "email": commercial}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setUserContacts(response.data["contacts"]);
                const contactWU = getDifference(contacts,response.data["contacts"]);
                setContactsWithoutUser(contactWU);
    
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        if(commercial !== ""){
            loadContactbyUser();
        }
    }, [accessToken, contacts, commercial, needUserContactsListReload])

    const handleChangeUser = (e) => {
        if (e.target.selectedIndex !== 0) {
            setCommercial(users[e.target.selectedIndex - 1].email); 
            setSelectedCommercial(e.target.value);
        } else {
            setCommercial("");
            setSelectedCommercial("");
            setUserContacts("");
        }
    }

    const handleAddUCAssoc = async (value, label) => {
        const selectedUserContact = [{"value": value, "label": label}];
        const handledErr = [401];
        try {
            await axios.post(ADDASSOCUC_URL,
                JSON.stringify({accessToken, "assoc_type": "UContacts", "utilisateur": commercial, "contacts": selectedUserContact}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            setNeedUserContactsListReload(true);
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
            } else {
                console.log("Problème interne lié au serveur !");
            }
        }
    }

    const handleDelUCAssoc = async (value, label) => {
        const selectedUserContact = [{"value": value, "label": label}];
        const handledErr = [401];
        try {
            await axios.post(DELETEASSOCUC_URL,
                JSON.stringify({accessToken, "assoc_type": "UContacts", "utilisateur": commercial, "contacts": selectedUserContact}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            setNeedUserContactsListReload(true);
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
            } else {
                console.log("Problème interne lié au serveur !");
            }
        }
    }
 
    return (
        <>
            {!isMobile ? <BrowserSidenav /> : null}
            <div className={!isMobile ? "browser-content page-admin" : "mobile-content page-admin"}>
                <Accordion defaultActiveKey="" className="admin-accord">
                    <Card>
                        <Card.Header className="admin-head">
                            <CustomToggle eventKey="0">Gérer les utilisateurs</CustomToggle>
                            <Button variant="plus" onClick={handleShowAddUser}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
                                    <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
                                </svg>
                            </Button>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="p-0">
                                <ListGroup variant="flush" className="user-list-admin">
                                    {users.map(user => (
                                    <ListGroup.Item key={user.email} className="item-content">
                                        <div className="content">
                                            {user.role} - {user.prenom} {user.nom}
                                            <br />
                                            Email : {user.email}
                                        </div>
                                        <div className="btn-container">
                                            <Button variant="icon" className="mx-1" value={user.email} onClick={handleShowUpdateUser}>
                                                <FaPencilAlt />
                                            </Button>
                                            <Button variant="icon" className="mx-1" value={user.email} onClick={handleShowDeleteUser}>
                                                <FaTrashAlt />
                                            </Button>
                                        </div>
                                    </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header className="admin-head">
                            <CustomToggle eventKey="3">Assigner des contacts aux commerciaux</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <Form className="adminAssign">
                                    <Form.Group>
                                        <Form.Label>
                                            Commercial
                                        </Form.Label>
                                        <Form.Select 
                                            aria-label="Default select example"
                                            value={selectedCommercial}
                                            onChange={(e) => handleChangeUser(e)}
                                        >
                                            <option>Choisir un commercial</option>
                                            {users.map(user => (
                                                <option key={user.email}>{user.prenom} {user.nom}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label>
                                                Tous les contacts
                                            </Form.Label>
                                            <ul className="addContact">
                                            {commercial ? ContactsWithoutUser.map(usercontactw => (
                                                <li key={usercontactw.email} onClick={() => handleAddUCAssoc(usercontactw.email, usercontactw.raison_sociale)}>{usercontactw.prenom} {usercontactw.nom} - {usercontactw.raison_sociale}</li>
                                            )) : ""}
                                            </ul>
                                        </Col>

                                        <Col>
                                            <Form.Label>
                                                Contacts assignés
                                            </Form.Label>
                                            <ul className="delContact">
                                            {UserContacts ? UserContacts.map(usercontact => (
                                                <li key={usercontact.email} onClick={() => handleDelUCAssoc(usercontact.email, usercontact.raison_sociale)}>{usercontact.prenom} {usercontact.nom} - {usercontact.raison_sociale}</li>
                                            )) : ""}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header className="admin-head">
                            <CustomToggle eventKey="5">Gérer les activités commerciales</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                                <Form>
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                Créer un type d'activité
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Ex : ConfCall, RDV ..."
                                                value={actionAdd}
                                                onChange={e => setActionAdd(e.target.value)}
                                            >

                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                Supprimer un type d'activité
                                            </Form.Label>
                                            <Form.Select 
                                                aria-label="Default select example"
                                                onChange={e => e.target.selectedIndex !== 0 ? setActionDel(actions[e.target.selectedIndex - 1].libelle) : setActionDel("")}
                                            >
                                                <option>Choisir un type d'activité à supprimer</option>
                                                {actions.map(action => (
                                                <option key={action.libelle}>{action.libelle}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Button
                                            variant="sub"
                                            onClick={e => handleAddAction()}
                                            style={{width: "auto", margin: "0 auto"}}
                                        >
                                            Ajouter
                                        </Button>

                                        <Button
                                            variant="sub"
                                            onClick={e => handleDeleteAction()}
                                            style={{width: "auto", margin: "0 auto"}}
                                        >   
                                            Supprimer
                                        </Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header className="admin-head">
                            <CustomToggle eventKey="1">Importer des contacts</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                {showUploadCSV ? 
                                    <Form>
                                        <Form.Group className="mb-3" style={{width: "640px", margin: "0 auto", textAlign: "center"}} controlId="adminUploadCSV">
                                            <p>Importez un fichier de contacts (Enregistré au format <strong>CSV UTF-8</strong> depuis Excel)</p>
                                            <Form.Control 
                                                type="file"
                                                accept=".csv"
                                                style={{width: "50%", margin: "0 auto"}}
                                                onChange={e => {setSelectedFiles(e.target.files); e?.target?.files.length > 0 ? setIsFilesPicked(true) : setIsFilesPicked(false);}}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="adminUploadCSVNavBtn">
                                            <Button 
                                                disabled={disableBtn} 
                                                variant="sub" 
                                                style={{width: "auto", margin: "0 auto"}}
                                                onClick={() => goToCheckCSVFields()}>
                                                Suivant
                                            </Button>
                                        </Form.Group>
                                        <p><Button variant="link" href={modeleCSV}>Téléchargez un modèle au format Excel .xlsx</Button></p>
                                    </Form>
                                : showCheckFieldsCSV ? 
                                    <Form onSubmit={handleImportCSVSubmit} style={{width: "600px", margin: "0 auto"}}>
                                        <p style={{textAlign: "center", fontWeight: "bold", fontSize: "18px"}}>Sélectionner la colonne du fichier correspondante à chaque champ</p>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Client">
                                                <Form.Label>
                                                    Champs de données
                                                </Form.Label>

                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Client ou Prospect"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Client">
                                                <Form.Label>
                                                    Colonne du fichier correspondante 
                                                </Form.Label>

                                                <Form.Select 
                                                    aria-label="CSVClient"
                                                    onChange={e => {changeClientCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Nom">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Nom"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Nom">
                                                <Form.Select 
                                                    aria-label="CSVNom"
                                                    onChange={e => {changeNomCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Prenom">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Prenom"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Prenom">
                                                <Form.Select 
                                                    aria-label="CSVPrenom"
                                                    onChange={e => {changePrenomCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Email">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Email"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Email">

                                                <Form.Select 
                                                    aria-label="CSVEmail"
                                                    onChange={e => {changeEmailCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelPortable">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Téléphone portable"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelPortable">
                                                <Form.Select 
                                                    aria-label="CSVTelPortable"
                                                    onChange={e => {changeTelPortableCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelDirect">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Ligne directe"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelDirect">
                                                <Form.Select 
                                                    aria-label="CSVTelDirect"
                                                    onChange={e => {changeTelDirectCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Linkedin">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"LinkedIn"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Linkedin">
                                                <Form.Select 
                                                    aria-label="CSVLinkedin"
                                                    onChange={e => {changeLinkedinCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.RaisonSociale">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Raison Sociale"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.RaisonSociale">
                                                <Form.Select 
                                                    aria-label="CSVRaisonSociale"
                                                    onChange={e => {changeRaisonSocialeCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Fonction">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Fonction"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Fonction">
                                                <Form.Select 
                                                    aria-label="CSVFonction"
                                                    onChange={e => {changeFonctionCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.StatutJuridique">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Type d'entreprise"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.StatutJuridique">
                                                <Form.Select 
                                                    aria-label="CSVStatutJuridique"
                                                    onChange={e => {changeStatutJuridiqueCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelStandard">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Standard"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelStandard">
                                                <Form.Select 
                                                    aria-label="CSVTelStandard"
                                                    onChange={e => {changeTelStandardCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Adresse">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Adresse"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Adresse">
                                                <Form.Select 
                                                    aria-label="CSVAdresse"
                                                    onChange={e => {changeAdresseCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Commentaire">
                                                <Form.Control 
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    value={"Commentaire"}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Commentaire">
                                                <Form.Select 
                                                    aria-label="CSVCommentaire"
                                                    onChange={e => {changeCommentaireCSVKey(e.target.selectedIndex);}}>
                                                    <option>Choisir une colonne</option>
                                                    {parsedCSVInfo?.fields.map(field => (
                                                        <option key={field}>{field}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>

                                        <Form.Group as={Row} controlId="adminCompareCSVNavBtn">
                                                <Button 
                                                    variant="sub" 
                                                    style={{width: "auto", margin: "0 auto"}}
                                                    onClick={e => {setShowCheckFieldsCSV(false); setShowUploadCSV(true); setSelectedFiles([]); setIsFilesPicked(false);}}>
                                                    Retour
                                                </Button>
                                                <Button 
                                                    disabled={disableCSVSubmit}
                                                    variant="sub" 
                                                    style={{width: "auto", margin: "0 auto"}}
                                                    type="submit">
                                                    Valider
                                                </Button>
                                        </Form.Group>
                                        {isLoadingImport
                                        ? 
                                        <div style={{
                                            marginTop: "50px",
                                            textAlign: "center",
                                            width: "100%"
                                        }}>
                                            <Spinner animation="border" size="sm"/> Importation en cours
                                        </div>
                                        : "" }
                                    </Form>
                                : null}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header className="admin-head">
                            <CustomToggle eventKey="2">Exporter tous les contacts</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <Row>
                                    <p style={{textAlign: "center"}}>Téléchargez le fichier d'export de contact au format CSV !</p>
                                    <div style={{width: "auto", margin: "0 auto"}}>{csvReport !==  undefined ? <CSVLink {...csvReport} className="btn btn-sub">Exporter tous les contacts</CSVLink> : null}</div>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Alert 
                    key={"warning"} 
                    variant={"warning"} 
                    className={errMsg !== "" ? "" : "offscreen"}
                    style={{position: "absolute", bottom: "20px", margin: "0"}}>
                    <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
                </Alert>

                <UserFormModal 
                    required={true}
                    show={showAddUser}
                    handleClose={handleCloseAddUser}
                    headerContent={"Ajouter un utilisateur"}

                    prenomRef={prenomRef}

                    changePrenom={changePrenom}
                    changeNom={changeNom}
                    changeEmail={changeEmail}
                    changeMdp={changeMdp}

                    role={role}
                    changeRole={changeRole}

                    validNom={validNom}
                    changeValidNom={changeValidNom}
                    validPrenom={validPrenom}
                    changeValidPrenom={changeValidPrenom}
                    validEmail={validEmail}
                    changeValidEmail={changeValidEmail}
                    validPwd={validPwd}
                    changeValidPwd={changeValidPwd}
                    matchPwd={matchPwd}
                    changeMatchPwd={changeMatchPwd}
                    showPwd={showPwd}
                    changeShowPwd={changeShowPwd}

                    handleSubmit={handleAddUserSubmit}
                    isLoading={isLoading}
                    btnLabel={["Ajout en cours ...", "Ajouter"]}
                    msgContent={<p ref={addMsgRef} className={addMsg ? "errmsg" : "offscreen"} aria-live="assertive">{addMsg}</p>}
                />

                <UserFormModal 
                    required={false}
                    show={showUpdateUser}
                    handleClose={handleCloseUpdateUser}
                    headerContent={"Mettre à jour l'utilisateur : " + userEmailToUpdate} 
                    prenom={prenom}
                    changePrenom={changePrenom}
                    nom={nom}
                    changeNom={changeNom}
                    email={email}
                    changeEmail={changeEmail}
                    changeMdp={changeMdp}
                    role={role}
                    changeRole={changeRole}
                    validNom={validNom}
                    changeValidNom={changeValidNom}
                    validPrenom={validPrenom}
                    changeValidPrenom={changeValidPrenom}
                    validEmail={validEmail}
                    changeValidEmail={changeValidEmail}
                    validPwd={validPwd}
                    changeValidPwd={changeValidPwd}
                    matchPwd={matchPwd}
                    changeMatchPwd={changeMatchPwd}
                    handleSubmit={handleUpdateUserSubmit}
                    isLoading={isLoading}
                    btnLabel={["Modification en cours ...", "Modifier"]}
                    msgContent={<p ref={updMsgRef} className={updMsg ? "errmsg" : "offscreen"} aria-live="assertive">{updMsg}</p>}
                />

                <ValidationModal 
                    show={showDeleteUser}
                    handleClose={handleCloseDeleteUser}
                    headerContent={"Suppression :"}
                    bodyContent={"Etes-vous sûr de vouloir supprimer le compte : " + userToDelete}
                    handleSubmit={handleDeleteUserSubmit}
                    isLoading={isLoading}
                    msgContent={<p ref={delMsgRef} className={delMsg ? "errmsg" : "offscreen"} aria-live="assertive">{delMsg}</p>}
                />
            </div>
        </>
    )
}

export default Admin;