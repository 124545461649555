import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { formatDate } from "@fullcalendar/core";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { isMobile } from "react-device-detect";

import { Card, Button, ListGroup, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import BrowserSidenav from "../components/BrowserSidenav.jsx";
import NavMobile from '../components/NavMobile';
import CampagneViewModal from "../components/CampagneViewModal";
import CampagneFormAddModal from "../components/CampagneFormAddModal";
import ValidationModal from "../components/ValidationModal";
import CreateEmpty from "../components/CreateEmpty";

const GETCAMPAGNES_URL = '/campagnes/getCampagnes';
const ADDCAMPAGNE_URL = '/campagnes/addCampagne';
const DELCAMPAGNE_URL = '/campagnes/deleteCampagne';
const UPDCAMPAGNE_URL = '/campagnes/increCampagne';
const GETCONTACTS_URL = '/contacts';
const GETUSER_URL = '/users';
const GETLISTES_URL = '/listes'
const CONTACTLISTE_URL = '/listes/getContacts'
const IMPORT_URL = '/exchange/importContacts';


const Campagnes = () => {
    //SESSION STATES
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;
    const role = auth?.role;

    //STORAGE
    const [campagnes, setCampagnes] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [utilisateurs, setUtilisateurs] = useState([]);

    //TOOLS
    const [needCampagneListReload, setNeedCampagneListReload] = useState(false)
    const [needContactCampagneListReload, setNeedContactCampagneListReload] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);

    //VIEW CAMPAGNE
    const [campagne, setCampagne] = useState({});

    //GET CAMPAGNES
    useEffect(() => {
        setNeedCampagneListReload(false);
        setIsLoadingList(true);
        const fetchCampagneList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCAMPAGNES_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setCampagnes(response.data["campagnes"]);
                setIsLoadingList(false);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchCampagneList()
        // eslint-disable-next-line
    }, [accessToken, needCampagneListReload])

    //GET CONTACTS
    useEffect(() => {
        const fetchContactsList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setContacts(response.data["contacts"]);

            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        role === "Manager" &&  fetchContactsList()
        // eslint-disable-next-line
    }, [accessToken])

    //GET USERS LIST
    useEffect(() => {
        const fetchUserList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETUSER_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                setUtilisateurs(response.data["utilisateurs"]);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        role === "Manager" && fetchUserList()
        // eslint-disable-next-line
    }, [accessToken])

    useEffect(() => {
        if (contacts) {
            var options = []
            contacts.forEach(contact => {
                var option = {value: contact.email, label: contact.raison_sociale}
                options.push(option)
            })
        }
        setKVContacts(options)
    }, [contacts])

    useEffect(() => {
        if (utilisateurs) {
            var options = []
            utilisateurs.forEach(utilisateur => {
                var option = {value: utilisateur.email, label: utilisateur.prenom + " " + utilisateur.nom}
                options.push(option)
            })
        }
        setKVUtilisateurs(options)
    }, [utilisateurs])

    //CAMPAGNE
    const [showViewCampagne, setShowViewCampagne] = useState(false);

    const handleShowViewCampagne = () => {
        setShowViewCampagne(true);
    }

    const handleCloseViewCampagne = () => {
        setShowViewCampagne(false);
        setTimeout(() => {
            setCampagne({});
        }, 200);
    }

    //LISTES
    const [listes, setListes] = useState([]);
    const [kVListe, setKVListe] = useState([]);
    const [selectedListe, setSelectedListe] = useState(0);

    //GET LISTES
    useEffect(() => {
        const fetchLists = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETLISTES_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setListes(response.data["listes"]);
            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchLists()
    }, [accessToken])

    useEffect(() => {
        if (listes) {
            var options = []
            listes.forEach(liste => {
                var option = {value: liste.id, label: liste.nom}
                options.push(option)
            })
        }
        setKVListe(options)
    }, [listes])    

    const handleSubmitAddCampagneListe = e => {
        e.preventDefault()
        setIsLoading(true);
        
        let cEmails = []
        let uEmails = []
        
        const handledErr = [401];

        const idListe = selectedListe.value;
        const loadContactbyUser = async () =>{
            try {
                const response = await axios.post(CONTACTLISTE_URL,
                    JSON.stringify({accessToken, "id": String(idListe)}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                
                response.data["contacts"].forEach(contact => {
                    cEmails.push(contact.email)
                })
                
                uEmails.push(selectedUtilisateurs.value)
                
                setContacts_emails(cEmails);
                setUtilisateurs_emails(uEmails);

            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
        
        if(idListe !== 0){
            loadContactbyUser();
        }
    }

    //CREATE CAMPAGNE
    const [kVContacts, setKVContacts] = useState([]);
    const [kVUtilisateurs, setKVUtilisateurs] = useState([]);

    const [selectedContacts, setSelectedContacts] = useState(0);
    const [selectedUtilisateurs, setSelectedUtilisateurs] = useState(0);

    const [showAddCampagne, setShowAddCampagne] = useState(false);

    const [description, setDescription] = useState("");
    const handleChangeDateDescription = value => {
        setDescription(value);
    }
    
    const [date_debut, setDate_debut] = useState("");
    const handleChangeDateDebut = value => {
        setDate_debut(value);
    }
    const [date_fin, setDate_fin] = useState("");
    const handleChangeDateFin = value => {
        setDate_fin(value);
    }

    const handleChangeContacts = selectedOptions => {
        setSelectedContacts(selectedOptions)
    }

    const handleChangeListe = selectedOptions => {
        setSelectedListe(selectedOptions)
    }

    const handleChangeUtilisateurs = selectedOptions => {
        setSelectedUtilisateurs(selectedOptions)
    }

    const handleShowAddCampagne = () => {
        setShowAddCampagne(true);
    }

    const handleCloseAddCampagne = () => {
        setShowAddCampagne(false);
        setContacts_emails([]);
        setSelectedContacts([]);
        setUtilisateurs_emails([]);
        setSelectedUtilisateurs([]);
        setSelectedListe([]);
        setDescription("");
        setDate_debut("");
        setDate_fin("");
    }

    const [contacts_emails, setContacts_emails] = useState([])
    const [utilisateurs_emails, setUtilisateurs_emails] = useState([])

    const handleSubmitAddCampagne = e => {
        e.preventDefault()
        setIsLoading(true);
        
        let cEmails = []
        let uEmails = []

        selectedContacts.forEach(contact => {
            cEmails.push(contact.value)
        })
        
        uEmails.push(selectedUtilisateurs.value);

        setContacts_emails(cEmails);
        setUtilisateurs_emails(uEmails);
    }

    useEffect(() => {
        const addCampagne = async () => {
            const handledErr = [401];  
            try {
                const response = await axios.post(ADDCAMPAGNE_URL,
                    JSON.stringify({accessToken, utilisateurs_emails, contacts_emails, 
                        "campagne" : {description, date_fin, date_debut}}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                setUtilisateurs(response.data["utilisateurs"]);
                setTimeout(function () {
                    setIsLoading(false);
                    setNeedCampagneListReload(true);
                    handleCloseAddCampagne();
                }, 2000);
            } catch (err) {
                if (!err?.response) {
                    setIsLoading(false);
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setIsLoading(false);
                    console.log(err.response?.data["message"]);
                } else {
                    setIsLoading(false);
                    console.log("Problème interne lié au serveur !");
                }
            }
        }

        //FETCH
        contacts_emails.length !== 0 && utilisateurs_emails.lenght !== 0 && addCampagne();
        // eslint-disable-next-line
    }, [contacts_emails, utilisateurs_emails])

    const [campagne_id, setCampagne_id] = useState("");
    const [showDeleteCampagne, setShowDeleteCampagne] = useState(false);

    const handleShowDeleteCampagne = (e) => {
        setCampagne_id(e.target.value);
        setShowDeleteCampagne(true);
    }

    const handleCloseDeleteCampagne = () => {
        setShowDeleteCampagne(false);
        setCampagne_id("");
    }

    //DELETE CAMPAGNE
    const handleDeleteCampagne = async e => {
        e.preventDefault();
        setIsLoading(true);
        const handledErr = [401];
        try {
            await axios.post(DELCAMPAGNE_URL,
                JSON.stringify({accessToken, campagne_id}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            setTimeout(function () {
                setIsLoading(false);
                setNeedCampagneListReload(true);
                handleCloseDeleteCampagne();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setIsLoading(false);
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setIsLoading(false);
                console.log(err.response?.data["message"]);
            } else {
                setIsLoading(false);
                console.log("Problème interne lié au serveur !");
            }
        }
    }

    //UPD CAMPAGNE
    const handleUPDCampagne = async (campagne_id, contact_id, statut_contact) => {
        
        const handledErr = [401];  
            try {
                await axios.post(UPDCAMPAGNE_URL,
                    JSON.stringify({accessToken, "contact_id": String(contact_id), "contact_statut": String(statut_contact)}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setNeedCampagneListReload(true);
                setNeedContactCampagneListReload(campagne_id);

            } catch (err) {
                if (!err?.response) {
                    setIsLoading(false);
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setIsLoading(false);
                    console.log(err.response?.data["message"]);
                } else {
                    setIsLoading(false);
                    console.log("Problème interne lié au serveur !");
                }
            }
    }

    useEffect(() => {     
        if(needContactCampagneListReload > 0){ 
            const mycamp = campagnes?.filter(item => item.id === needContactCampagneListReload);
            setCampagne(mycamp[0]);
            setNeedContactCampagneListReload(0);
        }
        // eslint-disable-next-line
    }, [campagnes])

    //IMPORT CSV
    const [parsedCSVInfo, setParsedCSVInfo] = useState();
    const changeParsedCSVInfo = (value) => setParsedCSVInfo(value);
    const [parsedCSV, setParsedCSV] = useState();
    const changeParsedCSV = (value) => setParsedCSV(value);

    const [clientCSVKey, setClientKey] = useState("none");
    const changeClientCSVKey = (value) => {value !== 0 ? setClientKey(parsedCSVInfo.fields[value - 1]) : setClientKey("none");};

    const [emailCSVKey, setEmailKey] = useState("none");
    const changeEmailCSVKey = (value) => {value !== 0 ? setEmailKey(parsedCSVInfo.fields[value - 1]) : setEmailKey("none");};

    const [prenomCSVKey, setPrenomCSVKey] = useState("none");
    const changePrenomCSVKey = (value) => {value !== 0 ? setPrenomCSVKey(parsedCSVInfo.fields[value - 1]) : setPrenomCSVKey("none");};

    const [nomCSVKey, setNomCSVKey] = useState("none");
    const changeNomCSVKey = (value) => {value !== 0 ? setNomCSVKey(parsedCSVInfo.fields[value - 1]) : setNomCSVKey("none");};

    const [fonctionCSVKey, setFonctionCSVKey] = useState("none");
    const changeFonctionCSVKey = (value) => {value !== 0 ? setFonctionCSVKey(parsedCSVInfo.fields[value - 1]) : setFonctionCSVKey("none");};

    const [telPortableCSVKey, setTelPortableCSVKey] = useState("none");
    const changeTelPortableCSVKey = (value) => {value !== 0 ? setTelPortableCSVKey(parsedCSVInfo.fields[value - 1]) : setTelPortableCSVKey("none");};

    const [telDirectCSVKey, setTelDirectCSVKey] = useState("none");
    const changeTelDirectCSVKey = (value) => {value !== 0 ? setTelDirectCSVKey(parsedCSVInfo.fields[value - 1]) : setTelDirectCSVKey("none");};

    const [linkedinCSVKey, setLinkedinCSVKey] = useState("none");
    const changeLinkedinCSVKey = (value) => {value !== 0 ? setLinkedinCSVKey(parsedCSVInfo.fields[value - 1]) : setLinkedinCSVKey("none");};

    const [statutJuridiqueCSVKey, setStatutJuridiqueCSVKey] = useState("none");
    const changeStatutJuridiqueCSVKey = (value) => {value !== 0 ? setStatutJuridiqueCSVKey(parsedCSVInfo.fields[value - 1]) : setStatutJuridiqueCSVKey("none");};

    const [raisonSocialeCSVKey, setRaisonSocialeCSVKey] = useState("none");
    const changeRaisonSocialeCSVKey = (value) => {value !== 0 ? setRaisonSocialeCSVKey(parsedCSVInfo.fields[value - 1]) : setRaisonSocialeCSVKey("none");};

    const [telStandardCSVKey, setTelStandardCSVKey] = useState("none");
    const changeTelStandardCSVKey = (value) => {value !== 0 ? setTelStandardCSVKey(parsedCSVInfo.fields[value - 1]) : setTelStandardCSVKey("none");};

    const [adresseCSVKey, setAdresseCSVKey] = useState("none");
    const changeAdresseCSVKey = (value) => {value !== 0 ? setAdresseCSVKey(parsedCSVInfo.fields[value - 1]) : setAdresseCSVKey("none");};

    const [commentaireCSVKey, setCommentaireCSVKey] = useState("none");
    const changeCommentaireCSVKey = (value) => {value !== 0 ? setCommentaireCSVKey(parsedCSVInfo.fields[value - 1]) : setCommentaireCSVKey("none");};

    const handleImportCSVSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const handledErr = [409, 401];
        if (emailCSVKey !== "none") {
            let cEmails = []
            let uEmails = []
            parsedCSV.forEach(contact => {
                cEmails.push(contact.Email);
            })
            uEmails.push(selectedUtilisateurs.value);

            try {
                await axios.post(IMPORT_URL,
                    JSON.stringify({
                        accessToken, "role": role, "is_client": clientCSVKey, "assoc_u": selectedUtilisateurs.value,
                        "champs" : 
                            {"email": emailCSVKey, "prenom": prenomCSVKey, "nom": nomCSVKey, "fonction": fonctionCSVKey, "tel_portable": telPortableCSVKey, 
                            "tel_direct": telDirectCSVKey, "linkedin": linkedinCSVKey, "statut_juridique": statutJuridiqueCSVKey, "raison_sociale": raisonSocialeCSVKey, "tel_standard": telStandardCSVKey, "adresse": adresseCSVKey, "commentaire": commentaireCSVKey}, 
                        "valeures": parsedCSV
                    }), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                
                setContacts_emails(cEmails);
                setUtilisateurs_emails(uEmails);

            } catch (err) {
                if (!err?.response) {
                    console.log("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    console.log(err.response?.data["message"]);
                } else {
                    console.log("Problème interne lié au serveur !");
                }
            }
        }
    };

    //HOVER BUTTON
    const handleMouseOver = async (e) => {
        e.currentTarget.className = "contacts-list-item list-group-item hover";
    }

    const handleMouseOut = async (e) => {
        e.currentTarget.className = "contacts-list-item list-group-item";
    }

    return (
        <>
            {!isMobile ? <BrowserSidenav /> : <NavMobile handleLink={handleShowAddCampagne} handleData="Ajouter une campagne" name="Mes campagnes" role={role} />}
            <div className={!isMobile ? "browser-content page-clients" : "mobile-content page-clients"}>
                {!isMobile ? <Card>
                    <Card.Header className="contacts-list-header">
                        <h2>Mes campagnes</h2>
                            <Button disabled={role === "Manager" ? false : true} data-tip="Ajouter une campagne" variant="plus" onClick={() => handleShowAddCampagne()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
                                    <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
                                </svg>
                            </Button>
                    </Card.Header>
                </Card>
                : "" }

                {isLoadingList
                    ? 
                    <div style={{
                        marginTop: "50px",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        <Spinner animation="border" size="sm"/> Chargement
                    </div>
                    : <ListGroup variant="flush" className="contacts-list">
                        {!campagnes.length ? <CreateEmpty name="Campagne" namebtn="Créer une campagne" showModal={setShowAddCampagne} /> : campagnes.map(campagne => (
                        <div key={campagne.id} className="contacts-list-item-container">
                            <ListGroup.Item className="contacts-list-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                <div className="contacts-list-item-content" onClick={() => {setCampagne(campagne); handleShowViewCampagne();}}>
                                    <div className="header-contact">
                                        Du <strong>{formatDate(campagne.date_debut, {
                                            month: 'numeric',
                                            year: 'numeric',
                                            day: 'numeric',
                                            locale: 'fr'
                                        })}</strong> au <strong>{formatDate(campagne.date_fin, {
                                            month: 'numeric',
                                            year: 'numeric',
                                            day: 'numeric',
                                            locale: 'fr'
                                        })}</strong>
                                    </div>
                                    <div className="body-contact">
                                        <div>
                                            Description : {campagne.description}
                                        </div>
                                        <div>
                                            Tâche(s) réalisée(s) : {campagne.contacts.filter(item => item.statut === true).length}/{campagne.contacts.length}
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-container">
                                        <Button disabled={role === "Manager" ? false : true} variant="icon" className="m-1" value={campagne.id} onClick={handleShowDeleteCampagne}>
                                            <FaTrashAlt />
                                        </Button>
                                </div>
                            </ListGroup.Item>
                        </div>
                        ))}
                    </ListGroup>
                }
            </div>

            <CampagneViewModal
                show={showViewCampagne}
                handleClose={handleCloseViewCampagne}
                handleUPDCampagne={handleUPDCampagne}
                campagne={campagne}
            />

            <CampagneFormAddModal
                show={showAddCampagne}
                handleClose={handleCloseAddCampagne}
                handleSubmit={handleSubmitAddCampagne}
                handleSubmitListe={handleSubmitAddCampagneListe}
                handleImportCSVSubmit={handleImportCSVSubmit}
                headerContent={"Ajouter une campagne"}

                contacts={contacts}
                handleChangeContacts={handleChangeContacts}
                selectedContacts={selectedContacts}
                kVContacts={kVContacts}

                handleChangeListe={handleChangeListe}
                selectedListe={selectedListe}
                kVListe={kVListe}

                utilisateurs={utilisateurs}
                handleChangeUtilisateurs={handleChangeUtilisateurs}
                selectedUtilisateurs={selectedUtilisateurs}
                kVUtilisateurs={kVUtilisateurs}
                handleChangeDateDebut={handleChangeDateDebut}
                handleChangeDateFin={handleChangeDateFin}
                handleChangeDescription={handleChangeDateDescription}

                parsedCSVInfo={parsedCSVInfo}
                changeParsedCSVInfo={changeParsedCSVInfo}
                changeParsedCSV={changeParsedCSV}
                changeClientCSVKey={changeClientCSVKey}
                changeEmailCSVKey={changeEmailCSVKey}
                changePrenomCSVKey={changePrenomCSVKey}
                changeNomCSVKey={changeNomCSVKey}
                changeFonctionCSVKey={changeFonctionCSVKey}
                changeTelPortableCSVKey={changeTelPortableCSVKey}
                changeTelDirectCSVKey={changeTelDirectCSVKey}
                changeLinkedinCSVKey={changeLinkedinCSVKey}
                changeStatutJuridiqueCSVKey={changeStatutJuridiqueCSVKey}
                changeRaisonSocialeCSVKey={changeRaisonSocialeCSVKey}
                changeTelStandardCSVKey={changeTelStandardCSVKey}
                changeAdresseCSVKey={changeAdresseCSVKey}
                changeCommentaireCSVKey={changeCommentaireCSVKey}

                isLoading={isLoading}
                btnLabel={["Création en cours ...", "Créer", "Importation en cours", "Importer et créer"]}
            />

            <ValidationModal 
                show={showDeleteCampagne}
                handleSubmit={handleDeleteCampagne}
                handleClose={handleCloseDeleteCampagne}
                headerContent={"Suppression :"}
                isLoading={isLoading}
                bodyContent={"Etes-vous sûr de vouloir supprimer la campagne ?"}
            />
        </>
    )
}

export default Campagnes;