import React from "react";
import { Modal, Stack, Form, Button } from "react-bootstrap";

const ContactAddFilesModal = (props) => (
    <Modal centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Stack>
                    <Form onSubmit={props.handleSubmit} className={"addFile"}>
                        <Form.Group controlId="contactFormDocumentsLies" className={"searchFile"}> 
                            <Form.Control 
                                multiple
                                type="file"
                                accept=".doc,.docx,.pdf,.png,.jpg,.jpeg,.ppt,.pptx,.xls,.xlsx"
                                style={{width: "310px", margin: "0 auto"}}
                                onChange={e => {props.changeSelectedFiles(e.target.files); props.changeIsFilesPicked(true);}} 
                            />
                        </Form.Group>
                        <Button 
                                type="submit"
                                variant="sub"
                                disabled={props.isLoading}
                            >
                                {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                        <Button 
                            variant="sub"
                            onClick={props.handleClose}
                        >
                            Annuler
                        </Button>
                    </Form>
                </Stack>
            </Modal.Body>
    </Modal>
);

export default ContactAddFilesModal;