import React from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "@fullcalendar/core";
import { FaCheck, FaRedoAlt } from "react-icons/fa";

const CampagneViewModal = (props) => {

    return (
        <>
            <Modal centered show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={"text-center"}>
                            Campagne effective du 
                            <br/>
                            Du <strong>{formatDate(props.campagne.date_debut, {
                                month: 'numeric',
                                year: 'numeric',
                                day: 'numeric',
                                locale: 'fr'
                            })}</strong> au <strong>{formatDate(props.campagne.date_fin, {
                                month: 'numeric',
                                year: 'numeric',
                                day: 'numeric',
                                locale: 'fr'
                            })}</strong>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="campagne_view">
                    <p className="campagne_item">Tâche(s) réalisée(s) : {props.campagne.contacts?.filter(item => item.statut === true).length}/{props.campagne.contacts?.length}</p>

                    <p className="campagne_item">Commercial : {props.campagne.utilisateurs?.map(utilisateur => (<strong key={utilisateur.email}>{utilisateur.prenom} {utilisateur.nom}</strong>))}
                    </p>

                    <p className={"campagne_item"}>Description :<br/>
                    {props.campagne.description}</p>

                    <div className="contacts_campagne">
                        <h3>Contacts</h3>
                        <ListGroup variant="flush"> 
                            {props.campagne.contacts?.map(contact => (
                                <ListGroup.Item key={contact.contact_email} className={contact.statut ? "check" : ""}>
                                    <p><Link to={"/contacts/"+contact.contact_email} style={{ color: 'inherit', textDecoration: 'inherit'}}><strong>{contact.contact_entreprise}</strong> - {contact.contact_nom} {contact.contact_prenom}</Link></p>
                                    <Button variant="icon" onClick={() => props.handleUPDCampagne(props.campagne.id,contact.id,contact.statut ? "0" : "1")}>{contact.statut ? <FaRedoAlt /> : <FaCheck />}</Button>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="sub"
                        onClick={props.handleClose}
                    >
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default CampagneViewModal;