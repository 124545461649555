import React from "react";
import { isMobile } from "react-device-detect";

const Unauthorized = () => {
    if (isMobile) {
        return (
            <>
                {/* TODO ACCES MOBILE */}
                <h1>Unauthorized</h1>
            </>
        )
    } else {
        return (
            <>
                <h1>Unauthorized</h1>
            </>
        )
    }
}

export default Unauthorized;