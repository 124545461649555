import React from "react";
import { Modal, Stack, Form, Button, Row, Col } from "react-bootstrap";

const UserFormModal = (props) => (
    <Modal centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title className="text-center">
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Stack>
                    <Form onSubmit={props.handleSubmit}>
                        <Row className="mb-3" style={{alignItems: "baseline"}}> 
                        <Form.Group as={Col} controlId="userFormPrenom">
                               {/*  <div className={props.validPrenom ? "hide" : "invalide"}>✗</div> */}
                                <div className={props.validPrenom ? "valide" : "hide"}>✓</div>
                                <Form.Label>
                                    Prénom
                                </Form.Label>
                                <Form.Control 
                                    type="prenom" 
                                    placeholder="Prénom utilisateur"
                                    autoComplete="off"
                                    value={props.prenom}
                                    ref={props.prenomRef}
                                    required={props.required}
                                    onChange={e => {props.changePrenom(e.target.value); props.changeValidPrenom(e.target.value)}} 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="userFormNom">
                                {/* <div className={props.validNom ? "hide" : "invalide"}>✗</div> */}
                                <div className={props.validNom ? "valide" : "hide"}>✓</div>
                                <Form.Label>
                                    Nom
                                </Form.Label>
                                <Form.Control 
                                    type="nom" 
                                    placeholder="Nom utilisateur"
                                    autoComplete="off"
                                    value={props.nom}
                                    required={props.required}
                                    onChange={e => {props.changeNom(e.target.value); props.changeValidNom(e.target.value)}} 
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="userFormEmail">
                            {/* <div className={props.validEmail ? "hide" : "invalide"}>✗</div> */}
                            <div className={props.validEmail ? "valide" : "hide"}>✓</div>
                            <Form.Label>
                                Adresse email
                            </Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Email utilisateur" 
                                autoComplete="new-email"
                                onChange={e => {props.changeEmail(e.target.value); props.changeValidEmail(e.target.value)}} 
                                value={props.email}
                                required={props.required}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="userFormPassword">
                            {/* <div className={props.validPwd ? "hide" : "invalide"}>✗</div> */}
                            <div className={props.validPwd ? "valide" : "hide"}>✓</div>
                            <Form.Label>
                                Mot de passe
                            </Form.Label>
                            <Form.Control 
                                type={props.showPwd ? "" : "password"}
                                placeholder="Mot de passe utilisateur" 
                                autoComplete="new-password"
                                onChange={e => {props.changeMdp(e.target.value); props.changeValidPwd(e.target.value); props.changeMatchPwd(e.target.value)}} 
                                value={props.mdp} 
                                required={props.required}
                            />
                        </Form.Group>
                        <div className={props.matchPwd ? "valide" : "hide"}>✓</div>
                        <Form.Group className="mb-3" controlId="userFormVerifPassword">
                            <Form.Control 
                                type={props.showPwd ? "" : "password"}
                                placeholder="Confirmation du mot de passe" 
                                disabled={!props.validPwd}
                                autoComplete="off"
                                onChange={e => props.changeMatchPwd(e.target.value)} 
                                value={props.mdp} 
                                required={props.required} 
                            />
                            <div style={{marginTop: "0.5rem"}}>
                                <input type="checkbox" onChange={e => props.changeShowPwd(e.target.checked)}>
                                </input>
                                <label style={{marginLeft: "5px"}}>
                                    Afficher mot de passe
                                </label>
                            </div>
                        </Form.Group>
                        <div style={{fontSize: "13px", color: "#aaaaaa"}}>
                            Le mot de passe doit contenir :<br/>
                            - Entre 8 caractères et 16 caractères<br/>
                            - Une majuscule<br/>
                            - Un caractère spécial<br/>
                            - Des lettres et des chiffres
                        </div>
                        <Row className="mb-3 text-center">
                            <Form.Label>
                                Rôle
                            </Form.Label>
                            <Form.Group as={Col} controlId="userFormRole">
                                <Form.Check
                                    inline
                                    type="radio" 
                                    aria-label="radio"
                                    label="Manager" 
                                    onChange={props.changeRole} 
                                    value="Manager" 
                                    checked={props.role === "Manager"}
                                />
                            </Form.Group>   
                            <Form.Group as={Col} controlId="userFormCommercial">
                                <Form.Check
                                    inline
                                    type="radio" 
                                    aria-label="radio"
                                    label="Commercial" 
                                    onChange={props.changeRole}
                                    value="Commercial"
                                    checked={props.role === "Commercial"}
                                />
                            </Form.Group>
                        </Row>
                        <Button 
                            className="w-100"
                            type="submit"
                            variant="sub"
                            disabled={props.isLoading}
                        >
                            {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                    </Form>
                </Stack>
        </Modal.Body>
        <Modal.Footer>
            {props.msgContent}
        </Modal.Footer>
    </Modal>
);

export default UserFormModal;