import React from "react";
import { Modal, Stack, Form, Button, Row, Col } from "react-bootstrap";

const ListFormAddModal = (props) => (
    <Modal centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title className="text-center">
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Stack>
                    <Form noValidate validated={props.validated} onSubmit={props.handleSubmit}>
                        <Row className="mb-3" style={{alignItems: "baseline"}}>
                            <Form.Group as={Col} controlId="listFormNom">
                                <Form.Label>
                                    Nom de la liste*
                                </Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Nom"
                                    autoComplete="off"
                                    ref={props.focusRef}
                                    value={props.nom}                                    
                                    onChange={e => {props.changeNom(e.target.value);}} 
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Choisissez un nom de liste</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button 
                            className="w-100"
                            type="submit"
                            variant="sub"
                            disabled={props.isLoading}
                        >
                            {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                    </Form>
                </Stack>
        </Modal.Body>
        <Modal.Footer>
            {props.msgContent}
        </Modal.Footer>
    </Modal>
);

export default ListFormAddModal;