import React, { useState, useEffect } from "react"
import { Modal, Stack, Form, Button, Row, Col } from "react-bootstrap";
import Select, {components} from 'react-select';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import Papa from "papaparse";

import modeleCSV from "../assets/Modèle_import_Lucky.xlsx"

registerLocale('fr', fr);
setDefaultLocale('fr');

const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Aucun commercial</span> 
      </components.NoOptionsMessage>
    );
};
const NoOptionsMessageC = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Aucun contact</span> 
      </components.NoOptionsMessage>
    );
};

const CampagneFormAddModal = (props) => {
    const [showAddCamp, setShowAddCamp] = useState(true);
    const [showCampOne, setShowCampOne] = useState(false);
    const [showCampList, setShowCampList] = useState(false);
    const [showCampCSV, setShowCampCSV] = useState(false);
    const [showCheckFieldsCSV, setShowCheckFieldsCSV] = useState(false);
    const [typeOfContact, setTypeOfContact] = useState({});

    const [selectedFiles, setSelectedFiles] = useState();
	const [isFilesPicked, setIsFilesPicked] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setShowAddCamp(true);
        setShowCampOne(false);
        setShowCampList(false);
        setShowCampCSV(false);
        setSelectedFiles([]);
        setIsFilesPicked(false);
        }, 200);
    }, [props.show])

    useEffect(() => {
        if (isFilesPicked === true) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [isFilesPicked])

    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const options = [
        { value: '1', label: 'Sélectionner les contacts un par un' },
        { value: '2', label: 'Sélectionner une liste' },
        { value: '3', label: 'Importer un fichier CSV' }
    ]

    const goToNextStep = (e) => {
        e.preventDefault();
        setShowAddCamp(false);
        if(typeOfContact["value"] === '1'){
            setShowCampOne(true);
        }else if(typeOfContact["value"] === '2'){
            setShowCampList(true);
        }else{
            setShowCampCSV(true);
        }
    }

    const goToPreviousStep = () => {
        setShowAddCamp(true); 
        setShowCampList(false);
    }

    const goToCheckCSVFields = () => {
        Papa.parse(selectedFiles[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                props.changeParsedCSVInfo(results.meta)
                props.changeParsedCSV(results.data)
            },
        });

        setShowCampCSV(false); 
        setShowCheckFieldsCSV(true);
    }

    useEffect(() => {
        props.handleChangeDateFin(endDate);
        // eslint-disable-next-line
    }, [endDate])

    useEffect(() => {
        props.handleChangeDateDebut(startDate);
        // eslint-disable-next-line
    }, [startDate])

    const handleSetTypeOfContact = selectedOptions => {
        setTypeOfContact(selectedOptions)
    }

    return(
    <Modal dialogClassName="campagne-modal-size" centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title className="text-center">
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Stack>
                {showAddCamp ?
                    <Form onSubmit={e => goToNextStep(e)}>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Commerciaux*
                                </Form.Label>
                                <Select
                                    isMulti={false}
                                    placeholder={"Choisir un/des commercial(aux)"} 
                                    options={props.kVUtilisateurs} 
                                    value={props.selectedUtilisateurs}
                                    onChange={props.handleChangeUtilisateurs}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    components={{ NoOptionsMessage }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group as={Col} controlId="campagneFormDate">
                                <Form.Label>
                                    Date de début
                                </Form.Label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="P"
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="campagneFormDate">
                                <Form.Label>
                                    Date de fin*
                                </Form.Label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="P"
                                    required
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group controlId="campagneFormType">
                                <Form.Label>
                                    Contacts
                                </Form.Label>
                                <Select 
                                    options={options}
                                    placeholder={"Sélection des contacts"} 
                                    onChange={handleSetTypeOfContact}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={"mb-3"}>
                            <Form.Group controlId="campagneFormDescription">
                                <Form.Label>
                                    Description*
                                </Form.Label>
                                <Form.Control 
                                    as="textarea"
                                    type="textarea" 
                                    placeholder="Description"
                                    autoComplete="off"
                                    value={props.description}
                                    onChange={e => props.handleChangeDescription(e.target.value)}
                                    rows={8}
                                    style={{ height: '200px' }}
                                    required
                                />
                            </Form.Group>
                        </Row>

                        <Button 
                            variant="sub" 
                            style={{width: "auto", margin: "0 auto"}}
                            type="submit">
                            Suivant
                        </Button>
                    </Form>
                : showCampOne ? 
                    <Form onSubmit={e => props.handleSubmit(e)}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Contacts*
                                </Form.Label>
                                <Select
                                    isMulti={true}
                                    placeholder={"Choisir un/des contact(s)"} 
                                    options={props.kVContacts} 
                                    value={props.selectedContacts}
                                    onChange={props.handleChangeContacts}
                                    closeMenuOnSelect={false}
                                    isSearchable={true}
                                    components={{ NoOptionsMessageC }}
                                />
                            </Form.Group>
                        </Row>

                        <Button 
                            variant="sub" 
                            style={{marginRight: "20px"}}
                            onClick={() => goToPreviousStep()}>
                            Retour
                        </Button>
                        <Button 
                            type="submit"
                            variant="sub"
                            disabled={props.isLoading}
                        >
                            {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                    </Form>
                : showCampList ? 
                    <Form onSubmit={e => props.handleSubmitListe(e)}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Liste*
                                </Form.Label>
                                <Select
                                    isMulti={false}
                                    placeholder={"Choisir une liste"} 
                                    options={props.kVListe} 
                                    value={props.selectedListe}
                                    onChange={props.handleChangeListe}
                                    closeMenuOnSelect={true}
                                    isSearchable={false}
                                    components={{ NoOptionsMessageC }}
                                />
                            </Form.Group>
                        </Row>
                        <Button 
                            variant="sub" 
                            style={{marginRight: "20px"}}
                            onClick={() => goToPreviousStep()}>
                            Retour
                        </Button>                        
                        <Button 
                            type="submit"
                            variant="sub"
                            disabled={props.isLoading}
                        >
                            {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                    </Form>
                : showCampCSV ? 
                    <Form>
                        <Form.Group className="mb-3" style={{width: "640px", margin: "0 auto", textAlign: "center"}} controlId="campagneUploadCSV">
                            <p>Importez un fichier de contacts (Enregistré au format <strong>CSV UTF-8</strong> depuis Excel)</p>
                            <Form.Control 
                                type="file"
                                accept=".csv"
                                style={{width: "50%", margin: "0 auto"}}
                                onChange={e => {setSelectedFiles(e.target.files); e?.target?.files.length > 0 ? setIsFilesPicked(true) : setIsFilesPicked(false);}}
                            />
                        </Form.Group>

                        <Form.Group as={Row} controlId="adminUploadCSVNavBtn">
                            <Button 
                                disabled={disableBtn} 
                                variant="sub" 
                                style={{width: "auto", margin: "0 auto"}}
                                onClick={() => goToCheckCSVFields()}>
                                Suivant
                            </Button>
                        </Form.Group>
                        <p><Button variant="link" href={modeleCSV}>Téléchargez un modèle au format Excel .xlsx</Button></p>
                    </Form>
                : showCheckFieldsCSV ?
                    <Form onSubmit={props.handleImportCSVSubmit} style={{width: "600px", margin: "0 auto"}}>
                        <p style={{textAlign: "center", fontWeight: "bold", fontSize: "18px"}}>Sélectionner la colonne du fichier correspondante à chaque champ</p>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Client">
                                <Form.Label>
                                    Champs de données
                                </Form.Label>

                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Client ou Prospect"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Client">
                                <Form.Label>
                                    Colonne du fichier correspondante 
                                </Form.Label>

                                <Form.Select 
                                    aria-label="CSVClient"
                                    onChange={e => {props.changeClientCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Nom">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Nom"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Nom">
                                <Form.Select 
                                    aria-label="CSVNom"
                                    onChange={e => {props.changeNomCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Prenom">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Prenom"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Prenom">
                                <Form.Select 
                                    aria-label="CSVPrenom"
                                    onChange={e => {props.changePrenomCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Email">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Email"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Email">

                                <Form.Select 
                                    aria-label="CSVEmail"
                                    onChange={e => {props.changeEmailCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelPortable">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Téléphone portable"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelPortable">
                                <Form.Select 
                                    aria-label="CSVTelPortable"
                                    onChange={e => {props.changeTelPortableCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelDirect">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Ligne directe"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelDirect">
                                <Form.Select 
                                    aria-label="CSVTelDirect"
                                    onChange={e => {props.changeTelDirectCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Linkedin">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"LinkedIn"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Linkedin">
                                <Form.Select 
                                    aria-label="CSVLinkedin"
                                    onChange={e => {props.changeLinkedinCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.RaisonSociale">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Raison Sociale"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.RaisonSociale">
                                <Form.Select 
                                    aria-label="CSVRaisonSociale"
                                    onChange={e => {props.changeRaisonSocialeCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Fonction">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Fonction"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Fonction">
                                <Form.Select 
                                    aria-label="CSVFonction"
                                    onChange={e => {props.changeFonctionCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.StatutJuridique">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Type d'entreprise"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.StatutJuridique">
                                <Form.Select 
                                    aria-label="CSVStatutJuridique"
                                    onChange={e => {props.changeStatutJuridiqueCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.TelStandard">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Standard"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.TelStandard">
                                <Form.Select 
                                    aria-label="CSVTelStandard"
                                    onChange={e => {props.changeTelStandardCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Adresse">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Adresse"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Adresse">
                                <Form.Select 
                                    aria-label="CSVAdresse"
                                    onChange={e => {props.changeAdresseCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="adminFormCompareLuckyFields.Commentaire">
                                <Form.Control 
                                    readOnly={true}
                                    autoComplete="off"
                                    value={"Commentaire"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="adminFormCompareCSVFields.Commentaire">
                                <Form.Select 
                                    aria-label="CSVCommentaire"
                                    onChange={e => {props.changeCommentaireCSVKey(e.target.selectedIndex);}}>
                                    <option>Choisir une colonne</option>
                                    {props.parsedCSVInfo?.fields.map(field => (
                                        <option key={field}>{field}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <Form.Group as={Row} controlId="adminCompareCSVNavBtn">
                                <Button 
                                    variant="sub" 
                                    style={{width: "auto", margin: "0 auto"}}
                                    onClick={e => {setShowCheckFieldsCSV(false); setShowCampCSV(true); setSelectedFiles([]); setIsFilesPicked(false);}}>
                                    Retour
                                </Button>                       
                                <Button 
                                    type="submit"
                                    variant="sub"
                                    style={{width: "auto", margin: "0 auto"}}
                                    disabled={props.isLoading}
                                >
                                    {props.isLoading ? props.btnLabel[2] : props.btnLabel[3]}
                                </Button>
                        </Form.Group>
                    </Form>
                : "" }
                </Stack>
        </Modal.Body>
        <Modal.Footer>
            {props.msgContent}
        </Modal.Footer>
    </Modal>
)
}

export default CampagneFormAddModal;