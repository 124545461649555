import React, { useState, useEffect, useRef } from "react";
import axios from "../api/axios";

const Weather = () => {
  const storage = localStorage.getItem('weather') ? localStorage.getItem('weather') : 'Le Havre';
  const [location, setLocation] = useState(storage);
  //const location = useMemo(() => (localoca), [localoca]);
  const [days, setDays] = useState([]);
  const [daysFull, setDaysFull] = useState([]);
  const [temps, setTemps] = useState([]);
  const [minTemps, setMinTemps] = useState([]);
  const [maxTemps, setMaxTemps] = useState([]);
  const [weather, setWeather] = useState([]);
  const [icons, setIcons] = useState([]);
  const [displayIndex, setDisplayIndex] = useState(0);
  const locationInput = useRef(null);

  const weather_fr = {"Clear" : "Clair", "Cloudy" : "Nuageux", "Snow" : "Neige", "Rain" : "Pluie", "Drizzle" : "Bruine", "Thunderstorm" : "Orage"};

  const convertWeatherIcons = weather => {
    switch (weather) {
      case "Clear":
        return "circle-outline";
      case "Clouds":
        return "weather-cloudy";
      case "Snow":
        return "weather-snowy";
      case "Rain":
        return "weather-pouring";
      case "Drizzle":
        return "weather-pouring";
      case "Thunderstorm":
        return "weather-lightning-rainy";
      default:
        return "weather-cloudy";
    }
  };

  const handleFocus = e => {
    e.target.select();
  }
  
  const changeLocation = (e) => {
    e.preventDefault();
    const inputLocation = locationInput.current.value;
    localStorage.setItem('weather', inputLocation);
    setLocation(inputLocation);
  };

  const setIndex = index => {
    setDisplayIndex(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      const loca = encodeURIComponent(location);
      const urlPrefix = "https://api.openweathermap.org/data/2.5/forecast?q=";
      const urlSuffix = "&APPID=e06804715728e0dbe35b1c236fcc29d0&units=metric";
      const url = [urlPrefix, loca, urlSuffix].join("");
      const response = await axios.get(url);
      const datanew = response.data;
      const list = datanew.list;
      const nearestHr = computeNearestHr();
      const currentData = list.find(e => new Date(e.dt_txt).getHours() === nearestHr);
      const dayOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
      const dayOfWeekFull = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
      ];
      
      const currentDay = dayOfWeek[new Date(currentData.dt_txt).getDay()];
      const currentDayFull =
        dayOfWeekFull[new Date(currentData.dt_txt).getDay()];
      const currentTemp = Math.round(currentData.main.temp);
      const currentMinTemp = Math.round(currentData.main.temp_min);
      const currentMaxTemp = Math.round(currentData.main.temp_max);
      const currentWeather =
        currentData.weather[0].main === "Clouds"
          ? "Cloudy"
          : currentData.weather[0].main;
      const currentIcon = convertWeatherIcons(currentData.weather[0].main);
      const days = [];
      const daysFull = [];
      const temps = [];
      const minTemps = [];
      const maxTemps = [];
      const weather = [];
      const icons = [];
      for (let i = 0; i < datanew.list.length; i = i + 8) {
        let date = new Date(datanew.list[i].dt_txt);
        let day = dayOfWeek[date.getDay()];
        let dayFull = dayOfWeekFull[date.getDay()];
        days.push(day);
        daysFull.push(dayFull);
        temps.push(Math.round(datanew.list[i].main.temp));
        minTemps.push(Math.round(datanew.list[i].main.temp_min));
        maxTemps.push(Math.round(datanew.list[i].main.temp_max));

        if (datanew.list[i].weather[0].main === "Clouds") {
          weather.push("Cloudy");
        } else {
          weather.push(datanew.list[i].weather[0].main);
        }

        icons.push(
          convertWeatherIcons(datanew.list[i].weather[0].main)
        );
      }
      
      setDays([currentDay, ...days.slice(1)]);
      setDaysFull([currentDayFull, ...daysFull.slice(1)]);
      setTemps([currentTemp, ...temps.slice(1)]);
      setMinTemps([currentMinTemp, ...minTemps.slice(1)]);
      setMaxTemps([currentMaxTemp, ...maxTemps.slice(1)]);
      setWeather([currentWeather, ...weather.slice(1)]);
      setIcons([currentIcon, ...icons.slice(1)]);
    }
  
    const computeNearestHr = () => {
      const currentTimeInHrs = new Date().getHours();
      const constHrs = [0, 3, 6, 9, 12, 15, 18, 21];
      const differences = constHrs.map(e => Math.abs(e - currentTimeInHrs));
      const indexofLowestDiff = differences.indexOf(Math.min(...differences));
  
      return constHrs[indexofLowestDiff];
    };

    fetchData();

  }, [location]);

    let background = "";
    switch (weather[displayIndex]) {
      case "Clear":
        background = "clear";
        break;
      case "Cloudy":
        background = "cloudy";
        break;
      case "Snow":
        background = "snow";
        break;
      case "Rain":
        background = "rain";
        break;
      case "Drizzle":
        background = "drizzle";
        break;
      case "Thunderstorm":
        background = "thunderstorm";
        break;
      default:
        background = "cloudy";
    }

    return (
      <div className={"browser-home-widget-meteo widget ".concat(...background)}>
        <form onSubmit={changeLocation}>
   <div className="inline-input">
            <i className="mdi mdi-magnify"></i>
            <input
              className="location-input"
              defaultValue={location}
              type="text"
              onFocus={handleFocus}
              ref={locationInput}
            />
          </div>
        </form>

        <div className="main-display">
          <div className="main-info">
            <div className="temp-measurement">{temps[displayIndex]}</div>
            <div className="temp-unit">°C</div>
          </div>

          <div className="sub-info">
            <div className="sub-info-title">{daysFull[displayIndex]}</div>

            <div className="sub-info-text">{weather_fr[weather[displayIndex]]}</div>

            <div className="sub-info-text">
              <span className="max-temp">
                <i className="mdi mdi-arrow-up" />
                {maxTemps[displayIndex]}
                °C
              </span>
              <span className="min-temp">
                <i className="mdi mdi-arrow-down" />
                {minTemps[displayIndex]}
                °C
              </span>
            </div>
          </div>
        </div>

        <div className="selection-panel">
          <div className="selection-row">
            {icons.map((item, index) => {
              if (displayIndex === index) {
                return (
                  <div
                    className="selection-icons selected"
                    key={index + 1}
                    onClick={() => setIndex(index)}
                  >
                    <i className={"mdi mdi-".concat(item)} />
                  </div>
                );
              } else {
                return (
                  <div
                    className="selection-icons"
                    key={index + 1}
                    onClick={() => setIndex(index)}
                  >
                    <i className={"mdi mdi-".concat(item)} />
                  </div>
                );
              }
            })}
          </div>
          <div className="selection-row">
            {days.map((item, index) => {
              if (displayIndex === index) {
                return (
                  <div
                    className="selection-days selected"
                    key={index + 1}
                    onClick={() => setIndex(index)}
                  >
                    {item}
                  </div>
                );
              } else {
                return (
                  <div
                    className="selection-days"
                    key={index + 1}
                    onClick={() => setIndex(index)}
                  >
                    {item}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }

export default Weather;