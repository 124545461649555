import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";

const ValidationModal = (props) => (
    <Modal centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                {props.bodyContent}
            </Modal.Body>
        <Modal.Footer>
                <Button 
                    className="m-1"
                    onClick={props.handleSubmit}
                    variant="icon"
                    disabled={props.isLoading}
                >
                    {props.isLoading ? 'Suppression en cours...' : <FaCheck />}
                </Button>
                <Button 
                    variant="icon"
                    onClick={props.handleClose}
                >
                    <FaTimes />
                </Button>
            {props.msgContent}
        </Modal.Footer>
    </Modal>
);

export default ValidationModal;