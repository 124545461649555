import React from "react";
import { useEffect, useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
const GETCONTACTSBYID_URL = '/contacts/getContactbyid'

const ContactRapport = ({idContact}) => {

  //SESSION STATES
  const { auth } = useAuth();
  const accessToken = auth?.accessToken;
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  
  useEffect(() => {
    const handleGetContactbyNameId = async () => {
      await axios.post(GETCONTACTSBYID_URL,
          JSON.stringify(
              {accessToken, "id" : String(idContact)}   
          ), 
          {
              headers: {"Content-Type":"application/json"},
              withCredentials: true
          }
      )
      .then(function (response) {
        setNom(response.data["contact"].nom);
        setPrenom(response.data["contact"].prenom);
      })
      .catch(function (error) {
          console.log(error);
      });
    }
    //FETCH
    handleGetContactbyNameId()
    // eslint-disable-next-line
  }, [accessToken])

  return(
    <>
      {nom} {prenom}
    </>

  );
}

export default ContactRapport;