import React from "react";
import { Button, ListGroup } from "react-bootstrap";

const CreateEmpty = ({name, namebtn, showModal}) => {
  
  return(
    <ListGroup.Item className="create_empty">
        <div>
          Vous n'avez pas encore de {name}
        </div>
        <div>
                <Button variant="icon" className="m-1"  onClick={() => showModal(true)}>
                  {namebtn}
                </Button>
        </div>
    </ListGroup.Item>
  );
}

export default CreateEmpty;