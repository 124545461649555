import { useState, useRef, useEffect } from "react";
import { Stack, Form, Button, Figure, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from "../../api/axios.jsx";

import logolucky from '../../assets/lucky-logo-white.svg';

//API
const RESET_URL = '/auth/resetpasswd';

const MotdepasseOublie = () => {
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setsuccMsg] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const emailRef = useRef();

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const handledErr = [400, 403, 404];

        try {
            const response = await axios.post(RESET_URL,
                JSON.stringify({email}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            setsuccMsg(response?.data["message"])
            setEmail("");           
            setIsLoading(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg("Pas de réponses...");
                setIsLoading(false);
            } else if (handledErr.indexOf(err?.response?.status) !== -1) {
                setErrMsg(err?.response?.data["message"]);
                setIsLoading(false);
            } else {
                setErrMsg("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <Stack gap={2} className="form-signin">
                <Figure className="mx-auto">
                    <Link to="/">
                        <Figure.Image
                            width={100}
                            alt="Lucky CRM"
                            src={logolucky}
                        />
                    </Link>
                </Figure>
                <p style={{textAlign: "center", color: "#fff"}}>Vous avez oublié votre mot de passe ?</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control 
                            type="email"
                            placeholder="Votre email"
                            ref={emailRef}
                            autoComplete="off"
                            onChange={e => setEmail(e.target.value)} 
                            value={email}
                            required
                        />
                    </Form.Group>
                    <Button
                        className="w-100"
                        type="submit"
                        variant="sub"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Recherche en cours...' : 'Demander la réinitialisation'}
                    </Button>
                    
                </Form>
                <Alert 
                    key={"danger"} 
                    variant={"danger"} 
                    style={{margin: "0", display: "flex"}}
                    className={errMsg !== "" ? "" : "offscreen"}>

                    <p className={"errMsg"}>{errMsg}</p>
                </Alert>
                <Alert 
                    key={"success"} 
                    variant={"success"} 
                    style={{margin: "0", display: "flex"}}
                    className={succMsg !== "" ? "" : "offscreen"}>

                    <p className={"succMsg"}>{succMsg}</p>
                </Alert>
            </Stack>
        </>
    )
}

export default MotdepasseOublie;