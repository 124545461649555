import React, { useEffect, useState, useRef } from "react"
import { isMobile } from "react-device-detect";
import { ListGroup, Button, Dropdown, Alert, Card, Spinner} from "react-bootstrap";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import BrowserSidenav from '../components/BrowserSidenav';
import NavMobile from '../components/NavMobile';
import ContactFormAddModal from "../components/ContactFormAddModal";
import ValidationModal from "../components/ValidationModal";
import CreateEmpty from "../components/CreateEmpty";

import Tesseract from 'tesseract.js';


const GETCONTACTS_URL  = '/contacts'
const ADDCONTACT_URL   = '/contacts/addContact'
const DELCONTACT_URL   = '/contacts/deleteContact'
const GETLISTES_URL    = '/listes'
const CONTACTLISTE_URL = '/listes/getContacts'



const Contacts = () => {
	//SESSION STATES
	const { auth } = useAuth();
	const role = auth?.role;
	const accessToken = auth?.accessToken;

	//TOOLS
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingList, setIsLoadingList] = useState(false);
	const [isLoadingListes, setIsLoadingListes] = useState(false);
	const [needListReload, setNeedListReload] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [addErrMsg, setAddErrMsg] = useState("");
	const [delErrMsg, setDelErrMsg] = useState("");
	const [filtre, setFiltre] = useState("");
	const [tri, setTri] = useState("");
	const [search, setSearch] = useState("");

	//REFS
	const focusRef = useRef("");

	//STORAGE
	const [contacts, setContacts] = useState([]);
	const [allContacts, setAllContacts] = useState([]);
	const [contactsDump, setContactsDump] = useState([]);

	//CONTACT STATES
	const [prenom, setPrenom] = useState("");
	const changePrenom = (value) => {setPrenom(value); console.log(prenom);}

	const [nom, setNom] = useState("");
	const changeNom = (value) => setNom(value);

	const [email, setEmail] = useState("");
	const changeEmail = (value) => setEmail(value);

	const [fonction, setFonction] = useState("");
	const changeFonction = (value) => setFonction(value);

	const [telPortable, setTelPortable] = useState("");
	const changeTelPortable = (value) => setTelPortable(value);

	const [telDirect, setTelDirect] = useState("");
	const changeTelDirect = (value) => {console.log(value); setTelDirect(value); console.log(telDirect);}

	const [linkedin, setLinkedin] = useState("");
	const changeLinkedin = (value) => setLinkedin(value);

	const [raisonSociale, setRaisonSociale] = useState("");
	const changeRaisonSociale = (value) => setRaisonSociale(value);

	const [statutJuridique, setStatutJuridique] = useState("");
	const changeStatutJuridique = (value) => setStatutJuridique(value);

	const [telStandard, setTelStandard] = useState("");
	const changetelStandard = (value) => setTelStandard(value);

	const [adresse, setAdresse] = useState("");
	const changeAdresse = (value) => setAdresse(value);

	const [commentaire, setCommentaire] = useState("");
	const changeCommentaire = (value) => setCommentaire(value);

	const [showCamera, setShowCamera] = useState(false);

	const [afficherPhotoPrise, setAfficherPhotoPrise] = useState(false);

	const [item, setItem] = useState({ isClient: "" });
	const { isClient } = item;
	const changeIsClient = e => {
		e.persist();

		setItem(prevState => ({
			...prevState,
			isClient: e.target.value
		}));
	};

	const [listes, setListes] = useState([]);
	const [selectedListes, setSelectedListes] = useState(0);


	const [afficherImage, setAfficherImage] = useState(false);
	const changeAfficherImage = (value) => setAfficherImage(value);


	const [fileRectoOk, setFileRectoOk] = useState(false);
	const [fileVersoOk, setFileVersoOk] = useState(false);


	const [lectureCarteFinie, setLectureCarteFinie] = useState(false)



	const [fileRecto, setFileRecto] = useState("");
	const changeFileRecto = (value) => {
		setFileRecto(value);

		if (value !== "" || value != null)
			setFileRectoOk(true);
	}

	const [fileVerso, setFileVerso] = useState("");
	const changeFileVerso = (value) => {
		setFileVerso(value);

		if (value !== "" || value != null)
			setFileVersoOk(true);
	}



	const scannerCartes = async () => {
		let value = ["", "", "", "", "", ""];

		if ( fileRecto !== "" && fileRectoOk ) value = await lireCarte(fileRecto, value);
		if ( fileVerso !== "" && fileVersoOk ) await lireCarte(fileVerso, value);


		setLectureCarteFinie(true);
		setTimeout(() => {
			setLectureCarteFinie(false);
		}, 5000);
	}



	// OCR POUR LIRE LA CARTE DE VISITE, RENVOIE UN TABLEAU DE VALEURS
	const lireCarte = async (value, tab) => {
		const file = value;
		const reader = new FileReader();


		const data = await new Promise((resolve, reject) => {
			reader.onload = (e) => {
				const img = new Image();
				img.src = e.target.result;

				img.onload = () => {
					Tesseract.recognize(img, 'eng', { logger: m => console.log(m) })
						.then(({ data }) => {
							if (data && data.text)
								return resolve(data.text);
							else
								console.error("No text data extracted");
						}) .catch(error => {
							console.error("Error during OCR:", error);
						});
				};
			};

			reader.readAsDataURL(file);
		});


		console.log(data);

		return majInfosCarte(data, tab);
	}



	//GET LISTES
	useEffect(() => {
		setIsLoadingListes(true);
		const fetchLists = async () => {
			const handledErr = [401];
			try {
				const response = await axios.post(GETLISTES_URL,
					JSON.stringify({accessToken}),
					{
						headers: {"Content-Type":"application/json"},
						withCredentials: true
					}
				);
				setListes(response.data["listes"]);
				setIsLoadingListes(false);
			} catch (err) {
				if (!err?.response) {
					setErrMsg("Pas de réponse");
				} else if (handledErr.indexOf(err.response?.status) !== -1) {
					setErrMsg(err.response?.data["message"]);
				} else {
					setErrMsg("Problème interne lié au serveur !");
				}
			}
		}
		//FETCH
		fetchLists()
	}, [accessToken])

	//GET CONTACTS
	useEffect(() => {
		setNeedListReload(false);
		setIsLoadingList(true);
		const fetchContactsList = async () => {
			const handledErr = [401];
			var urlAxios = {};
			var jsonIfy = {};
			if(selectedListes === 0){
				urlAxios = GETCONTACTS_URL
				jsonIfy = JSON.stringify({accessToken})
			}else{
				urlAxios = CONTACTLISTE_URL
				jsonIfy = JSON.stringify({accessToken, "id": String(selectedListes)})
			}

			try {
				const response = await axios.post(urlAxios,
					jsonIfy,
					{
						headers: {"Content-Type":"application/json"},
						withCredentials: true
					}
				);
				setIsLoadingList(false);
				setContacts(response.data["contacts"]);
				setAllContacts(response.data["contacts"]);
				setContactsDump(response.data["contacts"]);
			} catch (err) {
				if (!err?.response) {
					setErrMsg("Pas de réponse");
				} else if (handledErr.indexOf(err.response?.status) !== -1) {
					setErrMsg(err.response?.data["message"]);
				} else {
					setErrMsg("Problème interne lié au serveur !");
				}
			}
		}
		//FETCH
		fetchContactsList()
	}, [accessToken, selectedListes, needListReload])

	//FILTER CONTACTS LIST
	useEffect(() => {
		if (filtre === "client") {
			const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== false);
			setContacts(filteredContacts);
			setAllContacts(filteredContacts);
			return
		}
		if (filtre === "prospect") {
			const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== true);
			setContacts(filteredContacts);
			setAllContacts(filteredContacts);
			return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtre])

	//SORT CONTACTS LIST
	useEffect(() => {
		if (tri === "defaut") {
			if (filtre === "client") {
				const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== false);
				setContacts(filteredContacts);
				setAllContacts(filteredContacts);
				return
			}
			if (filtre === "prospect") {
				const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== true);
				setContacts(filteredContacts);
				setAllContacts(filteredContacts);
				return
			}
			setContacts(contactsDump);
			setAllContacts(contactsDump);
			return
		}

		if (tri === "raisonSociale") {
			const sortedContacts = [...contacts].sort((a, b) => a?.raison_sociale?.localeCompare(b?.raison_sociale));
			setContacts(sortedContacts);
			return
		}
		if (tri === "personne") {
			const sortedContacts = [...contacts].sort((a, b) => a?.nom?.localeCompare(b?.nom));
			setContacts(sortedContacts);
			return
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tri])

	//SEARCH IN CONTACTS LIST
	useEffect(() => {
		const searchedContacts = [];
		if (search === "") {
			if (filtre === "client") {
				const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== false);
				setContacts(filteredContacts);
				setAllContacts(filteredContacts);
				setTri("");
				return
			}
			if (filtre === "prospect") {
				const filteredContacts = [...contactsDump].filter((contact) => contact.is_client !== true);
				setContacts(filteredContacts);
				setAllContacts(filteredContacts);
				setTri("");
				return
			}
			setContacts(contactsDump);
			setAllContacts(contactsDump);
			return
		}
		allContacts.forEach(contact => {
			const exists_rs = contact.raison_sociale?.toLowerCase().includes(search.toLowerCase());
			const exists_name = contact.nom?.toLowerCase().includes(search.toLowerCase());
			const exists_firstname = contact.prenom?.toLowerCase().includes(search.toLowerCase());
			if(exists_rs || exists_name || exists_firstname) searchedContacts.push(contact);
		});
		setContacts(searchedContacts);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	//ADD CONTACT FORM MODAL
	const [showAddContact, setShowAddContact] = useState(false);
	const handleShowAddContact = async () => {
		setValidated(false);
		await setShowAddContact(true);
		focusRef.current.focus()
	}
	const handleCloseAddContact = () => {
		setShowAddContact(false);
		setPrenom("");
		setNom("");
		setEmail("");
		setFonction("");
		setTelPortable("");
		setTelDirect("");
		setLinkedin("");
		setRaisonSociale("");
		setStatutJuridique("");
		setTelStandard("");
		setAdresse("");
		setCommentaire("");
		setItem({ isClient : "" });
		setShowCamera(false);

		setFileRecto("");
		setFileVerso("");

		setAfficherPhotoPrise(false);

		setAfficherImage(false);

		setFileRectoOk(false);
		setFileVersoOk(false);

		setLectureCarteFinie(false);
	}

	const [validated, setValidated] = useState(false);

	const handleAddContactSubmit = async (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		}else{

			const handledErr = [401, 409, 204, 206];
			// if (validNom === true && validPrenom === true && validPwd === true && matchPwd === true) {
				setIsLoading(true);
				try {
					const response = await axios.post(ADDCONTACT_URL,
						JSON.stringify(
							{accessToken, email, prenom, nom, fonction, tel_portable : telPortable, tel_direct : telDirect, linkedin,
							raison_sociale : raisonSociale, statut_juridique : statutJuridique, tel_standard : telStandard, adresse,
							commentaire, is_client : isClient}
						),
						{
							headers: {"Content-Type":"application/json"},
							withCredentials: true
						}
					)

					setAddErrMsg(response?.data["message"]);
					setTimeout(function () {
						setIsLoading(false);
						setNeedListReload(true);
						handleCloseAddContact();
					}, 2000);
				} catch (err) {
					if (!err?.response) {
						setAddErrMsg("Pas de réponse");
						setIsLoading(false);
					} else if (handledErr.indexOf(err.response?.status) !== -1) {
						setAddErrMsg(err.response?.data["message"]);
						setIsLoading(false);
					} else {
						setAddErrMsg("Problème interne lié au serveur !");
						setIsLoading(false);
					}
				}
			// } else {
			//     setAddMsg("Au moins une valeur non valide");
			// }
		}
		setValidated(true);
	}

	//DELETE CONTACT
	const [showDeleteContact, setShowDeleteContact] = useState(false);
	const handleShowDeleteContact = contact => {
		setEmail(contact.email);
		setShowDeleteContact(true);
	}

	const handleCloseDeleteContact = () => {
		setEmail("");
		setShowDeleteContact(false);
	}

	const handleDeleteContactSubmit = async (e) => {
		e.preventDefault();
		const handledErr = [401, 409, 204, 206];
		setIsLoading(true);
		try {
			const response = await axios.post(DELCONTACT_URL,
				JSON.stringify({accessToken, "email" : email}),
				{
					headers: {"Content-Type":"application/json"},
					withCredentials: true
				}
			);

			setDelErrMsg(response?.data["message"]);
			setTimeout(function () {
				setIsLoading(false);
				setNeedListReload(true);
				handleCloseDeleteContact();
			}, 2000);
		} catch (err) {
			if (!err?.response) {
				setDelErrMsg("Pas de réponse");
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				setDelErrMsg(err.response?.data["message"]);
			} else {
				setDelErrMsg("Problème interne lié au serveur !");
			}
		}
	}



	//OCR
	const majInfosCarte = async (data, tab) => {
		let prenom     = tab[0];
		let nomFamille = tab[1];
		let mail       = tab[2];
		let tel        = tab[3];
		let adresse    = tab[4];
	
		const mots = data.toString().match(/\w+/g);


		if ( mots === null || mots === "" ) return [prenom, nomFamille, mail, tel, adresse];


		// Récupération des informations de manière asynchrone sans attendre les conditions suivantes
		if (mail === "") {
			mail = await getMail(data);
			changeEmail(mail);
		}


		if (tel === "") {
			tel = await getNumeroTelephone(data);
			if (tel !== null) changeTelDirect(tel[0]);
		}


		if (prenom === "")
			prenom = await getPrenom(mots);
			// setPrenom(data.toString());
			// prenom = "ok";


		if (prenom !== "" && nomFamille === "")
			nomFamille = await getNomFamille(mots, prenom);


		if (adresse === "")
			adresse = await getAdresse(data);


		return [prenom, nomFamille, mail, tel, adresse];
	};
	



	const getNumeroTelephone = (data) => {
		data = data.replaceAll(" ", "");
		data = data.replaceAll(".", "");
		data = data.replaceAll("?", "");
		data = data.replaceAll(",", "");
		data = data.replaceAll("-", "");
		data = data.replaceAll(";", "");
		data = data.replaceAll("+", "");
		data = data.replaceAll("(", "");
		data = data.replaceAll(")", "");

		return data.match(/(0|33|330)(1|2|3|4|5|6|7)[0-9]{8}/);
	};


	const getMail = (data) => {
		return data.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/);
	}



	const getNomFamille = async (mots, prenom) => {
		for ( let cpt = 0; cpt < mots.length; cpt++ ) {
			// On vérifie si le mot est un nom de famille
			if (mots[cpt].length >= 3 && mots[cpt].match(/^[a-zA-Z]+$/) !== null) {

				try {
					let data = await axios.get('/nomFamille/' + mots[cpt].toUpperCase())


					if ( mots[cpt].toLowerCase() !== prenom.toLowerCase() && parseInt(data.data.count, 10) >= 1 ) {
						changeNom(mots[cpt]);
						return mots[cpt];
					}
				} catch (error) {
					console.error('Error sending data:', error);
				}
			}
		}
	};


	const getPrenom = async (mots) => {
		for ( let cpt = 0; cpt < mots.length; cpt++ ) {
			// On vérifie si le mot est un prénom
			if (mots[cpt].length >= 3 && mots[cpt].match(/^[a-zA-Z]+$/) !== null) {

				try {
					let data = await axios.get('/prenom/' + mots[cpt].toUpperCase())

					if ( parseInt(data.data.count, 10) >= 1 ) {
						changePrenom(mots[cpt]);
						return mots[cpt];
					}
				} catch (error) {
					console.error('Error sending data:', error);
				}
			}
		}
	};



	const eAdrLike = async (mots) => {
		let data = (await axios.get('/adresseLike/' + mots.toUpperCase())).data.count;

		try {
			return parseInt (data, 10) >= 1;
		} catch (error) {
			console.error('Error sending data:', error);
		}
	}



	const eAdrExact = async (mots) => {
		let data = (await axios.get('/adresse/' + mots.toUpperCase())).data.count;

		try {
			return parseInt (data, 10) >= 1;
		} catch (error) {
			console.error('Error sending data:', error);
		}
	}


	const eCommLike = async (mots) => {
		try {
			return parseInt ( (await axios.get('/communeLike/' + mots.toUpperCase())).data.count, 10 ) >= 1;
		} catch (error) {
			console.error('Error sending data:', error);
		}
	}



	const eCommExact = async (mots) => {
		try {
			return parseInt ( (await axios.get('/commune/' + mots.toUpperCase())).data.count, 10 ) >= 1;
		} catch (error) {
			console.error('Error sending data:', error);
		}
	}



	const getAdresse = async (data) => {
		const mots = data.toString().match(/[a-zA-Z0-9-]+/g);


		var adresse = "";
		var tabAdresses = [];

		let tmp;


		for (let cpt = 0; cpt < mots.length; cpt++)
		{
			let res = "";
			tmp = cpt;


			if (mots[cpt].match(/^[a-zA-Z]+$/) === null) continue;

			for (let cpt1 = cpt; cpt1 < mots.length; cpt1++)
			{
				res += mots[cpt1] + " ";
				if (mots[cpt1].match(/^[a-zA-Z]+$/) === null) break;

				if ( !await eAdrLike(res.toUpperCase()) ) break;
				if ( await eAdrExact(res.toUpperCase()) )
				{
					adresse = (mots[cpt-1] + " " + res).trim();

					tmp = cpt1;
				}
			}

			
			if ( adresse !== "" && !tabAdresses.includes(adresse) ) tabAdresses.push( adresse );

			cpt = tmp;
		}


		return await getVille(tabAdresses, data);
	}




	const getVille = async ( adresses, data ) => {

		data = data.replaceAll(",", "");

		for ( let cpt = 0; cpt < adresses.length; cpt ++ ) data = data.replaceAll(adresses[cpt], "");


		let mots = data.toString().match(/[a-zA-Z0-9-]+/g);


		var commune = "";
		var tabCommunes = [];


		let tmp;


		for (let cpt = 0; cpt < mots.length; cpt++)
		{
			let res = "";
			tmp = cpt;


			if (mots[cpt].match(/^[a-zA-Z]+$/) === null) continue;

			for (let cpt1 = cpt; cpt1 < mots.length; cpt1++)
			{
				res += mots[cpt1] + " ";
				if (mots[cpt1].match(/^[a-zA-Z]+$/) === null) break;

				if ( !await eCommLike(res.toUpperCase()) ) break;
				if ( await eCommExact(res.toUpperCase()) )
				{
					commune = res;

					tmp = cpt1;
				}
			}


			if ( commune !== "" && !tabCommunes.includes(commune) ) tabCommunes.push( commune );


			cpt = tmp;
		}


		if ( adresses.length >= tabCommunes.length )
			for ( let cpt = 0; cpt < tabCommunes.length; cpt ++ )
				adresses[cpt] += " - " + tabCommunes[cpt].trim();
		else
			for ( let cpt = 0; cpt < adresses.length; cpt ++ )
				adresses[cpt] += " - " + tabCommunes[cpt].trim();


		for ( let cpt = 0; cpt < adresses.length; cpt ++ )
			if ( adresses[cpt].match(/^[0-9]+/) !== null )
			{
				changeAdresse(adresses[cpt]);

				return adresses[cpt];
			}

		return "";
	}





	//HOVER BUTTON
	const handleMouseOver = async (e) => {
		e.currentTarget.className = "contacts-list-item list-group-item hover";
	}

	const handleMouseOut = async (e) => {
		e.currentTarget.className = "contacts-list-item list-group-item";
	}
	return (
		<>
			{!isMobile ? <BrowserSidenav /> : <NavMobile handleLink={handleShowAddContact} handleData="Ajouter un contact" name="Contact" handleList={true} />}
			<div className={!isMobile ? "browser-content page-clients" : "mobile-content page-clients"}>
				<Card>
					{!isMobile ? <Card.Header className="contacts-list-header">
						<h2>Contacts</h2>
						{
						<Link to={"/listes"}><Button data-tip="Gérer les listes" variant="list">
							<svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
								<g id="Groupe_106" data-name="Groupe 106" transform="translate(-293 -17)">
									<circle id="Ellipse_7" data-name="Ellipse 7" cx="1.5" cy="1.5" r="1.5" transform="translate(293 17)" fill="#f8f8f8"/>
									<circle id="Ellipse_8" data-name="Ellipse 8" cx="1.5" cy="1.5" r="1.5" transform="translate(293 24)" fill="#f8f8f8"/>
									<circle id="Ellipse_9" data-name="Ellipse 9" cx="1.5" cy="1.5" r="1.5" transform="translate(293 31)" fill="#f8f8f8"/>
									<rect id="Rectangle_59" data-name="Rectangle 59" width="18" height="3" rx="1.5" transform="translate(298 31)" fill="#f8f8f8"/>
									<rect id="Rectangle_60" data-name="Rectangle 60" width="18" height="3" rx="1.5" transform="translate(298 24)" fill="#f8f8f8"/>
									<rect id="Rectangle_61" data-name="Rectangle 61" width="18" height="3" rx="1.5" transform="translate(298 17)" fill="#f8f8f8"/>
								</g>
							</svg>
						</Button></Link>
						}
						<Button data-tip="Ajouter un contact" variant="plus" onClick={handleShowAddContact}>
							<svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
								<path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
							</svg>
						</Button>
					</Card.Header>
					: "" }
					<Card.Body className={!isMobile ? "search-container" : "search-container ismobile"}>
						<div className="sub-container">
							<FaSearch
								color="#3E5B5F"
								style={{height: "1.5em", width: "1.5em", marginTop: "4px"}}
							/>
							<input className={"search-input"}
								type={"text"}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								placeholder={"Société, Nom, Prénom ..."}
								autoComplete={"off"}
							/>
						</div>
						<div className="sub-container">
							<Dropdown>
								<Dropdown.Toggle variant="" id="dropdown-basic">
									Trier
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={e => {setTri("defaut")}}>Défaut</Dropdown.Item>
									<Dropdown.Item onClick={e => {setTri("raisonSociale")}}>Raison Sociale</Dropdown.Item>
									<Dropdown.Item onClick={e => {setTri("personne")}}>Personne</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown>
								<Dropdown.Toggle variant="" id="dropdown-basic">
									Filtrer
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={e => {setFiltre("", setContacts(contactsDump), setAllContacts(contactsDump))}}>Défaut</Dropdown.Item>
									<Dropdown.Item onClick={e => {setFiltre("client")}}>Client</Dropdown.Item>
									<Dropdown.Item onClick={e => {setFiltre("prospect")}}>Prospect</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</Card.Body>
				</Card>

				{isLoadingListes
					?
					<div style={{
						marginTop: "50px",
						textAlign: "center",
						width: "100%"
					}}>
						<Spinner animation="border" size="sm"/> Chargement des listes
					</div>
					: !listes.length ? ""
						:
						<div className="listes-filtres">
							<Button variant="list" onClick={() => setSelectedListes(0)} className={selectedListes === 0 ? "active" : ""} >Mes contacts</Button>
							{listes.map(list => (
								<Button key={list.id} variant="list" value={list.id} onClick={() => setSelectedListes(list.id)} className={selectedListes === list.id ? "active" : ""} >{list.nom}</Button>
							))}
						</div>

				}

				{isLoadingList
					?
					<div style={{
					marginTop: "50px",
						textAlign: "center",
						width: "100%"
					}}>
						<Spinner animation="border" size="sm"/> Chargement des contacts
					</div>
					: <ListGroup variant="flush" className="contacts-list">
						{!contacts.length
							? !search ? <CreateEmpty name="Contact" namebtn="Créer un contact" showModal={setShowAddContact} /> : "Aucun contact ne correspond à votre recherche"
							: contacts.map(contact => (
						<div key={contact.email} className="contacts-list-item-container">
							<ListGroup.Item className="contacts-list-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
								<Link to={"/contacts/"+contact.email} style={{ color: 'inherit', textDecoration: 'inherit'}} className="contacts-list-item-content">
									<div className="header-contact">
										<strong className={contact.is_client ? "client-color" : "prospect-color"}>{contact.raison_sociale}</strong>
									</div>
									<div className="body-contact">
										<div>
											<strong>{contact.prenom} {contact.nom}</strong><br/>
											{contact.fonction ? contact.fonction : ""}
										</div>
										<div>
											{contact.email}
										</div>
										<div>
											{contact.tel_portable ? `Portable : ${contact.tel_portable}` : ""}<br/>
											{contact.tel_direct ? `Direct : ${contact.tel_direct}` : ""}
										</div>
									</div>
								</Link>
								<div className="btn-container">
										<Button disabled={role === "Manager" ? false : true} variant="sub" className="m-1" value={contact.email} onClick={e => handleShowDeleteContact(contact)}>
											<FaTrashAlt />
										</Button>
								</div>
							</ListGroup.Item>
						</div>
						))}
					</ListGroup>
				}

				<Alert
					key={"warning"}
					variant={"warning"}
					className={errMsg !== "" ? "" : "offscreen"}
					style={{position: "absolute", bottom: "20px", margin: "0"}}>
					<p className={"errMsg"} aria-live="assertive">{errMsg}</p>
				</Alert>

				<ContactFormAddModal
					show={showAddContact}
					handleClose={handleCloseAddContact}
					handleSubmit={handleAddContactSubmit}

					// Ajouter la fonction scannercartes
					scannerCartes={scannerCartes}

					headerContent={"Ajouter un contact"}
					btnLabel={["Ajout en cours ...", "Ajouter"]}

					changePrenom={changePrenom}
					prenom = {prenom}

					changeNom={changeNom}
					nom = {nom}

					changeEmail={changeEmail}
					email = {email}

					changeFonction={changeFonction}

					changeTelPortable={changeTelPortable}
					telPortable = {telPortable}

					changeTelDirect={changeTelDirect}
					telDirect = {telDirect}

					changeLinkedin={changeLinkedin}
					changeRaisonSociale={changeRaisonSociale}
					changeStatutJuridique={changeStatutJuridique}
					changeTelStandard={changetelStandard}

					changeAdresse={changeAdresse}
					adresse = {adresse}

					changeAfficherImage={changeAfficherImage}
					afficherImage = {afficherImage}

					changeCommentaire={changeCommentaire}
					changeIsClient={changeIsClient}
					isClient={isClient}

					changeFileRecto={changeFileRecto}
					changeFileVerso={changeFileVerso}

					setShowCamera={setShowCamera}
					showCamera={showCamera}

					setAfficherPhotoPrise={setAfficherPhotoPrise}
					afficherPhotoPrise={afficherPhotoPrise}

					fileRectoOk={fileRectoOk}
					fileVersoOk={fileVersoOk}

					lectureCarteFinie={lectureCarteFinie}

					validated={validated}

					focusRef={focusRef}
					isLoading={isLoading}
					errMsg={
						<Alert
							key={"warning"}
							variant={"warning"}
							className={addErrMsg !== "" ? "" : "offscreen"}
							style={{position: "absolute", bottom: "20px", margin: "0"}}>
							<p className={"errMsg"} aria-live="assertive">{addErrMsg}</p>
						</Alert>
					}
				/>

				<ValidationModal
					show={showDeleteContact}
					handleClose={handleCloseDeleteContact}
					headerContent={"Suppression"}
					bodyContent={"Etes-vous sûr de vouloir supprimer le compte : " + email}
					handleSubmit={handleDeleteContactSubmit}
					isLoading={isLoading}
					errMsg={
						<Alert
							key={"warning"}
							variant={"warning"}
							className={delErrMsg !== "" ? "" : "offscreen"}
							style={{position: "absolute", bottom: "20px", margin: "0"}}>
							<p className={"errMsg"} aria-live="assertive">{delErrMsg}</p>
						</Alert>
					}
				/>
			</div>
		</>
	)
}

export default Contacts;