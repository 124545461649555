import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get("/auth/refresh", {
            withCredentials: true
        });
        setAuth(prev => {
            return {
                ...prev, 
                role: response?.data.role,
                accessToken: response?.data.accessToken,
                emailP: response?.data.email,
                prenomP: response?.data.prenom,
                nomP: response?.data.nom,
                affiliationP: response?.data.affiliation,
                companyP: response?.data.company,
            }
        })
        return response?.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;