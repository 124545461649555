import React from "react";

import { isMobile } from "react-device-detect";

const Taches = (props) => {
    if (isMobile) {
        return (
            <>
                <h1>Ma liste de tâches</h1>
            </>
        )
    } else {
        return (
            <>
                {/* IMPLEMENTATION POSSIBLE SUR PC */}
            </>
        )
    }
}

export default Taches;