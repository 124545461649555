import React, { useEffect, useState, useRef } from "react"
import { isMobile } from "react-device-detect";
import { Accordion, Button, useAccordionButton, Alert, Card, Spinner, Form, Row, Col} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import BrowserSidenav from '../components/BrowserSidenav';
import NavMobile from '../components/NavMobile';
import ListFormAddModal from "../components/ListFormAddModal";
import ValidationModal from "../components/ValidationModal";
import CreateEmpty from "../components/CreateEmpty";

const GETLISTES_URL = '/listes'
const ADDLISTE_URL = '/listes/addListe'
const UPDLISTE_URL = '/listes/increListe'
const DELLISTE_URL = '/listes/deleteListe'
const CONTACTLISTE_URL = '/listes/getContacts'
const USERLISTE_URL = '/listes/getUsers'
const GETUSER_URL = '/users';
const GETCONTACTS_URL = '/contacts';

const Listes = () => {
    //SESSION STATES
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;
    const role = auth?.role;
    const emailP = auth?.emailP;

    //TOOLS
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [needListReload, setNeedListReload] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [addErrMsg, setAddErrMsg] = useState("");
    const [delErrMsg, setDelErrMsg] = useState("");

    //REFS
    const focusRef = useRef("");

    //LIST STATES
    const [nom, setNom] = useState("");
    const [id_list, setID] = useState("");
    const [nomDel, setNomDel] = useState("");
    const changeNom = (value) => setNom(value);

    //STORAGE
    const [listes, setListes] = useState([]);

    //GET LISTES
    useEffect(() => {
        setNeedListReload(false);
        setIsLoadingList(true);
        const fetchLists = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETLISTES_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setListes(response.data["listes"]);
                setIsLoadingList(false);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchLists()
    }, [accessToken, needListReload])

    //ADD LIST FORM MODAL
    const [showAddList, setShowAddList] = useState(false);
    const handleShowAddList = async () => {
        setValidated(false);
        await setShowAddList(true);
        focusRef.current.focus()
    }
    const handleCloseAddList = () => {
        setShowAddList(false);
        setNom("");
    }

    const [validated, setValidated] = useState(false);

    const handleAddListSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }else{

            const handledErr = [401, 409, 204, 206];
                setIsLoading(true);
                try {
                    const response = await axios.post(ADDLISTE_URL,
                        JSON.stringify(
                            {accessToken, "liste":{"nom": nom}}
                        ), 
                        {
                            headers: {"Content-Type":"application/json"},
                            withCredentials: true
                        }
                    )

                    setAddErrMsg(response?.data["message"]);
                    setTimeout(function () {
                        setIsLoading(false);
                        setNeedListReload(true);
                        handleCloseAddList();
                    }, 2000);
                } catch (err) {
                    if (!err?.response) {
                        setAddErrMsg("Pas de réponse");
                        setIsLoading(false);
                    } else if (handledErr.indexOf(err.response?.status) !== -1) {
                        setAddErrMsg(err.response?.data["message"]);
                        setIsLoading(false);
                    } else {
                        setAddErrMsg("Problème interne lié au serveur !");
                        setIsLoading(false);
                    }
                }
        }
        setValidated(true);
    }

    //DELETE LISTE
    const [showDeleteList, setShowDeleteList] = useState(false);
    const handleShowDeleteList = list => {
        setID(list.id);
        setNomDel(list.nom);
        setShowDeleteList(true);
    }
    
    const handleCloseDeleteList = () => {
        setID("");
        setNomDel("");
        setShowDeleteList(false);
    }

    const handleDeleteListSubmit = async (e) => {
        e.preventDefault();
        const handledErr = [401, 409, 204, 206];
        setIsLoading(true);
        try {
            const response = await axios.post(DELLISTE_URL,
                JSON.stringify({accessToken, "liste_id" : String(id_list)}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            
            setDelErrMsg(response?.data["message"]);
            setTimeout(function () {
                setIsLoading(false);
                setNeedListReload(true);
                handleCloseDeleteList();
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setDelErrMsg("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setDelErrMsg(err.response?.data["message"]);
            } else {
                setDelErrMsg("Problème interne lié au serveur !");
            }
        }
    }

    //CONTACTS ASSOC LIST
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const fetchContactsList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setContacts(response.data["contacts"]);

            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchContactsList()
    }, [accessToken])

    const [UserContacts, setUserContacts] = useState([]);
    const [ContactsWithoutUser, setContactsWithoutUser] = useState([]);
    const [needContactsListReload, setNeedContactsListReload] = useState(false);
    const [selectedList, setSelectedList] = useState(0);

    function getDifference(array1, array2) {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.email === object2.email;
            });
        });
    }

    useEffect(() => {
        setNeedContactsListReload(false);
        const loadContactbyUser = async () =>{
            const handledErr = [401];
            try {
                const response = await axios.post(CONTACTLISTE_URL,
                    JSON.stringify({accessToken, "id": String(selectedList)}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setUserContacts(response.data["contacts"]);
                const contactWU = getDifference(contacts,response.data["contacts"]);
                setContactsWithoutUser(contactWU);
    
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        if(selectedList !== 0){
            loadContactbyUser();
        }
    }, [accessToken, emailP, contacts, selectedList, needContactsListReload])

    const handleUpdContactList = async (type, value, action) => {
        const selectedType = [{"value": value}];
        var dataUpdt = {};
        type === "contacts" ? dataUpdt = JSON.stringify({accessToken, "assoc_type": action, "id_liste": selectedList, "contacts": selectedType}) : dataUpdt = JSON.stringify({accessToken, "assoc_type": action, "id_liste": selectedList, "utilisateurs": selectedType});
        const handledErr = [401];
        try {
            await axios.post(UPDLISTE_URL,
                dataUpdt, 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );
            setNeedContactsListReload(true);
            setNeedUsersListReload(true);
        } catch (err) {
            if (!err?.response) {
                console.log("Pas de réponse");
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                console.log(err.response?.data["message"]);
            } else {
                console.log("Problème interne lié au serveur !");
            }
        }
    }

    //USER ASSOC LIST
    const [users, setUsers] = useState([]);
    const [UsersListe, setUsersListe] = useState([]);
    const [needUsersListReload, setNeedUsersListReload] = useState(false);

    useEffect(() => {
        const fetchUsersList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETUSER_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setUsers(response.data["utilisateurs"]);

            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        //FETCH
        fetchUsersList()
    }, [accessToken])

    useEffect(() => {
        setNeedUsersListReload(false);
        const uINl = [""];
        const loadUsersbyListe = async () =>{
            const handledErr = [401];
            try {
                const response = await axios.post(USERLISTE_URL,
                    JSON.stringify({accessToken, "id": String(selectedList)}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                response.data["utilisateurs"].map(userindaliste => (
                    uINl.push(userindaliste.email)
                ))
                setUsersListe(uINl);
    
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        if(selectedList !== 0){
            loadUsersbyListe();
        }
    }, [accessToken, emailP, selectedList, needUsersListReload])


    //Accordeons Toggle
    function CustomToggle({ children, eventKey}) {
        const decoratedOnClick = useAccordionButton(eventKey, () => 
            setSelectedList(eventKey),
        );
        return (
          <button
            type="button"
            className="btn"
            style={{width: "100%"}}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
    }

    return (
        <>
            {!isMobile ? <BrowserSidenav /> : <NavMobile handleLink={handleShowAddList} handleData="Ajouter une liste" name="Listes de contacts" />}
            <div className={!isMobile ? "browser-content page-listes" : "mobile-content page-listes"}>
                {!isMobile ? <Card>
                    <Card.Header className="listes-list-header">
                        <h2>Listes de contacts</h2>
                        <Button data-tip="Ajouter une liste" variant="plus" onClick={handleShowAddList}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
                                <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
                            </svg>
                        </Button>
                    </Card.Header>
                </Card>
                : "" }
                {isLoadingList
                    ? 
                    <div style={{
                        marginTop: "50px",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        <Spinner animation="border" size="sm"/> Chargement
                    </div>
                    : <Accordion defaultActiveKey="" className="listes-list">
                        {!listes.length ? <CreateEmpty name="Liste" namebtn="Créer une liste" showModal={setShowAddList} /> : listes.map(list => (
                            <Card key={list.id} className="listes-list-item">
                                <Card.Header className="listes-head">
                                    <CustomToggle eventKey={list.id}><strong>{list.nom}</strong></CustomToggle>
                                    <Button variant="plus" value={list.id} onClick={e => handleShowDeleteList(list)}>
                                        <FaTrashAlt color="#fff" />
                                    </Button>
                                </Card.Header>
                                <Accordion.Collapse eventKey={list.id}>
                                    <Card.Body>
                                        <Form className="listesAssign">
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Label>
                                                        Tous les contacts
                                                    </Form.Label>
                                                    <ul className="addContact">
                                                    {selectedList ? ContactsWithoutUser.map(usercontactw => (
                                                        <li key={usercontactw.email} onClick={() => handleUpdContactList("contacts",usercontactw.email, "AddContacts")}>{usercontactw.prenom} {usercontactw.nom} - {usercontactw.raison_sociale}</li>
                                                    )) : ""}
                                                    </ul>
                                                </Col>

                                                <Col>
                                                    <Form.Label>
                                                        Contacts dans la liste
                                                    </Form.Label>
                                                    <ul className="delContact">
                                                    {UserContacts ? UserContacts.map(usercontact => (
                                                        <li key={usercontact.email} onClick={() => handleUpdContactList("contacts",usercontact.email, "DelContacts")}>{usercontact.prenom} {usercontact.nom} - {usercontact.raison_sociale}</li>
                                                    )) : ""}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Form>
                                        {role === "Manager" 
                                            ?
                                            <Form className="commerciauxAssign">
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Label>
                                                            Attribuer la liste à des commerciaux
                                                        </Form.Label>
                                                        <ul className="addUsers">
                                                        {selectedList ? users.map(userinlist => (
                                                            UsersListe.includes(userinlist.email) 
                                                            ? <li key={userinlist.email} className="inDaList" onClick={() => handleUpdContactList("utilisateurs",userinlist.email, "DelUtilisateur")}>{userinlist.prenom} {userinlist.nom}</li>
                                                            : <li key={userinlist.email} className="notInDaList" onClick={() => handleUpdContactList("utilisateurs",userinlist.email, "AddUtilisateur")}>{userinlist.prenom} {userinlist.nom}</li>
                                                        )) : ""}
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            : "" 
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                }

                <Alert 
                    key={"warning"} 
                    variant={"warning"} 
                    className={errMsg !== "" ? "" : "offscreen"}
                    style={{position: "absolute", bottom: "20px", margin: "0"}}>
                    <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
                </Alert>

                <ListFormAddModal 
                    show={showAddList}
                    handleClose={handleCloseAddList}
                    handleSubmit={handleAddListSubmit}
                    headerContent={"Ajouter une liste"}
                    btnLabel={["Ajout en cours ...", "Ajouter"]}  

                    changeNom={changeNom}

                    validated={validated}

                    focusRef={focusRef}
                    isLoading={isLoading}
                    errMsg={
                        <Alert 
                            key={"warning"} 
                            variant={"warning"} 
                            className={addErrMsg !== "" ? "" : "offscreen"}
                            style={{position: "absolute", bottom: "20px", margin: "0"}}>
                            <p className={"errMsg"} aria-live="assertive">{addErrMsg}</p>
                        </Alert>
                    }
                />

                <ValidationModal 
                    show={showDeleteList}
                    handleClose={handleCloseDeleteList}
                    headerContent={"Suppression"}
                    bodyContent={"Etes-vous sûr de vouloir supprimer la liste : " + nomDel}
                    handleSubmit={handleDeleteListSubmit}
                    isLoading={isLoading}
                    errMsg={
                        <Alert 
                            key={"warning"} 
                            variant={"warning"} 
                            className={delErrMsg !== "" ? "" : "offscreen"}
                            style={{position: "absolute", bottom: "20px", margin: "0"}}>
                            <p className={"errMsg"} aria-live="assertive">{delErrMsg}</p>
                        </Alert>
                    }
                />

            </div>
        </>
    )
}

export default Listes;