import { useState, useRef, useEffect } from "react";
import { Stack, Form, Button, Figure, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import axios from "../../api/axios.jsx";

import logolucky from '../../assets/lucky-logo-white.svg';

//API
const RESET_URL = '/auth/updatepasswd';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%./?*&$]).{8,16}$/;

const ResetMotdepasse = () => {
    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");
    const changeMdp = (value) => setPasswd(value);
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setsuccMsg] = useState("");

    const [validPwd, setValidPwd] = useState(false);
    const changeValidPwd = (value) => setValidPwd(PWD_REGEX.test(value));
    const [matchPwd, setMatchPwd] = useState(false);
    const changeMatchPwd = (value) => setMatchPwd(value === passwd);

    const [showPwd, setShowPwd] = useState(false);
    const changeShowPwd = (value) => {value ? setShowPwd(true) : setShowPwd(false)}

    const [isLoading, setIsLoading] = useState(false);

    const emailReset = new URLSearchParams(window.location.search).get('email');

    const passwdRef = useRef();
    const validPasswdRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        setEmail(emailReset);
        passwdRef.current.focus();
        validPasswdRef.current.focus();
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (e) => {
        if(matchPwd){
            e.preventDefault();
            setIsLoading(true);

            const handledErr = [400, 403, 404];

            try {
                const response = await axios.post(RESET_URL,
                    JSON.stringify({email, "mdp" : passwd}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                setsuccMsg(response?.data["message"])
                setEmail("");           
                setPasswd("");
                setValidPwd(false);
                setMatchPwd(false);
                setShowPwd(false);   
                setIsLoading(false);
                setTimeout(function () {
                    navigate("/connexion")
                }, 1000);

            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponses...");
                    setIsLoading(false);
                } else if (handledErr.indexOf(err?.response?.status) !== -1) {
                    setErrMsg(err?.response?.data["message"]);
                    setIsLoading(false);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                    setIsLoading(false);
                }
            }
        }else{
            e.preventDefault();
            setErrMsg("Les mots de passe ne sont pas corrects !");
        }
    }

    return (
        <>
            <Stack gap={2} className="form-signin">
                <Figure className="mx-auto">
                    <Link to="/">
                        <Figure.Image
                            width={100}
                            alt="Lucky CRM"
                            src={logolucky}
                        />
                    </Link>
                </Figure>
                <p style={{textAlign: "center", color: "#fff"}}>Réinitialisez votre mot de passe {email}</p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2" controlId="userFormPassword">
                        <div className={validPwd ? "valide" : "hide"}>✓</div>
                        <Form.Control 
                            type={showPwd ? "" : "password"}
                            placeholder="Mot de passe" 
                            autoComplete="new-password"
                            ref={passwdRef}
                            onChange={e => {changeMdp(e.target.value); changeValidPwd(e.target.value); changeMatchPwd(e.target.value)}} 
                            value={passwd} 
                            required="required"
                        />
                    </Form.Group>
                    <div className={matchPwd ? "valide" : "hide"}>✓</div>
                    <Form.Group className="mb-3" controlId="userFormVerifPassword">
                        <Form.Control 
                            type={showPwd ? "" : "password"}
                            placeholder="Confirmation du mot de passe" 
                            disabled={!validPwd}
                            autoComplete="off"
                            ref={validPasswdRef}
                            onChange={e => changeMatchPwd(e.target.value)}
                            required="required" 
                        />
                        <div style={{marginTop: "0.5rem"}}>
                            <input type="checkbox" onChange={e => changeShowPwd(e.target.checked)}>
                            </input>
                            <label style={{marginLeft: "5px", color: "#fff"}}>
                                Afficher mot de passe
                            </label>
                        </div>
                    </Form.Group>
                    <div style={{fontSize: "13px", color: "#fff"}}>
                        Le mot de passe doit contenir :<br/>
                        - Entre 8 caractères et 16 caractères<br/>
                        - Une majuscule<br/>
                        - Un caractère spécial<br/>
                        - Des lettres et des chiffres<br/><br/>
                    </div>
                    <Button
                        className="w-100"
                        type="submit"
                        variant="sub"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Changement en cours...' : 'Changer le mot de passe'}
                    </Button>
                    
                </Form>
                <Alert 
                    key={"danger"} 
                    variant={"danger"} 
                    style={{margin: "0", display: "flex"}}
                    className={errMsg !== "" ? "" : "offscreen"}>

                    <p className={"errMsg"}>{errMsg}</p>
                </Alert>
                <Alert 
                    key={"success"} 
                    variant={"success"} 
                    style={{margin: "0", display: "flex"}}
                    className={succMsg !== "" ? "" : "offscreen"}>

                    <p className={"succMsg"}>{succMsg}</p>
                </Alert>
            </Stack>
        </>
    )
}

export default ResetMotdepasse;