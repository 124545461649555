import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken.jsx";
import useAuth from "../hooks/useAuth.jsx";
import { Spinner } from "react-bootstrap";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } 
            catch (error) {
                console.error(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, [auth, refresh])

    return(
        <>
            {isLoading
                ? 
                <div style={{
                    left: "0",
                    lineHeight: "200px",
                    marginTop: "-100px",
                    position: "absolute",
                    textAlign: "center",
                    top: "50%",
                    width: "100%"
                }}>
                    <Spinner animation="border" size="sm"/> Chargement
                </div>
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin;