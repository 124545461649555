//Dependencies components
import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { formatDate } from "@fullcalendar/core";

//Internals components
import BrowserSidenav from "../components/BrowserSidenav";
import Weather from "../components/Weather";

//Logos Mobile
import LogoLuckyColor from '../assets/logo_lucky_gris.svg';
import LogoOpteamColor from '../assets/opteam.svg';
import Group from '../assets/group.svg';
import Location from '../assets/location.svg';
import Loupe from '../assets/loupe.svg';
import Zone from '../assets/zone.svg';

import useAuth from "../hooks/useAuth";
import axios from "../api/axios";

const GETANALYTICS_URL = '/home/getAnalytics'

const Home = () => {
    //SESSION STATE
    const { auth } = useAuth();
    const accessToken = auth?.accessToken; 

    //TOOLS
    const [errMsg, setErrMsg] = useState("");
    const [date, setDate] = useState(new Date().toLocaleString());

    //STATES
    const [nbClients, setNbClients] = useState("");
    const [nbProspects, setNbProspects] = useState("");
    const [lastContact, setLastContact] = useState("");
    const [lastActivite, setLastActivite] = useState("");

    
    useEffect(() => {
        const interval = setInterval(() => setDate(new Date().toLocaleString()), 1000);
        return () => {
        clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const getAnalytics = async () => {
            const handledErr = [409, 401];
            try {
                const response = await axios.post(GETANALYTICS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );
                setNbClients(response?.data["nb_client"]);
                setNbProspects(response?.data["nb_prospect"]);
                setLastContact(response?.data["last_contact"]);
                setLastActivite(response?.data["last_rapportsActivite"]); 
            } catch (err) {
                if (!err?.response) {
                    setErrMsg("Pas de réponse");
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }
        
        getAnalytics();
    }, [accessToken])

    if (isMobile) {
        return (
            <>
                {/* Menu Mobile */}
                <div className="mobile-content home">
                    <div className="mobile-home-header">
                        <img
                        alt="Logo Lucky CRM"
                        src={LogoLuckyColor}
                        width="164"
                        />
                    </div>
                    <div className="mobileHomecontent">
                        <Link to="/contacts" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Group}
                                />
                                <br/>
                                Mes contacts
                            </div>
                        </Link> 
                        <Link to="/geolocalisation" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Location}
                                />
                                <br/>
                                Géolocalisation
                            </div>
                        </Link>
                        <Link to="/rapportActivite" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Loupe}
                                />
                                <br/>
                                Rapport d'activité
                            </div>
                        </Link>
                        <Link to="/campagnes" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Zone}
                                />
                                <br/>
                                Campagnes
                            </div>
                        </Link>
                    </div>
                    <div className="mobile-home-footer">
                        <p style={{fontSize: "12px", color: "#3E5B5F", textAlign: "center"}}><img
                        alt="Logo Opteam"
                        src={LogoOpteamColor}
                        width="119"
                        /><br/>
                        <span>© Opteam Interactive</span> - <a href='https://luckycrm.fr/politique-de-confidentialite/' target='_blank' rel='noreferrer' style={{textDecoration: "none", color: "#3E5B5F"}}>Mentions légales</a> - <a href='https://luckycrm.fr/hotline/' target='_blank' rel='noreferrer' style={{textDecoration: "none", color: "#3E5B5F"}}>Hotline</a></p>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                {/* Tableau de Bord PC */}
                <BrowserSidenav />
                <div className="browser-home-content">
                    <div className="datetemp-container">
                        <p className="date">
                            {date}
                        </p>
                    </div>

                    <Weather />

                    <div className="menuRapide">
                        <Link to="/contacts" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Group}
                                />
                                <br/>
                                Mes contacts
                            </div>
                        </Link> 
                        <Link to="/geolocalisation" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Location}
                                />
                                <br/>
                                Géolocalisation
                            </div>
                        </Link>
                        <Link to="/rapportActivite" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Loupe}
                                />
                                <br/>
                                Rapport d'activité
                            </div>
                        </Link>
                        <Link to="/campagnes" className="box">
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={Zone}
                                />
                                <br/>
                                Campagnes
                            </div>
                        </Link>
                    </div>

                    <div className="browser-home-box statistiques">
                        <div className="header">
                            Données
                        </div>
                        <div className="content">
                            <div className="item">
                                <h3>
                                    Nombre de clients 
                                </h3>
                                <p>
                                    {nbClients}
                                </p>
                            </div>
                            <div className="item">
                                <h3>
                                    Nombre de prospects
                                </h3>
                                <p>
                                    {nbProspects}
                                </p>
                            </div>
                            <div className="item">
                                <h3>
                                    Dernier contact inscrit
                                </h3>
                                <p>
                                    {lastContact}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*
                    <div className="browser-home-box mesrdv">
                        <div className="header">
                            Mes rendez-vous
                        </div>
                        <div className="content">
                            <h3>Prochain rendez-vous</h3>
                            <div className="group">
                                <div className="item">
                                    <p>Le 24/03/2023 à 9h</p>
                                    <p>SOCIETE - Nom du contact</p>
                                    <p>Adresse du rendez-vous</p>
                                </div>
                                <div className="item">
                                    <p>Le 24/03/2023 à 10h</p>
                                    <p>SOCIETE BIS - Nom du contact</p>
                                    <p>Adresse du rendez-vous Bis</p>
                                </div>
                                <div className="item">
                                    <p>Le 24/03/2023 à 14h</p>
                                    <p>SOCIETE - Nom du contact</p>
                                    <p>Adresse du rendez-vous</p>
                                </div>
                            </div>

                            <h3>Derniers rendez-vous</h3>
                            <div className="group">
                                <div className="item">
                                    <p>Le 24/03/2023 à 9h</p>
                                    <p>SOCIETE - Nom du contact</p>
                                    <p>Adresse du rendez-vous</p>
                                </div>
                                <div className="item">
                                    <p>Le 24/03/2023 à 10h</p>
                                    <p>SOCIETE BIS - Nom du contact</p>
                                    <p>Adresse du rendez-vous Bis</p>
                                </div>
                                <div className="item">
                                    <p>Le 24/03/2023 à 14h</p>
                                    <p>SOCIETE - Nom du contact</p>
                                    <p>Adresse du rendez-vous</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    */}

                    <div className="browser-home-box rapport-activite">
                        <div className="header">
                            Mes activités
                        </div>
                        <div className="content">
                            {lastActivite.length ? lastActivite.map(activite => (
                                <div key={activite.id} style={{display: "flex", justifyContent: "space-between"}}>
                                    <div>{formatDate(activite.date, {
                                        month: 'numeric',
                                        year: 'numeric',
                                        day: 'numeric',
                                        locale: 'fr'
                                    })}</div>
                                    <div>{activite.action}</div>
                                    <div>Contact :  {activite.contact}</div>
                                </div>
                            )) 
                            : "Aucune activité pour le moment"}
                        </div>
                    </div>

                    <Alert 
                        key={"warning"} 
                        variant={"warning"} 
                        className={errMsg !== "" ? "" : "offscreen"}
                        style={{position: "absolute", bottom: "20px", margin: "0"}}>
                        <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
                    </Alert>
                </div>
            </>
        )
    }
}

export default Home;