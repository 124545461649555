//Dependencies components
import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./contexts/AuthProvider.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//App.jsx
import App from './App.jsx';

//style
import './css/index.css';
import './css/styles.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
);

reportWebVitals();
