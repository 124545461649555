import React from "react";
import { useEffect, useState } from "react";

import jwt_decode from "jwt-decode";
import axios from "../api/axios";

import { Card, Button } from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";  
import dayGridPlugin from "@fullcalendar/daygrid"; 
import timeGridPlugin from "@fullcalendar/timegrid"
import frLocale from '@fullcalendar/core/locales/fr'
import googleCalendarPlugin from '@fullcalendar/google-calendar'

import BrowserSidenav from "../components/BrowserSidenav.jsx"; 

const Agenda = (props) => {
    // eslint-disable-next-line
    const [googleUser, setGoogleUser] = useState({});
    const [googleUserAccessToken, setGoogleUserAccessToken] = useState("");

    //FUNCS
    function handleCallbackResponse(response) {
        console.log(response.credential)

        document.getElementById("signInDiv").hidden = true;
        setGoogleUser(jwt_decode(response.credential));

        const client = google.accounts.oauth2.initTokenClient({
            client_id: '93628953737-uvgjre7p6259rnoibcg3d3nmlk1r0v37.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/calendar.readonly',
            prompt: '',
            callback: (response) => {
                setGoogleUserAccessToken(response.access_token);
            },
        });

        client.requestAccessToken();
    }

    function handleSignOut(e) {
        setGoogleUser({})
        document.getElementById("signInDiv").hidden = false;
    }

    //EFFECTS
    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: "93628953737-uvgjre7p6259rnoibcg3d3nmlk1r0v37.apps.googleusercontent.com",
            callback: handleCallbackResponse,
        });

        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: "outline", size: "large" }
        );
    }, []);

    useEffect(() => {
        const getCalendarDatas = async () => {
            try {
                const response = await axios.post("https://www.googleapis.com/calendar/v3/users/me/calendarList",
                    {
                        headers: {
                            "Authorization":"Bearer " + googleUserAccessToken,
                        },
                    }
                );
                console.log(response)
            } catch (err) {
                console.log(err);
            }
        }

        googleUserAccessToken !== "" && getCalendarDatas();
    }, [googleUserAccessToken])

    return (
        <>
            <BrowserSidenav />
            <div className="browser-content page-clients">
            { googleUserAccessToken === "" ?
                <div id="signInDiv" style={{margin: "auto"}}></div> :
                <Card>
                    <Card.Header className="contacts-list-header">
                        <h2>Agenda</h2>
                        <Button variant="sub" onClick={(e) => handleSignOut(e)} style={{marginLeft: "auto"}}>Se déconnecter</Button>
                    </Card.Header>
                    <Card.Body style={{padding: "0"}}>
                    { googleUserAccessToken !== "" &&
                        <div className="fullcalendar-container">
                            <FullCalendar
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                initialView="dayGridMonth"
                                locale={frLocale}
                                plugins={[dayGridPlugin, timeGridPlugin, googleCalendarPlugin]}
                                googleCalendarApiKey={""}
                                eventSources={[
                                    { googleCalendarId: "" }
                                ]}
                            />
                        </div>
                    }
                    </Card.Body>
                </Card>
            }
            </div>
        </>
    )
}

export default Agenda;