import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, Form, Button, Figure, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from "../../api/axios.jsx";
import useAuth from "../../hooks/useAuth.jsx";

import logolucky from '../../assets/lucky-logo-white.svg';

//API
const LOGIN_URL = '/auth/login';

const Connexion = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [email, setEmail] = useState("");
    const [mdp, setMdp] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const emailRef = useRef();

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const handledErr = [400, 403, 404];

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({email, mdp}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            const role = response?.data?.role;
            const accessToken = response?.data?.accessToken;
            const emailP = response?.data.email;
            const prenomP = response?.data.prenom;
            const nomP = response?.data.nom;
            const affiliationP = response?.data.affiliation;
            const companyP = response?.data.company;

            setAuth({role, accessToken, emailP, prenomP, nomP, affiliationP, companyP});

            setEmail("");
            setMdp("");

            navigate(from, { replace: true});
            
            setIsLoading(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg("Pas de réponses...");
                setIsLoading(false);
            } else if (handledErr.indexOf(err?.response?.status) !== -1) {
                setErrMsg(err?.response?.data["message"]);
                setIsLoading(false);
            } else {
                setErrMsg("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <Stack gap={2} className="form-signin">
                <Figure className="mx-auto">
                    <Figure.Image
                        width={100}
                        alt="Lucky CRM"
                        src={logolucky}
                    />
                </Figure>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control 
                            type="email"
                            placeholder="Votre email"
                            ref={emailRef}
                            autoComplete="off"
                            onChange={e => setEmail(e.target.value)} 
                            value={email}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="motdepasse">
                        <Form.Control 
                            type="password"
                            placeholder="Votre mot de passe"
                            onChange={e => setMdp(e.target.value)} 
                            value={mdp} 
                            required
                        />
                    </Form.Group>
                    <Button
                        className="w-100"
                        type="submit"
                        variant="sub"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Connexion...' : 'Se connecter'}
                    </Button>
                </Form>
                <Link to="/mot-de-passe-oublie" style={{textAlign:"center", color:"#fff", fontSize:"14px", textDecoration:"none", marginTop:"10px"}}>Mot de passe oublié ?</Link>
                <Alert 
                    key={"danger"} 
                    variant={"danger"} 
                    style={{margin: "0", display: "flex"}}
                    className={errMsg !== "" ? "" : "offscreen"}>

                    <p className={"errMsg"}>{errMsg}</p>
                </Alert>
            </Stack>
            
        </>
    )
}

export default Connexion;