import React, { useState, useRef } from "react";
import { Modal, Stack, Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import {Camera} from 'react-camera-pro';


import camera         from '../assets/camera.png';
import fermer         from '../assets/fermer.png';
import retourner      from '../assets/retourner.png';
import modePNG        from '../assets/mode.png';
import modeSelectdPNG from '../assets/modeSelected.png';


const ContactFormAddModal = (props) => {
	const [capturedImage     , setCapturedImage     ] = useState(null); // pour stocker l'image capturée
	const [capturedImageRecto, setCapturedImageRecto] = useState(null); // pour stocker l'image capturée du recto
	const [capturedImageVerso, setCapturedImageVerso] = useState(null); // pour stocker l'image capturée du verso

	const [photoEnCours, setPhotoEnCours] = useState(0); // 0: rien, 1: recto, 2: verso


	const webcamRef = useRef(null);

	const [facingMode, setFacingMode] = useState(1);
	const [iconeMode, setIconeMode] = useState(modePNG);

	const [mode, setMode] = useState(false);   // TRUE : paysage, FALSE : portrait


	const changerMode = () => {
		setMode(!mode);

		if (mode)
			setIconeMode(modePNG);
		else
			setIconeMode(modeSelectdPNG);
	};


	const toggleCamera = (num) => {
		props.changeAfficherImage(false);
		props.setShowCamera(!props.showCamera);

		if (!props.showCamera) {
			setPhotoEnCours(num);
		}
	};


	const togglePhoto = () => {
		props.changeAfficherImage(!props.afficherImage);
		console.log(photoEnCours);


		if (photoEnCours === 1) {
			console.log(1);
			setCapturedImageRecto(capturedImage);
		} else if (photoEnCours === 2) {
			console.log(2);
			setCapturedImageVerso(capturedImage);
		}

		console.log("Image"       + capturedImage     );
		console.log("Image recto" + capturedImageRecto);
		console.log("Image verso" + capturedImageVerso);

		setPhotoEnCours(0);

		props.setAfficherPhotoPrise(true);

		setMode(false);
		setFacingMode(1);
	};





	const switchCamera = () => {
		if (facingMode === 1) {
			setFacingMode(2);
		} else {
			setFacingMode(1);
		}
	};



	const capturePhoto = () => {
		const imageSrc = webcamRef.current.takePhoto({ mimeType: 'image/png' });
		const img = new Image();
		img.src = imageSrc;

		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			if (!mode) {
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0, img.width, img.height);
			} else {
				canvas.width = img.height;
				canvas.height = img.width;
				ctx.translate(canvas.width / 2, canvas.height / 2);
				ctx.rotate(-Math.PI / 2);
				ctx.drawImage(img, -img.width / 2, -img.height / 2);
			}

			const rotatedImageSrc = canvas.toDataURL('image/png');
			setCapturedImage(rotatedImageSrc);

			// Convert base64 to blob
			const byteString = atob(rotatedImageSrc.split(',')[1]);
			const mimeString = rotatedImageSrc.split(',')[0].split(':')[1].split(';')[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);

			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			const blob = new Blob([ab], { type: mimeString });

			// Create a File object
			const file = new File([blob], 'captured-image.png', { type: mimeString });

			// Update the state and props with the file
			if (photoEnCours === 1) {
				setCapturedImageRecto(rotatedImageSrc);
				props.changeFileRecto(file);
			} else if (photoEnCours === 2) {
				setCapturedImageVerso(rotatedImageSrc);
				props.changeFileVerso(file);
			}

			toggleCamera();
			props.changeAfficherImage(true);

			props.setShowCamera(false);
		};
	};





	return (
		<Modal dialogClassName="contact-modal-size" centered show={props.show} onHide={props.handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="text-center">
					{props.headerContent}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Stack>
					<Form noValidate validated={props.validated} onSubmit={props.handleSubmit}>
						<Row className="mb-3 text-center">
							<Form.Group as={Col} controlId="userFormClient">
								<Form.Check
									inline
									type="radio"
									aria-label="radio"
									label="Client"
									onChange={props.changeIsClient}
									value="Client"
									checked={props.isClient === "Client"}
								/>
							</Form.Group>
							<Form.Group as={Col} controlId="userFormProspect">
								<Form.Check
									inline
									type="radio"
									aria-label="radio"
									label="Prospect"
									onChange={props.changeIsClient}
									value="Prospect"
									checked={props.isClient === "Prospect"}
								/>
							</Form.Group>
						</Row>

						<Row className="mb-3">
							<Form.Label style={{ textDecoration: "underline" }}>
								<strong>Scanner une carte de visite</strong>
							</Form.Label>

							<Col md>
								<Button variant="sub" style={{ width: "80%", marginLeft: "10%", marginBottom: "1%" }} onClick={() => toggleCamera(1)}>
									Recto de la carte de visite
								</Button>
							</Col>

							<Col md>
								{capturedImageRecto && props.afficherPhotoPrise && props.fileRectoOk && (
									<img src={capturedImageRecto} alt="Recto" width='80%' style={{ display: 'block', margin: '0 auto', width:'30%' }} />
								)}
							</Col>
						</Row>


						<Row className="mb-3">
							<Col md>
								<Button variant="sub" style={{ width: "80%", marginLeft: "10%", marginBottom: "1%" }} onClick={() => toggleCamera(2)}>
									Verso de la carte de visite
								</Button>
							</Col>

							<Col md>
								{capturedImageVerso && props.afficherPhotoPrise && props.fileVersoOk && (
									<img src={capturedImageVerso} alt="Verso" width='80%' style={{ display: 'block', margin: '0 auto', width:'30%' }} />
								)}
							</Col>
						</Row>


						<Col md style={{ position: 'relative' }}>
							{props.showCamera && facingMode === 1 && (
								<div style={{ position: 'fixed', width: 'calc(650px/2)', height: 'calc(1004px/2)', top: 'calc(50% - 1004px/4)', left: 'calc(50% - 650px/4)', zIndex: 999 }}>

									<img alt='Fermer'  src={fermer   } style={{ width: '8%', marginLeft: '100%', marginBottom: '1%' }} onClick={toggleCamera}/>

									<div >
										<Camera
											audio={false}
											ref={webcamRef}
											facingMode = 'environment'
											screenshotFormat="image/jpeg"
											mirrored={false}
											style={{
												objectFit: 'cover',
												borderRadius: '10px',
											}}
										/>
									</div>

									<img alt='Switch'  src={retourner} style={{ width: '8%', marginLeft: '15%'        , marginTop: 'calc( 1004px/2 )' }} onClick={switchCamera}/>
									<img alt='Prendre' src={camera   } style={{ width: '8%', marginLeft: 'calc(46%/2)', marginTop: 'calc( 1004px/2 )' }} onClick={capturePhoto}/>
									<img alt='mode'    src={iconeMode} style={{ width: '8%', marginLeft: 'calc(46%/2)', marginTop: 'calc( 1004px/2 )' }} onClick={changerMode }/>
								</div>
							)}


							{props.showCamera && facingMode === 2 && (
								<div style={{ position: 'fixed', width: 'calc(650px/2)', height: 'calc(1004px/2)', top: 'calc(50% - 1004px/4)', left: 'calc(50% - 650px/4)', zIndex: 999 }}>

									<img alt='Fermer'  src={fermer   } style={{ width: '8%', marginLeft: '100%', marginBottom: '1%' }} onClick={toggleCamera}/>

									<div >
										<Camera
											audio={false}
											ref={webcamRef}
											facingMode = 'user'
											screenshotFormat="image/jpeg"
											mirrored={true}
											style={{
												objectFit: 'cover',
												borderRadius: '10px',
											}}
										/>
									</div>

									<img alt='Switch'  src={retourner} style={{ width: '8%', marginLeft: '15%'        , marginTop: 'calc( 1004px/2 )' }} onClick={switchCamera}/>
									<img alt='Prendre' src={camera   } style={{ width: '8%', marginLeft: 'calc(46%/2)', marginTop: 'calc( 1004px/2 )' }} onClick={capturePhoto}/>
									<img alt='mode'    src={iconeMode} style={{ width: '8%', marginLeft: 'calc(46%/2)', marginTop: 'calc( 1004px/2 )' }} onClick={changerMode }/>
								</div>
							)}



							{props.afficherImage && mode && (
								<div style={{ position: 'fixed', top: 'calc(50% - 650px/4)', left: 0, zIndex: 999 }}>
									<img src={capturedImage} alt="Captured" style={{width: '90%', marginLeft: '5%'}}/>

									<Button variant="sub" style={{ minWidth: '20%', padding: '1%', marginLeft: '55%', marginTop: '1%', zIndex: 999 }} onClick={togglePhoto }> Valider </Button>
									<Button variant="sub" style={{ minWidth: '20%', padding: '1%', marginLeft:  '1%', marginTop: '1%', zIndex: 999 }} onClick={toggleCamera}> Annuler </Button>
								</div>
							)}


							{props.afficherImage && !mode && (
								<div style={{ position: 'fixed', top: 'calc(50% - 1004px/4)', left: 0, zIndex: 999 }}>
									<img src={capturedImage} alt="Captured" style={{width: '90%', marginLeft: '5%'}} />

									<Button variant="sub" style={{ minWidth: '20%', padding: '1%', marginLeft: '50%', marginTop: '1%', zIndex: 999 }} onClick={togglePhoto }> Valider </Button>
									<Button variant="sub" style={{ minWidth: '20%', padding: '1%', marginLeft:  '1%', marginTop: '1%', zIndex: 999 }} onClick={toggleCamera}> Annuler </Button>
								</div>
							)}

						</Col>



						<Row>
							{ props.lectureCarteFinie && (
								<div style={{ position: 'fixed', maxWidth: '60%', left: '20%', bottom: '10%', textAlign: 'center', backgroundColor:'rgba(208,252,179,0.7)', zIndex: 999, padding: 'calc(1% / 2)', borderRadius: '10px', color: '#3e5b5f', border: '1px solid rgba(62,91,95,0.7)' }}>
									Lecture de la carte terminée !
								</div>
							)}
						</Row>


						<Row className="mb-3">
							<Col md>
								<Button variant="sub" style={{ margin: '0 auto', display: 'block' }} onClick={props.scannerCartes}>
									OK
								</Button>
							</Col>
						</Row>

						<Row className="mb-3">
							<Form.Label style={{ textDecoration: "underline" }}>
								<strong>Contact</strong>
							</Form.Label>
							<Col md>
								<FloatingLabel controlId="contactFormPrenom" label="Prénom" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Prénom"
										autoComplete="off"
										ref={props.focusRef}
										value={props.prenom}
										onChange={e => { props.changePrenom(e.target.value); }}
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel controlId="contactFormNom" label="Nom" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Nom"
										autoComplete="off"
										value={props.nom}
										onChange={e => { props.changeNom(e.target.value); }}
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel controlId="contactFormFonction" label="Fonction*" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Fonction"
										autoComplete="off"
										value={props.fonction}
										onChange={e => { props.changeFonction(e.target.value); }}
										required
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel controlId="contactFormLinkedin" label="LinkedIn" className="mb-3">
									<Form.Control
										type="url"
										placeholder="URL"
										autoComplete="off"
										value={props.linkedin}
										onChange={e => { props.changeLinkedin(e.target.value); }}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md>
								<FloatingLabel controlId="contactFormEmail" label="Adresse email*" className="mb-3">
									<Form.Control
										type="email"
										placeholder="Mail"
										autoComplete="off"
										value={props.email}
										onChange={e => { props.changeEmail(e.target.value); }}
										required
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel controlId="contactFormTelPortable" label="Télephone portable*" className="mb-3">
									<Form.Control
										type="tel"
										placeholder="Télephone portable"
										autoComplete="off"
										value={props.telPortable}
										onChange={e => { props.changeTelPortable(e.target.value); }}
										required
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel controlId="contactTelDirect" label="Ligne directe" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Ligne directe"
										autoComplete="off"
										value={props.telDirect}
										onChange={e => { props.changeTelDirect(e.target.value); }}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row className="mb-3">
							<Form.Label style={{ textDecoration: "underline" }}>
								<strong>Entreprise</strong>
							</Form.Label>
							<Col md>
								<FloatingLabel controlId="contactFormIntitule" label="Raison sociale*" className="mb-3">
									<Form.Control
										type="text"
										placeholder="Raison sociale"
										autoComplete="off"
										value={props.raison_sociale}
										onChange={e => { props.changeRaisonSociale(e.target.value); }}
										required
									/>
								</FloatingLabel>
								</Col>
								<Col md>
									<FloatingLabel controlId="contactFormTypeEntreprise" label="Type d'entreprise" className="mb-3">
										<Form.Control 
											type="text" 
											placeholder="ex : TPE, SARL"
											autoComplete="off"
											value={props.statut_juridique}
											onChange={e => {props.changeStatutJuridique(e.target.value);}} 
										/>
									</FloatingLabel>
								</Col>
								<Col md>
									<FloatingLabel controlId="contactFormTelStandard" label="Standard" className="mb-3">
										<Form.Control 
											type="text" 
											placeholder="Standard"
											autoComplete="off"
											value={props.tel_standard}
											onChange={e => {props.changeTelStandard(e.target.value);}} 
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md>
									<FloatingLabel controlId="contactFormAdresse" label="Adresse*" className="mb-3">
										<Form.Control 
											type="text" 
											placeholder="ex: 24 Rue du Maréchal Joffre, 76600 Le Havre"
											autoComplete="off"
											value={props.adresse}
											onChange={e => {props.changeAdresse(e.target.value);}} 
											required
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md>
									<FloatingLabel controlId="contactFormCommentaire" label="Commentaire" className="mb-3">
										<Form.Control 
											as="textarea"
											placeholder="Intêressé par {produit}, Intêressé par {audit}, Projet(s) futur..."
											autoComplete="off"
											rows={8}
											style={{ height: '200px' }}
											value={props.commentaire}
											onChange={e => {props.changeCommentaire(e.target.value);}}
										/>
									</FloatingLabel>
								</Col>
							</Row>


							<Button 
								style={{ display: 'block', margin: '0 auto' }}
								type="submit"
								variant="sub"
								disabled={props.isLoading}
							>
								{props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
							</Button>
						</Form>
					</Stack>
			</Modal.Body>
			<Modal.Footer>
				{props.msgContent}
			</Modal.Footer>
		</Modal>
	);
};


export default ContactFormAddModal;