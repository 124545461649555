import React from "react";

import BrowserSidenav from "../components/BrowserSidenav.jsx";
import { isMobile } from "react-device-detect";

const Newsletter = (props) => {
    if (isMobile) {
        return (
            <>
                {/* IMPLEMENTATION POSSIBLE SUR MOBILE */}
            </>
        )
    } else {
        return (
            <>
                <BrowserSidenav />
                <div className="browser-content">
                <h2>Newsletter</h2>
            </div>
            </>
        )
    }
}

export default Newsletter;