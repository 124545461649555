import React, { useState, useRef } from "react";

import { Card, Form, Button, Row, Col, Alert } from "react-bootstrap";

import BrowserSidenav from '../../components/BrowserSidenav.jsx';

import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";

//API
const UPDUSER_URL = '/users/updateUser';

//REGEX
const USER_NAME_REGEX = /^[A-z]{3,23}$/;
const EMAIL_REGEX = /^(?=.*[A-z])(?=.*[.])(?=.*[@]).{6,30}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,16}$/;

const Profile = (props) => {
    //SESSION STATE
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;
    const role = auth?.role 

    //TOOLS
    const [isLoading, setIsLoading] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const changeShowPwd = (value) => {value ? setShowPwd(true) : setShowPwd(false)}
    const [errMsg, setErrMsg] = useState("");

    //USER STATES
    const [prenom, setPrenom] = useState(auth?.prenomP);
    const [nom, setNom] = useState(auth?.nomP);
    const [email, setEmail] = useState(auth?.emailP);
    const [mdp, setMdp] = useState("");

    //REGEX BOOL STATES
    const [validNom, setValidNom] = useState(false);
    const changeValidNom = (value) => setValidNom(USER_NAME_REGEX.test(value));
    const [validPrenom, setValidPrenom] = useState(false);
    const changeValidPrenom = (value) => setValidPrenom(USER_NAME_REGEX.test(value));
    const [validPwd, setValidPwd] = useState(false);
    const changeValidEmail = (value) => setValidEmail(EMAIL_REGEX.test(value));
    const [validEmail, setValidEmail] = useState(false);
    const changeValidPwd = (value) => setValidPwd(PWD_REGEX.test(value));
    const [matchPwd, setMatchPwd] = useState(false);
    const changeMatchPwd = (value) => setMatchPwd(value === mdp);

    //REFS
    const nomRef = useRef();

    const handleUpdateUserSubmit = async (e) => {
        // e.preventDefault();
        const handledErr = [409, 401];
        setIsLoading(true);
        try {
            const response = await axios.post(UPDUSER_URL,
                JSON.stringify({prenom, nom, role, "old_email": auth?.emailP, email, mdp, accessToken}), 
                {
                    headers: {"Content-Type":"application/json"},
                    withCredentials: true
                }
            );

            setErrMsg(response?.data["message"]);
            setTimeout(function () {
                setIsLoading(false);
            }, 2000);
        } catch (err) {
            if (!err?.response) {
                setErrMsg("Pas de réponse");
                setIsLoading(false);
            } else if (handledErr.indexOf(err.response?.status) !== -1) {
                setErrMsg(err.response?.data["message"]);
                setIsLoading(false);
            } else {
                setErrMsg("Problème interne lié au serveur !");
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <BrowserSidenav />
            <div className="browser-content page-clients">
                <Card>
                    <Card.Header className="contacts-list-header">
                        <h2>Profil</h2>
                    </Card.Header>
                    <Card.Body style={{background: "white"}}>
                        <Form onSubmit={handleUpdateUserSubmit}>
                            <Row className="mb-3" style={{alignItems: "baseline"}}> 
                                <Form.Group as={Col} controlId="userFormNom">
                                    <div className={validNom ? "valide" : "hide"}>✓</div>
                                    <Form.Label>
                                        Nom
                                    </Form.Label>
                                    <Form.Control 
                                        type="nom" 
                                        placeholder="Nom utilisateur"
                                        autoComplete="off"
                                        ref={nomRef}
                                        value={nom}
                                        onChange={e => {setNom(e.target.value); changeValidNom(e.target.value)}} 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="userFormPrenom">
                                    <div className={validPrenom ? "valide" : "hide"}>✓</div>
                                    <Form.Label>
                                        Prénom
                                    </Form.Label>
                                    <Form.Control 
                                        type="prenom" 
                                        placeholder="Prénom utilisateur"
                                        autoComplete="off"
                                        value={prenom}
                                        onChange={e => {setPrenom(e.target.value); changeValidPrenom(e.target.value)}} 
                                    />
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="userFormEmail">
                                <div className={validEmail ? "valide" : "hide"}>✓</div>
                                <Form.Label>
                                    Adresse email
                                </Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email utilisateur" 
                                    autoComplete="new-email"
                                    onChange={e => {setEmail(e.target.value); changeValidEmail(e.target.value)}} 
                                    value={email}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="userFormPassword">
                                <div className={validPwd ? "valide" : "hide"}>✓</div>
                                <Form.Label>
                                    Mot de passe
                                </Form.Label>
                                <Form.Control 
                                    type={showPwd ? "" : "password"}
                                    placeholder="Mot de passe utilisateur" 
                                    autoComplete="new-password"
                                    onChange={e => {setMdp(e.target.value); changeValidPwd(e.target.value); props.changeMatchPwd(e.target.value)}} 
                                    value={mdp} 
                                />
                            </Form.Group>
                            <div className={matchPwd ? "valide" : "hide"}>✓</div>
                            <Form.Group className="mb-3" controlId="userFormVerifPassword">
                                <Form.Control 
                                    type={showPwd ? "" : "password"}
                                    placeholder="Confirmation du mot de passe" 
                                    disabled={!validPwd}
                                    autoComplete="off"
                                    onChange={e => changeMatchPwd(e.target.value)} 
                                    value={mdp} 
                                />
                                <div style={{marginTop: "0.5rem"}}>
                                    <input type="checkbox" onChange={e => changeShowPwd(e.target.checked)}>
                                    </input>
                                    <label style={{marginLeft: "5px"}}>
                                        Afficher mot de passe
                                    </label>
                                </div>
                            </Form.Group>
                            <div style={{fontSize: "13px", color: "#aaaaaa"}}>
                                Le mot de passe doit contenir :<br/>
                                - 8 caractères minimum<br/>
                                - Une majuscule<br/>
                                - Un caractère spécial<br/>
                                - Des lettres et des chiffres
                            </div>
                            <Button 
                                className="w-100"
                                type="submit"
                                variant="sub"
                                disabled={isLoading}
                            >
                                {isLoading ? "Modification en cours ..." : "Modifier"}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

                <Alert 
                    key={"warning"} 
                    variant={"warning"} 
                    className={errMsg !== "" ? "" : "offscreen"}
                    style={{position: "absolute", bottom: "20px", margin: "0"}}>
                    <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
                </Alert>
            </div>
        </>
    );
}

export default Profile;