import React, { useEffect, useState, useRef } from "react"
import { isMobile } from "react-device-detect";
import { useParams, useNavigate } from "react-router-dom";
import { Button,Alert, Card, ListGroup} from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import {Icon} from 'leaflet'
import { FaPencilAlt, FaLinkedin, FaPhoneAlt, FaEnvelope, FaTrashAlt } from "react-icons/fa";
import { formatDate } from "@fullcalendar/core";

import markerIconProspect from "../assets/leaflet/marker-icon-2x-blue.png"
import markerIconClient from "../assets/leaflet/marker-icon-2x-red.png"

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import BrowserSidenav from '../components/BrowserSidenav';
import ContactFormUpdModal from "../components/ContactFormUpdModal";
import ValidationModal from "../components/ValidationModal";
import ContactAddFilesModal from "../components/ContactAddFileModal";
import RapporActiviteFormAddModal from "../components/RapportActiviteFormAddModal";

const GETCONTACT_URL = '/contacts/getContact'
const UPDCONTACT_URL = '/contacts/updateContact'
const GETFILE_URL = '/documents/getDocument'
const ADDFILES_URL = '/documents/addDocument'
const DELETEFILES_URL = '/documents/deleteDocument'
const ADDRAPPORTACTIVITE_URL = '/rapportsActivites/addRapportActivite'
const DELRAPPORTACTIVITE_URL = '/rapportsActivites/deleteRapportActivite'
const GETACTIONS_URL = '/actions/getActions'

const NOM_FAMILLE = '/nomFamille/nomFamille'



const browserViewContactLeafletContainer = `
	.leaflet-container {
		height: 250px;
		min-width: 100%;
	}
`

const mobileViewContactLeafletContainer = `
	.leaflet-container {
		height: 250px;
		width: 100%;
	}
`

const ContactsFiche = () => {
	//SESSION STATES
	const { auth } = useAuth();
	const accessToken = auth?.accessToken;
	const navigate = useNavigate();

	//TOOLS
	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [delErrMsg, setDelErrMsg] = useState("");
	const [needDataReload, setNeedDataReload] = useState(false);
	const [actionTypeInput, setActionTypeInput] = useState("Liste");
	const changeActionTypeInput = (value) => {
		setActionTypeInput(value);
	}

	//REFS
	const focusRef = useRef("");

	//PARAMS
	let {slug} = useParams();

	//STORAGE
	const [contact, setContact] = useState("");
	const [selectedFiles, setSelectedFiles] = useState();
	const changeSelectedFiles = (value) => setSelectedFiles(value);
	const [isFilesPicked, setIsFilesPicked] = useState(false);
	const changeIsFilesPicked = () => setIsFilesPicked(!isFilesPicked);  
	const [actions, setActions] = useState([]);

	//FORMS
	const [commentaireRapport, setCommentaireRapport] = useState("");
	const changeCommentaireRapport = (value) => {
		setCommentaireRapport(value);
	}
	const [action, setAction] = useState("");
	const changeAction = (value) => {
		setAction(value);
	}

	//CONTACT STATES
	const [contactEmailToUpdate, setContactEmailToUpdate] = useState("");

	const [prenom, setPrenom] = useState("");
	const changePrenom = (value) => {setPrenom(value); console.log(prenom);}

	const [nom, setNom] = useState("");
	const changeNom = (value) => setNom(value);

	const [email, setEmail] = useState("");
	const changeEmail = (value) => setEmail(value);

	const [fonction, setFonction] = useState("");
	const changeFonction = (value) => setFonction(value);

	const [telPortable, setTelPortable] = useState("");
	const changeTelPortable = (value) => setTelPortable(value);

	const [telDirect, setTelDirect] = useState("");
	const changeTelDirect = (value) => setTelDirect(value);

	const [linkedin, setLinkedin] = useState("");
	const changeLinkedin = (value) => setLinkedin(value);

	const [raisonSociale, setRaisonSociale] = useState("");
	const changeRaisonSociale = (value) => setRaisonSociale(value);

	const [statutJuridique, setStatutJuridique] = useState("");
	const changeStatutJuridique = (value) => setStatutJuridique(value);

	const [telStandard, setTelStandard] = useState("");
	const changetelStandard = (value) => setTelStandard(value);

	const [adresse, setAdresse] = useState("");
	const changeAdresse = (value) => setAdresse(value);

	const [commentaire, setCommentaire] = useState("");
	const changeCommentaire = (value) => setCommentaire(value);

	const [initialLatLng, setInitialLatLng] = useState("");
	const changeInitialLatLng = (value) => setInitialLatLng(value);

	const [lat, setLat] = useState("");
	const changeLat = (value) => setLat(value);

	const [lon, setLon] = useState("");
	const changeLon = (value) => setLon(value);

	const [item, setItem] = useState({ isClient: "" });
	const { isClient } = item;
	const changeIsClient = e => {
		e.persist();
	
		setItem(prevState => ({
			...prevState,
			isClient: e.target.value
		}));
	};

	//GET CONTACTS
	useEffect(() => {
		setNeedDataReload(false);
		const fetchContact = async () => {
			const handledErr = [401];
			try {
				const response = await axios.post(GETCONTACT_URL,
					JSON.stringify({accessToken, "email":slug}), 
					{
						headers: {"Content-Type":"application/json"},
						withCredentials: true
					}
				);
				setContact(response.data["contact"]);
			} catch (err) {
				if (!err?.response) {
					setErrMsg("Pas de réponse");
				} else if (handledErr.indexOf(err.response?.status) !== -1) {
					setErrMsg(err.response?.data["message"]);
				} else {
					setErrMsg("Problème interne lié au serveur !");
				}
			}
		}
		//FETCH
		fetchContact()
	}, [accessToken, needDataReload, slug])

	const SetMap = (coord) => {
		const map = useMap();
		map.setView(coord, 15);
	}

	const ContactMarker = (props) => {
		if (props.lat !== "" && props.lon !== "") { 
			SetMap(props.coord);
			return (
				<Marker position={props.coord} icon={new Icon({iconUrl: props.isClient ? markerIconClient : markerIconProspect, iconSize: [25, 41], iconAnchor: [12, 41]})}>
					<Popup offset={[0, -15]}>
						<p style={{marginBottom: "1vh", textAlign: "center", fontSize: "1.5em"}}>
							{props.raisonSoc}
						</p>
						<Button variant="sub" className="mx-auto" style={{border: "teal", borderRadius: "15px", fontSize: "1em", display: "flex", justifyContent: "center"}}>
							<a 
							href={"https://www.waze.com/fr/live-map/directions?navigate=yes&to=ll." + props.coord[0] + "%2C" + props.coord[1]}
							style={{textDecoration: "none", color: "black", backgroundColor: "#D0FCB3"}}
							>
								Allez-y
							</a>
						</Button>
					</Popup>
				</Marker>
			) 
		} else {
			SetMap([0, 0]);
			return (null)
		}
	}

	//UPD CONTACT FORM MODAL
	const [showUpdContact, setShowUpdContact] = useState(false);
	const handleShowUpdContact = async contact => {
		setPrenom(contact.prenom);
		setNom(contact.nom);
		setEmail(contact.email);
		setContactEmailToUpdate(contact.email);
		setFonction(contact.fonction);
		setTelPortable(contact.tel_portable);
		setTelDirect(contact.tel_direct);
		setLinkedin(contact.linkedin);
		setRaisonSociale(contact.raison_sociale);
		setStatutJuridique(contact.statut_juridique);
		setTelStandard(contact.tel_standard);
		setAdresse(contact.adresse);
		setCommentaire(contact.commentaire);
		setInitialLatLng({ lat: contact.lat, lng: contact.lon })
		setLat(contact.lat);
		setLon(contact.lon);
		setItem({ isClient : contact.is_client ? "Client" : "Prospect" });
		await setShowUpdContact(true);
		focusRef.current.focus()
	}

	const handleCloseUpdContact = () => {
		setShowUpdContact(false);
		setPrenom("");
		setNom("");
		setEmail("");
		setContactEmailToUpdate("");
		setFonction("");
		setTelPortable("");
		setTelDirect("");
		setLinkedin("");
		setRaisonSociale("");
		setStatutJuridique("");
		setTelStandard("");
		setAdresse("");
		setCommentaire("");
		setLat(0);
		setLon(0);
		setItem({ isClient : "" });
	}

	const handleUpdContactSubmit = async (e) => {
		e.preventDefault();
		const handledErr = [409, 401];
		setIsLoading(true);
		try {
			const stringedLat = lat.toString()
			const stringedLon = lon.toString()
			const response = await axios.post(UPDCONTACT_URL,
				JSON.stringify(
					{accessToken, old_email : contactEmailToUpdate, email, prenom, nom, fonction, tel_portable : telPortable, tel_direct : telDirect, linkedin,
					raison_sociale : raisonSociale, statut_juridique : statutJuridique, tel_standard : telStandard, adresse,
					commentaire, is_client : isClient, lat : stringedLat, lon : stringedLon}
				), 
				{
					headers: {"Content-Type":"application/json"},
					withCredentials: true
				}
			);
			setTimeout(function () {
				if(response.data["message"] === "Email changé"){
					navigate("/contacts/"+email)
				}
				setIsLoading(false);
				setNeedDataReload(true);
				handleCloseUpdContact();
			}, 2000);
		} catch (err) {
			if (!err?.response) {
				console.log("Pas de réponse");
				setIsLoading(false);
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				console.log(err.response?.data["message"]);
				setIsLoading(false);
			} else {
				console.log("Problème interne lié au serveur !");
				setIsLoading(false);
			}
		}
	}

	const GetFile = async (docName) => {
		const handledErr = [401, 409, 204, 206];
		try {
			const response = await axios.post(GETFILE_URL, 
				JSON.stringify({accessToken, document : docName}), 
				{
					headers: {"Content-Type":"application/json"},
					responseType: "blob",
					withCredentials: true
				}
			)
			
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', docName);
			document.body.appendChild(link);
			link.click();
			
		} catch (err) {
			if (!err?.response) {
				console.log("Pas de réponse");
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				console.log(err.response?.data["message"]);
			} else {
				console.log("Problème interne lié au serveur !");
			}
		}
	}

	const [showDeleteFile, setShowDeleteFile] = useState(false);
	const [docName, setDoc] = useState(false);
	const handleShowDeleteFile = nomDoc => {
		setDoc(nomDoc);
		setShowDeleteFile(true);
	}
	
	const handleCloseDeleteFile = () => {
		setDoc("");
		setShowDeleteFile(false);
	}

	const handleDeleteFileSubmit = async (e) => {
		e.preventDefault();
		const handledErr = [401, 409, 204, 206];
		setIsLoading(true);
		try {
			const response = await axios.post(DELETEFILES_URL, 
				JSON.stringify({accessToken, document : docName}), 
				{
					headers: {"Content-Type":"application/json"},
					withCredentials: true
				}
			)
			setDelErrMsg(response?.data["message"]);
			setTimeout(function () {
				setIsLoading(false);
				setNeedDataReload(true);
				handleCloseDeleteFile();
			}, 2000);
			
		} catch (err) {
			if (!err?.response) {
				setDelErrMsg("Pas de réponse");
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				setDelErrMsg(err.response?.data["message"]);
			} else {
				setDelErrMsg("Problème interne lié au serveur !");
			}
		}
	}

	const [showFilesContact, setShowFilesContact] = useState(false);
	const handleShowFilesContact = contact => {
		setEmail(contact?.email)
		setShowFilesContact(true);
	}
	const handleCloseFilesContact = () => {
		setShowFilesContact(false);
		setEmail("")
	}

	const handleAddFilesSubmit = async (e) => {
		e.preventDefault()
		const handledErr = [401, 400];
		setIsLoading(true);
		var bodyFormData = new FormData();
		bodyFormData.append("accessToken", accessToken)
		bodyFormData.append("contact", email)
		if (selectedFiles) {
			Array.from(selectedFiles).forEach(file => {
				bodyFormData.append("documents", file)
			});
		}
		try {
			const response = await axios.post(ADDFILES_URL, bodyFormData, 
				{
					headers: {"Content-Type":"multipart/form-data"},
					withCredentials: true
				}
			)
			console.log(response?.data["message"]);
			setSelectedFiles([])
			setIsFilesPicked(false)
			setTimeout(function () {
				setIsLoading(false);
				setNeedDataReload(true);
				handleCloseFilesContact();
			}, 2000);
		} catch (err) {
			if (!err?.response) {
				console.log("Pas de réponse");
				setIsLoading(false);
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				console.log(err.response?.data["message"]);
				setIsLoading(false);
			} else {
				console.log("Problème interne lié au serveur !");
				setIsLoading(false);
			}
		}
	}

	//Rapports d'activité
	useEffect(() => {
		const fetchActionList = async () => {
			const handledErr = [401];
			try {
				const response = await axios.post(GETACTIONS_URL,
					JSON.stringify({accessToken}), 
					{
						headers: {"Content-Type":"application/json"},
						withCredentials: true
					}
				);
				setActions(response.data["actions"]);
			} catch (err) {
				if (!err?.response) {
					console.log("Pas de réponse");
				} else if (handledErr.indexOf(err.response?.status) !== -1) {
					console.log(err.response?.data["message"]);
				} else {
					console.log("Problème interne lié au serveur !");
				}
			}
		}
		//FETCH
		fetchActionList()
	}, [accessToken])

	//ADD MODALS
	const [showAddRapportActiviteModal, setShowAddRapportActiviteModal] = useState(false);

	const handleShowAddRapportActivite = () => {
		setShowAddRapportActiviteModal(true);
	}

	const handleCloseAddRapportActivite = () => {
		setShowAddRapportActiviteModal(false);
		setActionTypeInput("Liste");
		setCommentaireRapport("");
	}

	const handleAddRapportActiviteFormSubmit = async (e) => {
		e.preventDefault();
		const handledErr = [409, 401];
		const contactRapport = contact.email;
		setIsLoading(true);
		try {
			const response = await axios.post(ADDRAPPORTACTIVITE_URL,
				JSON.stringify(
					{accessToken, "contact": contactRapport, action, "commentaire":commentaireRapport}
				), 
				{
					headers: {"Content-Type":"application/json"},
					withCredentials: true
				}
			);

			console.log(response.data["message"])

			setTimeout(function () {
				setIsLoading(false);
				setNeedDataReload(true);
				handleCloseAddRapportActivite();
			}, 2000);
		} catch (err) {
			if (!err?.response) {
				console.log("Pas de réponse");
				setIsLoading(false);
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				console.log(err.response?.data["message"]);
				setIsLoading(false);
			} else {
				console.log("Problème interne lié au serveur !");
				setIsLoading(false);
			}
		}
	}

	//DEL MODAL
	const [showDelRapportActiviteModal, setShowDelRapportActiviteModal] = useState(false);
	const [rapportToDelete, setRapportToDelete] = useState({});

	const handleShowDeleteRapport = rapport => {
		setShowDelRapportActiviteModal(true);
		setRapportToDelete(rapport);
	}

	const handleCloseDeleteRapport = () => {
		setShowDelRapportActiviteModal(false);
		setRapportToDelete({});
	}

	const handleDeleteRapportActiviteFormSubmit = async (e) => {
		e.preventDefault();
		const handledErr = [409, 401];
		setIsLoading(true);
		try {
			const response = await axios.post(DELRAPPORTACTIVITE_URL,
				JSON.stringify(
					{accessToken, "id" : String(rapportToDelete.id)}
				), 
				{
					headers: {"Content-Type":"application/json"},
					withCredentials: true
				}
			);

			console.log(response.data["message"])

			setTimeout(function () {
				setIsLoading(false);
				setNeedDataReload(true);
				handleCloseDeleteRapport();
			}, 2000);
		} catch (err) {
			if (!err?.response) {
				setErrMsg("Pas de réponse");
				setIsLoading(false);
			} else if (handledErr.indexOf(err.response?.status) !== -1) {
				setErrMsg(err.response?.data["message"]);
				setIsLoading(false);
			} else {
				setErrMsg("Problème interne lié au serveur !");
				setIsLoading(false);
			}
		}
	}

	return (
		<>
			{!isMobile ? (
				<style>
					{browserViewContactLeafletContainer}
				</style>
			) : (
				<style>
					{mobileViewContactLeafletContainer}
				</style>
			)}
			{!isMobile ? <BrowserSidenav /> : null}
			{contact === "" ? "" : (
				<div className={!isMobile ? "browser-content page-clients" : "mobile-content page-clients"}> 
					<Card>
						<Card.Header className="contacts-list-header">
							<h2>Fiche contact</h2>
							<Button variant="plus" value={contact.email} onClick={e => handleShowUpdContact(contact)}>
								<FaPencilAlt color="#fff" />
							</Button>
						</Card.Header>
						<Card.Body className="contact-infos">
							<div className="person">
								<p><strong>Raison sociale :</strong> <strong className={contact.is_client ? "client-color" : "prospect-color"}>{contact.raison_sociale}</strong></p>
								<p><strong>Prénom :</strong> {contact.prenom}</p>
								<p><strong>Nom :</strong> {contact.nom}</p>
								<p><strong>Fonction :</strong> {contact.fonction}</p>
								<p><strong>Type d'entreprise :</strong> {contact.statut_juridique}</p>
								<p><strong>Adresse :</strong> {contact.lat !== "" && contact.lon !== "" ? contact.adresse : "Pas d'adresse valide renseigné"}</p>
							</div>
							<div className="business">
								{contact.email ? <p><a href={"mailto:"+contact.email}><FaEnvelope />  {contact.email}</a></p> : ""}
								{contact.tel_portable ? <p><a href={"tel:"+contact.tel_portable}><FaPhoneAlt />  {contact.tel_portable}</a></p> : ""}
								{contact.tel_direct ? <p><a href={"tel:"+contact.tel_direct}><FaPhoneAlt />  {contact.tel_direct}</a></p> : ""}
								{contact.linkedin ? <p><a href={contact.linkedin} target="_blank" rel="noreferrer" ><FaLinkedin />  Voir le profil</a></p> : ""}
								{contact.tel_standard ? <p><a href={"tel:"+contact.tel_standard}><FaPhoneAlt />Standard {contact.tel_standard}</a></p> : ""}
							</div>
							<div className="minimap">
								<MapContainer scrollWheelZoom={false} id={"view-modal-leaflet"}>
									<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>

									<ContactMarker coord={[contact.lat, contact.lon]} isClient={contact.is_client} raisonSoc={contact.raison_sociale} />
								</MapContainer>
							</div>
							<div className="commentaires">
								<p style={{marginBottom: "0"}}><strong>Commentaire :</strong></p>
								<div className="comment">
									<p style={{marginBottom: "0"}}>
										{contact.commentaire ? contact.commentaire : "Aucun commentaire"}
									</p>
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="documents">
						<Card.Header className="contacts-list-header">
							<h2>Documents</h2>
							<Button variant="plus" value={contact.email} onClick={e => handleShowFilesContact(contact)}>
								<svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
									<path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
								</svg>
							</Button>
						</Card.Header>
						<Card.Body>
							<ListGroup variant="flush">
								{contact.documents.length === 0 ? "Aucun document" : contact.documents.map(documentContact => (
									<div key={documentContact.Nom} className="contacts-list-item-container">
										<ListGroup.Item className="contacts-list-item">
										<div style={{display: "flex", alignItems: "center"}}>
											<Button variant="sub" className={"button-as-link"} onClick={e => GetFile(documentContact.Nom)}>
												{documentContact.Nom}
											</Button>
											{documentContact.Type === "application/pdf" ? "- PDF - " : 
											documentContact.Type === "image/png" ? "- PNG - " :
											documentContact.Type === "image/jpg" ? "- JPG - " : 
											documentContact.Type === "application/octet-stream" ? " - " : null}
											{documentContact.Poids} Mo

											<Button variant="sub" style={{marginLeft: "auto"}} onClick={e => handleShowDeleteFile(documentContact.Nom)}>
												<FaTrashAlt />
											</Button>
										</div>
										</ListGroup.Item>
									</div>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
					<Card className="rapports">
						<Card.Header className="contacts-list-header">
							<h2>Rapports d'activité</h2>
							<Button variant="plus" value={contact.email} onClick={handleShowAddRapportActivite}>
								<svg xmlns="http://www.w3.org/2000/svg" width="21.667" height="21.667" viewBox="0 0 21.667 21.667">
									<path id="Icon_metro-cross" data-name="Icon metro-cross" d="M15.181,12.308h0L10.533,7.66l4.648-4.648h0a.48.48,0,0,0,0-.677l-2.2-2.2a.48.48,0,0,0-.677,0h0L7.66,4.788,3.013.14h0a.48.48,0,0,0-.677,0l-2.2,2.2a.48.48,0,0,0,0,.677h0L4.788,7.66.14,12.308h0a.48.48,0,0,0,0,.677l2.2,2.2a.48.48,0,0,0,.677,0h0L7.66,10.533l4.648,4.648h0a.48.48,0,0,0,.677,0l2.2-2.2a.48.48,0,0,0,0-.677Z" transform="translate(0 10.833) rotate(-45)" fill="#fff"/>
								</svg>
							</Button>
						</Card.Header>
						<Card.Body>
							<ListGroup variant="flush">
								{contact.rapports_activites.length === 0 ? "Aucun rapport d'activité" : contact.rapports_activites.map(rapportActivite => (
									<div key={rapportActivite.id} className="contacts-list-item-container">
										<ListGroup.Item className="contacts-list-item">
											<div className="contacts-list-item-content">
												<div>
													<p><strong>{formatDate(rapportActivite.date, {
														month: 'numeric',
														year: 'numeric',
														day: 'numeric',
														locale: 'fr'
													})} - {rapportActivite.fait_par} - {rapportActivite.action}</strong></p>
													<p>{rapportActivite.commentaire}</p>
												</div>
												{rapportActivite.email !== auth?.emailP ? "" : (
													<div className="btn-container">
														<Button variant="sub" className="m-1" value={contact.email} onClick={e => handleShowDeleteRapport(rapportActivite)}>
															<FaTrashAlt />
														</Button>
													</div>
												)}
											</div>
										</ListGroup.Item>
									</div>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
					<Button variant="sub" onClick={() => navigate(-1)}>Retour</Button>
				</div>
			)}

			<ContactFormUpdModal 
				show={showUpdContact}
				handleClose={handleCloseUpdContact}
				handleSubmit={handleUpdContactSubmit}
				headerContent={"Modifier le contact " + email}
				btnLabel={["Modification en cours ...", "Modifier"]}  

				changePrenom={changePrenom}
				prenom={prenom}

				changeNom={changeNom}
				nom={nom}

				changeEmail={changeEmail}
				email={email}

				changeFonction={changeFonction}
				fonction={fonction}

				changeTelPortable={changeTelPortable}
				telPortable={telPortable}

				changeTelDirect={changeTelDirect}
				telDirect={telDirect}

				changeLinkedin={changeLinkedin}
				linkedin={linkedin}

				changeRaisonSociale={changeRaisonSociale}
				raisonSociale={raisonSociale}

				changeStatutJuridique={changeStatutJuridique}
				statutJuridique={statutJuridique}

				changeTelStandard={changetelStandard}
				telStandard={telStandard}

				changeAdresse={changeAdresse}
				adresse={adresse}

				changeCommentaire={changeCommentaire}
				commentaire={commentaire}

				changeInitialLatLng={changeInitialLatLng}
				initialLatLng={initialLatLng}

				changeLat={changeLat}
				lat={lat}

				changeLon={changeLon}
				lon={lon}

				changeIsClient={changeIsClient}
				isClient={isClient}

				focusRef={focusRef}
				isLoading={isLoading}
				errMsg={""}
			/>

			<ValidationModal 
				show={showDeleteFile}
				handleClose={handleCloseDeleteFile}
				headerContent={"Suppression"}
				bodyContent={"Etes-vous sûr de vouloir supprimer le document : " + docName}
				handleSubmit={handleDeleteFileSubmit}
				isLoading={isLoading}
				errMsg={
					<Alert 
						key={"warning"} 
						variant={"warning"} 
						className={delErrMsg !== "" ? "" : "offscreen"}
						style={{position: "absolute", bottom: "20px", margin: "0"}}>
						<p className={"errMsg"} aria-live="assertive">{delErrMsg}</p>
					</Alert>
				}
			/>

			<ContactAddFilesModal
				show={showFilesContact}
				handleClose={handleCloseFilesContact}
				headerContent={"Ajouter un document"}
				handleSubmit={handleAddFilesSubmit}

				changeSelectedFiles={changeSelectedFiles}
				changeIsFilesPicked={changeIsFilesPicked}

				isLoading={isLoading}
				btnLabel={["Ajout en cours ...", "Ajouter"]} 
			/>

			<RapporActiviteFormAddModal
				show={showAddRapportActiviteModal}
				handleClose={handleCloseAddRapportActivite}
				handleSubmit={handleAddRapportActiviteFormSubmit}

				headerContent={"Ajouter un rapport d'activité"}
				btnLabel={["Ajout en cours...", "Ajouter"]}
				
				actions={actions}

				contacts={"None"}

				action={action}
				changeAction={changeAction}
				commentaire={commentaireRapport}
				changeCommentaire={changeCommentaireRapport}
				actionTypeInput={actionTypeInput}
				changeActionTypeInput={changeActionTypeInput}

				isLoading={isLoading}
			/>

			<ValidationModal 
				show={showDelRapportActiviteModal}
				handleClose={handleCloseDeleteRapport}
				headerContent={"Suppression"}
				bodyContent={"Etes-vous sûr de vouloir supprimer le rapport ?"}
				handleSubmit={handleDeleteRapportActiviteFormSubmit}
				isLoading={isLoading}
				errMsg={
					<Alert 
						key={"warning"} 
						variant={"warning"} 
						className={errMsg !== "" ? "" : "offscreen"}
						style={{position: "absolute", bottom: "20px", margin: "0"}}>
						<p className={"errMsg"} aria-live="assertive">{errMsg}</p>
					</Alert>
				}
			/>

			<Alert 
				key={"warning"} 
				variant={"warning"} 
				className={errMsg !== "" ? "" : "offscreen"}
				style={{position: "absolute", bottom: "20px", margin: "0"}}>
				<p className={"errMsg"} aria-live="assertive">{errMsg}</p>
			</Alert>
		</>
	)
}

export default ContactsFiche;