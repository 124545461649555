import React from "react";
import { Modal, Stack, Form, Button, Row, Col, InputGroup } from "react-bootstrap";

const RapporActiviteFormAddModal = (props) => (
    <Modal centered show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title className="text-center">
                {props.headerContent}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Stack>
                    <Form noValidate validated={props.validated} onSubmit={props.handleSubmit}>
                        <Row className="mb-3" style={{alignItems: "baseline"}}>
                                {props.actionTypeInput === "Liste" ?
                                <Form.Group as={Col} controlId="rActFormAction">
                                    <Form.Select
                                        required={true}
                                        aria-label="Default select example"
                                        onChange={e => {
                                            if (e.target.selectedIndex !== 0) {
                                                if (e.target.selectedIndex === props.actions.length + 1) {
                                                    props.changeAction("");
                                                    props.changeActionTypeInput("Manuel");
                                                } else { 
                                                    props.changeAction(props.actions[e.target.selectedIndex - 1].libelle);
                                                }
                                            } else {
                                                props.changeAction("");
                                            }
                                        }}
                                    >
                                        <option>Choisir une activité</option>
                                        {props.actions.map(action => (
                                            <option key={action.libelle}>{action.libelle}</option>
                                        ))}
                                        <option>Autre</option>
                                    </Form.Select> 
                                </Form.Group>
                                : 
                                <Col>
                                    <Form.Label>
                                        Action
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Saisir une action"
                                            autoComplete="off"
                                            value={props.action}                                    
                                            onChange={e => {props.changeAction(e.target.value);}} 
                                            required={true}
                                        />
                                        <Button 
                                            variant="outline-secondary" 
                                            id="button-addon1"
                                            onClick={() => {props.changeAction(""); props.changeActionTypeInput("Liste");}}
                                        >
                                            X
                                        </Button>
                                    </InputGroup>
                                </Col>
                                }
                            {props.contacts === "None" ? "" : (
                                <Form.Group as={Col} controlId="rActFormContact">
                                    <Form.Select 
                                        required={true}
                                        aria-label="Default select example"
                                        onChange={e => {
                                            if (e.target.selectedIndex !== 0) {
                                                if (e.target.selectedIndex < props.contacts.length + 1) {
                                                    props.changeContact(props.contacts[e.target.selectedIndex - 1].email);
                                                }
                                            } else {
                                                props.changeContact("");
                                            }
                                        }}
                                    >
                                        <option>Choisir un contact</option>
                                        {props.contacts.map(contact => (
                                            <option key={contact.email}>{contact.raison_sociale}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                        </Row>
                        <Row className="mb-3" style={{alignItems: "baseline"}}>
                            <Form.Group as={Col} controlId="rActFormCommentaire">
                                <Form.Label>
                                    Mon rapport
                                </Form.Label>
                                <Form.Control 
                                    as="textarea"
                                    type="textarea" 
                                    placeholder="Description"
                                    autoComplete="off"
                                    rows={8}
                                    value={props.commentaire}                                    
                                    onChange={e => {props.changeCommentaire(e.target.value);}} 
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Veuillez rédiger un commentaire</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button 
                            className="w-100"
                            type="submit"
                            variant="sub"
                            disabled={props.isLoading}
                        >
                            {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                        </Button>
                    </Form>
                </Stack>
        </Modal.Body>
        <Modal.Footer>
            {props.msgContent}
        </Modal.Footer>
    </Modal>
)

export default RapporActiviteFormAddModal;