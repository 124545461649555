import { Link } from 'react-router-dom';

import { IconContext } from 'react-icons';
import * as FaIcons    from 'react-icons/fa';
import * as AiIcons    from 'react-icons/ai';
import * as IoIcons    from 'react-icons/io';

import LogoLucky     from '../assets/logo-bm.svg';
import DefaultProfil from '../assets/profil-default.png';

import useAuth from "../hooks/useAuth.jsx";
import axios   from '../api/axios.jsx';

const LOGOUT_URL = '/auth/logout'

function BrowserSidenav() {
  const { auth } = useAuth();
  const accessToken = auth?.accessToken;
  const role = auth?.role;
  const email = auth?.emailP;
  const prenom = auth?.prenomP;
  const nom = auth?.nomP;
  const company = auth?.companyP;

  const handleLogout = async (e) => {
    // e.preventDefault();
    try {
        const response = await axios.post(LOGOUT_URL,
            JSON.stringify({accessToken}), 
            {
                headers: {"Content-Type":"application/json"},
                withCredentials: true
            }
        );
        console.log(response?.data["message"])
        window.location.reload(true);
    } catch (err) {
        if (!err?.response) {
            console.log("Pas de réponse");
        }
    }
  };

  return (
    <>
      <div className='sidebar-container'>
        <IconContext.Provider value={{ color: '#fff' }}>
        <div className='sidebar-header'>
          <img
            alt="Logo Lucky CRM"
            src={LogoLucky}
          />
          <span>Lucky CRM</span>
        </div>
        <nav className='sidebar-content'>
          <ul className='list-items'>
            {/* FOR ALL NAV */}
            <li className="item">
              <Link to="/">
                <AiIcons.AiFillHome />
                <span>Tableau de bord</span>
              </Link>
            </li>
            <li className="item">
              <Link to="/geolocalisation">
                <IoIcons.IoMdGlobe />
                <span>Géolocalisation</span>
              </Link>
            </li>
            <li className="item">
              <Link to="/contacts">
                <IoIcons.IoMdContacts />
                <span>Contacts</span>
              </Link>
            </li>
            {/* <li className="item">
              <Link to="/agenda">
                <FaIcons.FaCalendar />
                <span>Agenda</span>
              </Link>
            </li> */}
            <li className="item">
              <Link to="/rapportActivite">
                <IoIcons.IoMdPeople />
                <span>Rapport d'activité</span>
              </Link>
            </li>
            <li className="item">
              <Link to="/campagnes">
                <FaIcons.FaList />
                <span>Campagnes</span>
              </Link>
            </li>
            {/* <li className="item">
              <Link to="/newsletter">
                <FaIcons.FaNewspaper />
                <span>Newsletter</span>
              </Link>
            </li> */}

            {/* ADMIN NAV */}
            {role === "Manager" ? 
            <li className="item">
              <Link to="/admin">
                <IoIcons.IoMdHelpCircle />
                <span>Admin</span>
              </Link>
            </li> 
            : null }
          </ul>
        </nav>       
        <div className='sidebar-profil'>
          <Link to="/profile">
            <img
              alt="Mon profil"
              src={DefaultProfil}
            />
            <h2>{prenom} {nom}</h2>
            <p>{email}</p>
            <h3>{company}</h3>
          </Link>
          <div style={{display: "flex", alignContent: "center", justifyContent: "center", fontSize: "12px"}}>
            <button className='button-as-link-no-style' style={{color: "white"}}
              onClick={e => handleLogout(e)}
            >
              Se déconnecter
            </button>
          </div>
          <div style={{fontSize: "12px", color: "#fff", textAlign: "center"}}><button className='button-as-link-no-style' style={{color: "white"}}><a href='https://luckycrm.fr/politique-de-confidentialite/' target='_blank' rel='noreferrer'>Mentions légales</a></button> - <button className='button-as-link-no-style' style={{color: "white"}}><a href='https://luckycrm.fr/hotline/' target='_blank' rel='noreferrer'>Hotline</a></button></div>
        </div>
        <div className='sidebar-footer'>
          <span>© Opteam Interactive</span>
        </div>
        </IconContext.Provider>
      </div>
    </>
  );
}

export default BrowserSidenav;