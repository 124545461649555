import React from "react";

import { isMobile } from "react-device-detect";

const RendezVous = (props) => {
    if (isMobile) {
        return (
            <>
                <h1>Mes rendez-vous</h1>
            </>
        )
    } else {
        return (
            <>
                {/* IMPLEMENTATION POSSIBLE SUR PC */}
            </>
        )
    }
}

export default RendezVous;