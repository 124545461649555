import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";

import Home from "./pages/Home";
import Geolocalisation from "./pages/Geolocalisation";
import Agenda from "./pages/Agenda.jsx";
import RapportActivite from "./pages/RapportActivite";
import Campagnes from "./pages/Campagnes";
import Newsletter from "./pages/Newsletter";
import Contacts from "./pages/Contacts";
import ContactsFiche from "./pages/ContactsFiche";
import Listes from "./pages/Listes";
import Taches from "./pages/Taches";
import RendezVous from "./pages/RendezVous";
import Compte from "./pages/utilisateurs/Compte"
import Profile from "./pages/utilisateurs/Profile"

import Admin from "./pages/utilisateurs/Admin"

import Connexion from "./pages/utilisateurs/Connexion"
import MotdepasseOublie from "./pages/utilisateurs/MotdepasseOublie";
import ResetMotdepasse from "./pages/utilisateurs/ResetMotdepasse";

import Unauthorized from "./pages/Unauthorized";

const App = () => {  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* PRIVATE FOR COMMERCIAL, MANAGER */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={["Commercial", "Manager"]}/>}>
            <Route path="/" element={<Home />} />
            <Route path="geolocalisation" element={<Geolocalisation />} />
            <Route path="agenda" element={<Agenda />} />
            <Route path="rapportActivite" element={<RapportActivite />} />
            <Route path="campagnes" element={<Campagnes />} />
            <Route path="newsletter" element={<Newsletter />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="contacts/:slug" element={<ContactsFiche />} />
            <Route path="listes" element={<Listes />} />
            <Route path="taches" element={<Taches />} />
            <Route path="rendezVous" element={<RendezVous />} />
            <Route path="compte" element={<Compte />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          {/* PRIVATE FOR Manager */}
          <Route element={<RequireAuth allowedRoles={["Manager"]} />}>
            <Route path="admin" element={<Admin />} />
          </Route>
          {/* PRIVATE UNAUTHORIZED IF NOT ADMIN BUT COMMERCIAL */}
          <Route element={<RequireAuth allowedRoles={["Commercial"]} />}>
            <Route path="unauthorized" element={<Unauthorized />} />
          </Route>
        </Route>
        {/* PUBLIC */}
        <Route path="connexion" element={<Connexion />} />
        <Route path="mot-de-passe-oublie" element={<MotdepasseOublie />} />
        <Route path="reset" element={<ResetMotdepasse />} />
        {/* CATCH ELSE */}
        <Route path="*" element={<h2>404 not found</h2>} />
      </Route>
    </Routes>
  );
}

export default App;
