import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { Modal, Stack, Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import {Icon} from 'leaflet'

import markerIconProspect from "../assets/leaflet/marker-icon-2x-blue.png"
import markerIconClient from "../assets/leaflet/marker-icon-2x-red.png"

const ContactFormUpdModal = (props) => {

    const SetMap = (coord) => {
        const map = useMap();
        if (map.getZoom() == null) {
            map.setView(coord, 15);
        } else {
            map.panTo(coord, map.getZoom());
        }
    }

    const ContactMarker = (props) => {
        const [position, setPosition] = useState( { lat: props.lat ? parseFloat(props.lat) : 0, lng: props.lon ? parseFloat(props.lon) : 0} );
        const [draggable, setDraggable] = useState(false);
        const markerRef = useRef(null);

        const eventHandlers = useMemo(
            () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition({lat: marker.getLatLng().lat, lng: marker.getLatLng().lng});
                }
            },
            }),
            [],
        )

        const toggleDraggable = useCallback(() => {
            setDraggable((d) => !d);
        }, [])

        useEffect(() => {
            props.changeLat(position.lat);
            props.changeLon(position.lng);

            // eslint-disable-next-line
        }, [position])

        if (position.lat !== null && position.lng !== null) { 
            SetMap(position);
            return (
                <Marker  
                    icon={new Icon({iconUrl: props.isClient ? markerIconClient : markerIconProspect, iconSize: [25, 41], iconAnchor: [12, 41]})}
                    draggable={draggable}
                    eventHandlers={eventHandlers}
                    position={position}
                    ref={markerRef}
                >
                    <Popup offset={[0, -15]}>
                        <p style={{marginTop: "0.5em", marginBottom: "0.5em", textAlign: "center", fontSize: "1.5em"}}>
                            {props.raisonSociale}
                        </p>
                        <p style={{marginTop: "0.5em", marginBottom: "0.5em", textAlign: "center", fontSize: "1.5em"}}>
                        </p>
                        <Button variant="sub" className="mx-auto" style={{border: "teal", borderRadius: "15px", fontSize: "1em", display: "flex", justifyContent: "center"}}>
                            <span onClick={toggleDraggable}>
                            {draggable
                                ? 'Marqueur déplaçable'
                                : 'Cliquez pour rendre le marqueur déplaçable'}
                            </span>
                        </Button>
                    </Popup>
                </Marker>
            )
        } else {
            return (null)
        }
    }

    return (
        <>

            <Modal dialogClassName="contact-modal-size" centered show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        {props.headerContent}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upd-contact-modal-container">
                        <Stack>
                            <Form onSubmit={props.handleSubmit}>
                                <Row className="mb-3 text-center">
                                    <Form.Label>
                                        Etat du contact 
                                    </Form.Label>
                                    <Form.Group as={Col} controlId="userFormClient">
                                        <Form.Check
                                            inline
                                            type="radio" 
                                            aria-label="radio"
                                            label="Client" 
                                            onChange={props.changeIsClient} 
                                            value="Client" 
                                            checked={props.isClient === "Client"}
                                        />
                                    </Form.Group>   
                                    <Form.Group as={Col} controlId="userFormProspect">
                                        <Form.Check
                                            inline
                                            type="radio" 
                                            aria-label="radio"
                                            label="Prospect" 
                                            onChange={props.changeIsClient}
                                            value="Prospect"
                                            checked={props.isClient === "Prospect"}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <Form.Label style={{textDecoration: "underline"}}>
                                            <strong>Contact</strong>
                                        </Form.Label>
                                        <FloatingLabel controlId="contactFormPrenom" label="Prénom" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Prénom"
                                                autoComplete="off"
                                                ref={props.focusRef}
                                                value={props.prenom}                                    
                                                onChange={e => {props.changePrenom(e.target.value);}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormNom" label="Nom" className="mb-3">
                                            <Form.Control 
                                                type="text"
                                                placeholder="Nom"
                                                autoComplete="off"
                                                value={props.nom}
                                                onChange={e => {props.changeNom(e.target.value);}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormFonction" label="Fonction*" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Fonction"
                                                autoComplete="off"
                                                value={props.fonction}
                                                onChange={e => {props.changeFonction(e.target.value);}}
                                                required={props.required}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormLinkedin" label="LinkedIn" className="mb-3">
                                            <Form.Control 
                                                type="url" 
                                                placeholder="URL"
                                                autoComplete="off"
                                                value={props.linkedin}
                                                onChange={e => {props.changeLinkedin(e.target.value);}}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormEmail" label="Adresse email*" className="mb-3">
                                            <Form.Control 
                                                type="email" 
                                                placeholder="Mail"
                                                autoComplete="off"
                                                value={props.email}
                                                onChange={e => {props.changeEmail(e.target.value);}} 
                                                required={props.required}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormTelPortable" label="Télephone portable*" className="mb-3">
                                            <Form.Control 
                                                type="tel" 
                                                placeholder="Télephone portable"
                                                autoComplete="off"
                                                value={props.telPortable}
                                                onChange={e => {props.changeTelPortable(e.target.value);}}
                                                required={props.required}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactTelDirect" label="Ligne directe" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Ligne directe"
                                                autoComplete="off"
                                                value={props.telDirect}
                                                onChange={e => {props.changeTelDirect(e.target.value);}}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md>
                                        <Form.Label style={{textDecoration: "underline"}}>
                                            <strong>Entreprise</strong>
                                        </Form.Label>
                                        <FloatingLabel controlId="contactFormIntitule" label="Raison sociale*" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Raison sociale"
                                                autoComplete="off"
                                                value={props.raisonSociale}
                                                onChange={e => {props.changeRaisonSociale(e.target.value);}}
                                                required={props.required}
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormTypeEntreprise" label="Type d'entreprise" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="ex : TPE, SARL"
                                                autoComplete="off"
                                                value={props.statutJuridique}
                                                onChange={e => {props.changeStatutJuridique(e.target.value);}} 
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormTelStandard" label="Standard" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Standard"
                                                autoComplete="off"
                                                value={props.telStandard}
                                                onChange={e => {props.changeTelStandard(e.target.value);}} 
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="contactFormAdresse" label="Adresse*" className="mb-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="ex: 24 Rue du Maréchal Joffre, 76600 Le Havre"
                                                autoComplete="off"
                                                value={props.adresse}
                                                onChange={e => {props.changeAdresse(e.target.value);}} 
                                                required={props.required}
                                            />
                                        </FloatingLabel>
                                        <div className="minimap mb-3">
                                            <MapContainer scrollWheelZoom={false} id={"view-modal-leaflet"}>
                                                <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <ContactMarker 
                                                    isClient={props.is_client} 
                                                    raisonSociale={props.raisonSociale} 

                                                    changeInitialLatLng={props.changeInitialLatLng}
                                                    initialLatLng={props.initialLatLng}

                                                    changeLat={props.changeLat}
                                                    lat={props.lat}
                                                    changeLon={props.changeLon}
                                                    lon={props.lon}
                                                />
                                            </MapContainer>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md>
                                        <FloatingLabel controlId="contactFormCommentaire" label="Commentaire" className="mb-3">
                                            <Form.Control 
                                                as="textarea"
                                                placeholder="Intêressé par {produit}, Intêressé par {audit}, Projet(s) futur..."
                                                autoComplete="off"
                                                rows={8}
                                                style={{ height: '200px' }}
                                                value={props.commentaire}
                                                onChange={e => {props.changeCommentaire(e.target.value);}}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <div className="btn_form">
                                    <Button
                                        type="submit"
                                        variant="sub"
                                        disabled={props.isLoading}
                                    >
                                        {props.isLoading ? props.btnLabel[0] : props.btnLabel[1]}
                                    </Button>
                                    <Button 
                                        variant="sub"
                                        onClick={props.handleClose}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </Form>
                        </Stack>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ContactFormUpdModal;