import React, { useEffect, useState } from "react";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import markerIconProspect from "../assets/leaflet/marker-icon-2x-blue.png"
import markerIconClient from "../assets/leaflet/marker-icon-2x-red.png"
import {Icon} from 'leaflet'
import { Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from "../api/axios.jsx";
import useAuth from "../hooks/useAuth";

import BrowserSidenav from "../components/BrowserSidenav.jsx";
import NavMobile from '../components/NavMobile';
import { isMobile } from "react-device-detect";

const GETCONTACTS_URL = '/contacts';
const POSITION = [49.4930129, 0.1149465];
const browserLeafletContainer = `
    .leaflet-container {
        margin-left: 220.45px;
        min-height: 100vh;
        max-height: 100vh;
        width: calc(100%-220.45px);
    }
`

const mobileLeafletContainer = `
    .leaflet-container {
        min-height: 100vh;
        max-height: 100vh;
        width: 100%;
    }
`

const Geolocalisation = () => {
    //SESSION
    const { auth } = useAuth();
    const accessToken = auth?.accessToken;

    const [contacts, setContacts] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    // const [bounds, setBounds] = useState([]);

    const SetMap = () => {
        const map = useMap();
        map.setView(POSITION, 15);
    }

    // const RemoveMarker = (props) => {
    //     const map = useMap()
    //     map.remove([props.lat, props.lon])
    // }

    //GET CONTACT LIST
    useEffect(() => {
        const fetchContactsList = async () => {
            const handledErr = [401];
            try {
                const response = await axios.post(GETCONTACTS_URL,
                    JSON.stringify({accessToken}), 
                    {
                        headers: {"Content-Type":"application/json"},
                        withCredentials: true
                    }
                );

                const cleanedContacts = [];

                response.data["contacts"].forEach(contact => {
                    if ((contact.lat !== "0" || "") && (contact.lon !== "0" || "")) {
                        cleanedContacts.push(contact);
                    }
                });

                setContacts(cleanedContacts);
            } catch (err) {
                if (!err?.response) {
                    setErrMsg(err);
                } else if (handledErr.indexOf(err.response?.status) !== -1) {
                    setErrMsg(err.response?.data["message"]);
                } else {
                    setErrMsg("Problème interne lié au serveur !");
                }
            }
        }

        // const setBoundsArray = () => {
        //     var coordArray
        //     contacts.map(contact => {
        //         if (contact.lat !== "" && contact.lon !== "") {
        //             coordArray.push([contact.lat, contact.lon])
        //             console.log(coordArray)
        //         }
        //     })
        //     return coordArray
        // }

        fetchContactsList()
        // setBounds(setBoundsArray())
    }, [accessToken])

    return (
        <>
            {!isMobile ? (
                <>
                    <BrowserSidenav />
                    <style>
                        {browserLeafletContainer}
                    </style>
                </>
            ) : (
                <>
                    <NavMobile name="Géolocalisation" />
                    <style>
                        {mobileLeafletContainer}
                    </style>
                </>
            )}
            <MapContainer  id={!isMobile ? "browser-leaflet" : "mobile-leaflet"} center={POSITION} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=fr"
                />
                {contacts.map(contact => (
                    <div key={contact.email}>
                        <Marker position={[contact.lat, contact.lon]} icon={new Icon({iconUrl: contact.is_client ? markerIconClient : markerIconProspect, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                            <Popup offset={[0, -15]} className="popup-geoloc">
                                <h3>
                                <Link to={"/contacts/"+contact.email} style={{ color: 'inherit', textDecoration: 'inherit'}}>{contact.raison_sociale}</Link>                                    
                                </h3>
                                <p><strong>{contact.nom} {contact.prenom}</strong><br/>{contact.adresse}</p>
                                <Button variant="sub" className="mx-auto" style={{border: "teal", borderRadius: "15px", fontSize: "1em", display: "flex", justifyContent: "center"}}>
                                    <a 
                                    href={"https://www.waze.com/fr/live-map/directions?navigate=yes&to=ll." + contact.lat + "%2C" + contact.lon}
                                    style={{textDecoration: "none", color: "black", backgroundColor: "#D0FCB3"}}
                                    >
                                        J'y vais avec Waze !
                                    </a>
                                </Button>
                            </Popup>
                        </Marker>
                    </div>
                ))}
                <SetMap />
            </MapContainer>

            <Alert 
                key={"warning"} 
                variant={"warning"} 
                className={errMsg !== "" ? "geoErrMsgMinimised" : "geoErrMsgMinimised offscreen"}>
                <p className={"errMsg"} aria-live="assertive">{errMsg}</p>
            </Alert>
        </>
    )
}

export default Geolocalisation;